import React, { useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import ecommerceApi from "../../../../services/ecommerceApi";
import order, { reOrder, updateOrders } from "../../../../store/order";
import Button from "../../../shared/Button";
import { OrderType } from "../../../../types";
import { getSiteKeys } from "../../../../utils/config";
import { jsonParseString } from "../../../../utils/string";
import { DateTime } from "luxon";
import _ from "lodash";

export default function MyOrders() {
    const dispatch = useDispatch<AppDispatch>();

    const customer = useSelector((state: RootState) => state.auth.customer);

    const [orders, setOrders] = useState<OrderType[] | undefined>(undefined);
    const [reordering, setReordering] = useState<string | undefined>(undefined);

    React.useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        let customerOrders = (customer ? await ecommerceApi.getCustomerOrders(customer) : []) || [];
        const fullOrders = await Promise.all(customerOrders
            .filter(o => o.status !== "shoppingCart")
            .map(async o => ecommerceApi.getOrder(o.id)));
        setOrders(fullOrders);
    };

    const handleReOrder = (order: OrderType) => async (e: any) => {
        e.preventDefault();
        setReordering(order.id);
        const { tenant, salesChannel } = getSiteKeys();
        let reOrderData = order.orderItems.filter(oi => !oi.parentId).map(oi => {
            return {
                skuId: oi.skuId,
                quantity: oi.quantity,
                tenant,
                salesChannel
            };
        });
        await dispatch(reOrder(reOrderData));
        navigate("/cart");
    };

    return (
        <>
            {orders?.length == 0 && (
                <div className="bg-white text-center rounded p-5">
                    <p>You currently have no orders.</p>
                </div>
            )}
            {orders?.map(order => {
                const orderNumber = `#${order.invNumber || order.number}`;
                const orderDate = DateTime.fromISO(order.orderDate).toLocaleString();
                const orderStatus = order.status.split(/(?=[A-Z])/).join(" ").toUpperCase();
                return (
                    <div className="p-5 bg-white rounded mb-3 flex flex-col relative" key={order.id}>
                        <div className="flex flex-wrap -mx-5 flex-row">
                            <div className="w-full md:w-[200px] px-5 mb-3 md:mb-0">
                                <div className="w-auto space-y-3">
                                    <div>
                                        <span style={{ lineHeight: 1 }} className="inline-block px-4 py-2.5 bg-secondary rounded text-white text-sm uppercase font-extrabold relative tracking-wider">
                                            {orderStatus}
                                        </span>
                                    </div>
                                    <div>
                                        <h2>{orderNumber}</h2>
                                        <p>{orderDate}</p>
                                    </div>
                                    <div>
                                        <div className="font-bold mb-[2px]">Delivery to:</div>
                                        <div>
                                            {order.shippingAddress?.[0].line1},{" "}
                                            {order.shippingAddress?.[0].postCode}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 px-5">
                                {order.shipments.map((sm, index) => {

                                    return <div className="border-b-2 py-4 space-y-4">


                                        <div className="flex">
                                            {sm.orderItems.map(si => {
                                                const imageUrl = jsonParseString(si.imageUrl);
                                                return <div className="w-1/4 space-y-2">
                                                    <img src={imageUrl?.url} alt={si.name} className="
                                                        w-16 h-16 object-cover
                                                        md:w-full md:max-w-28 md:h-auto
                                                    " />
                                                    <div className="hidden md:block text-sm leading-tight">
                                                        {si.name}
                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                        <div>
                                            <h3 className="text-lg">
                                                <ShipmentStatus status={sm.status} />
                                            </h3>
                                            <p>Est. Delivery: {DateTime.fromISO(sm.estDeliveryDate).toLocaleString()}</p>
                                            <p>Tracking: {sm.trackingUrl ? <Link to={sm.trackingUrl}></Link> : "Not available."}</p>
                                        </div>
                                    </div>

                                })}
                            </div>
                        </div>

                        <div className="absolute top-5 right-5">
                            <Button onClick={handleReOrder(order)} loader={reordering === order.id}>Re Order</Button>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

const ShipmentStatus = ({ status }) => {

    const text = useMemo(() => {

        switch (status) {
            case "shoppingCart":
            case "awaitingConsignmentCreation":
            case "waitingToBePicked":
                return "Your shipment is being processed.";
            case "awaitingStock":
                return "Your shipment is awaiting stock. Please check your estimated delivery date.";
            case "inPick":
            case "picked":
            case "awaitingCollection":
                return "Your shipment is being processed by the warehouse.";
            case "collected":
                return "Your shipment has been collected by the courier.";
            case "outForDelivery":
                return "Your shipment is out for delivery.";
            case "delivered":
                return "Your order has been delivered.";
            case "cancelled":
                return "Your order has been cancelled.";
            case "returned":
                return "Your order has been returned.";
            default:
                return "Someting went wrong. Please contact support.";
        }

    }, [status]);

    return text;
}