import React from 'react'
import { ReviewType } from '../../../../../types'
import { BsStar, BsStarFill } from 'react-icons/bs'

interface Props {
    review: ReviewType
}

export default function ReviewItem({ review }: Props) {

    const rating = Number(review?.ratingValue);
    const percentage = (rating / 5) * 100;

    return (
        <>
            <div className='mb-3'>
                <h3 className="
                    text-lg text-base font-bold mb-2
                    xl:text-2xl
                ">{review?.customerName}</h3>

                <div className="relative text-2xl">
                    <div className="flex items-center text-gray-300">
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                    </div>
                    <div className="absolute overflow-hidden top-0 left-0 text-amber-400" style={{
                        width: `${percentage}%`,
                    }}>
                        <div className="flex">
                            <BsStarFill className='flex-none' />
                            <BsStarFill className='flex-none' />
                            <BsStarFill className='flex-none' />
                            <BsStarFill className='flex-none' />
                            <BsStarFill className='flex-none' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-sm md:text-base">{review?.body}</div>
        </>
    )
}
