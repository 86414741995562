import React from "react";
import { Link } from "gatsby";

const CustomBreadcrumb = ({ crumbs }) => {
    return (
        <section className="bg-primary text-white py-5">
            <div className="container mx-auto max-w-screen-lg px-3">
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                    {crumbs.map((crumb, index) => {
                        const isActiveCrumb = index === crumbs.length - 1;
                        const crumbStyle = isActiveCrumb ? { color: "white" } : { color: "black" };

                        return (
                            <>
                                {index != 0 && (
                                    <li key={index + "sep"} className="px-3">
                                        {crumb.crumbSeparator || ">"}
                                    </li>
                                )}
                                <li key={index} className="inline-block uppercase text-sm font-bold">
                                    <Link
                                        to={crumb.pathname}
                                        style={{ ...crumbStyle, ...crumb.crumbStyle }}
                                        activeStyle={{ ...crumb.crumbActiveStyle }}
                                    >
                                        {crumb.crumbLabel}
                                    </Link>
                                </li>
                            </>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

export default CustomBreadcrumb;
