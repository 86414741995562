import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { createElement, DropInElementType } from 'airwallex-payment-elements';
import { RootState } from "../../../../../../store";
import { jsonParseString } from '../../../../../../utils/string';
import { useAirwallex } from './AirwallexContext';
import Loading from '../../../../../shared/Loading';

type Props = {
    onError: (event: Event) => void;
    onSuccess: (event: Event) => void;
    onPendingVerifyAccount: (event: Event) => void;
    onReady?: (event: Event) => void;
    onSwitchMethod?: (event: Event) => void;
}

export default function DropInElement({ onError, onSuccess, onPendingVerifyAccount, onReady, onSwitchMethod }: Props) {

    const ref = React.useRef<HTMLDivElement>(null);

    const { loading } = useAirwallex();

    const [error, setError] = React.useState(false);

    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const customer = useSelector((state: RootState) => state.order.order?.customer);
    const billingAddress = useSelector((state: RootState) => state.order.order?.billingAddress);
    const metadata = useSelector((state: RootState) => state.order.order?.metadata);

    useEffect(() => {

        let element: DropInElementType | null | undefined = undefined;

        const load = async () => {

            if (!orderId || !customer || !billingAddress || !metadata) {
                return;
            }

            const domElement = ref.current;

            if (!domElement) {
                return;
            }

            const parsed = jsonParseString(metadata);

            const pi = parsed?.airwallex?.paymentIntent as any;
            const piId = parsed?.airwallex?.paymentIntent?.id as (string | undefined);
            const clientSecret = parsed?.airwallex?.paymentIntentClientSecret as (string | undefined);

            if (!piId || !clientSecret) {
                setError(true);
                console.warn("Airwallex: PaymentIntent ID or Client Secret not found");
                return;
            }

            element = createElement('dropIn', {
                intent_id: piId,
                client_secret: clientSecret,
                currency: pi.currency,
                shopper_name: `${customer.firstName} ${customer.lastName}`,
                shopper_email: customer.email,
                country_code: billingAddress.countryCode,
            });

            if (!element) {
                return;
            }

            element.mount(domElement);

            element.on("success", onSuccess);
            element.on("error", onError);
            element.on("pendingVerifyAccount", onPendingVerifyAccount);
            onReady && element.on("ready", onReady);
            onSwitchMethod && element.on("switchMethod", onSwitchMethod)
        }

        load();

        return () => {
            if (element) {
                element.unmount();
            }
        }

    }, [loading, ref, metadata, billingAddress, customer, orderId, onError, onSuccess, onPendingVerifyAccount]);

    if (loading) {
        return <Loading />
    }

    if (error) {
        return (
            <div className="
                text-red-500
                text-sm
                text-center
                p-5
                border
                border-red-500
            ">
                Something went wrong! Please try again later.
            </div>
        )
    }

    return (
        <div
            ref={ref}
            key={"airwallex-full-featured-card-" + orderId}
        />
    )
}
