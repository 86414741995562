import React, { useEffect } from "react";
import SiteModal from "../../../shared/SiteModal";

import { Address } from "react-loqate";
import { ADDRESS_FORM } from "../../../checkout/CheckoutPage/constant";
import { getConfigData } from "../../../checkout/CheckoutPage/utils";
import { AddressType, CustomerType } from "../../../../types";
import { addressSchema } from "../../../../utils/validations";
import AddressSearch from "../../../checkout/CheckoutPage/AddressSearch";
import TextInput from "../../../shared/FormComponents/TextInput";
import SelectInput from "../../../shared/FormComponents/SelectInput";
import { AppDispatch, RootState } from "../../../../store";
import { fetchAllCountries } from "../../../../store/country";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../shared/Button";
import * as ECommerceApi from "../../../../services/ecommerceApi";
import { salesChannel } from "../../../../config";
import { updateAddresses } from "../../../../store/order";
const colClassDefault = {
    search: "w-full mb-4",
    form: "w-full"
};
interface Props {
    customer: CustomerType;
    closeModal: () => void;
}
export default function NewAddress({ customer, closeModal }: Props) {

    const countries = useSelector((state: RootState) => state.country.countries);
    const addresses = useSelector((state: RootState) => state.auth.addresses);
    const dispatch = useDispatch<AppDispatch>();

    const [addressData, setAddressData] = React.useState<AddressType>({
        ...getConfigData(ADDRESS_FORM, {
            firstName: customer?.firstName,
            lastName: customer?.lastName
        })
    });

    useEffect(() => {
        dispatch(fetchAllCountries());
    }, []);

    const [saving, setSaving] = React.useState(false);
    const [addressErrors, setAddressErrors] = React.useState({});

    const handleAddressUpdate = (key?: string, value?: string) => {

        if (!key) return;

        setAddressData((prev) => ({
            ...prev,
            [key]: value
        }));

    };

    const handleSaveClick = async () => {
        setSaving(true);

        try {
            await addressSchema.validate(addressData, { abortEarly: false });
        } catch (validationError) {
            const validationErrors = {};
            validationError.inner.forEach(error => {
                validationErrors[error.path] = error.message;
            });
            setAddressErrors(validationErrors);
            setSaving(false);
            return;
        }

        await handleSaveAddressClick(addressData);
        setSaving(false);
    };

    const handleSaveAddressClick = async (data: AddressType) => {
        const ADDRESS_TYPE = "CUSTOMER_ADDRESS";
        const newAddressResponse = await ECommerceApi.createAddress({
            ...data,
            customer: {
                firstName: customer?.firstName,
                lastName: customer?.lastName,
                id: customer?.id,
                email: customer?.email
            } as CustomerType,
            salesChannel: salesChannel,
            addressType: ADDRESS_TYPE
        });
        if (newAddressResponse) {
            dispatch(updateAddresses([...addresses, { ...newAddressResponse }]));
        }
        closeModal();
        setSaving(false);
    };

    const handleSearchSelect = (address: Address) => {
        setAddressData({
            ...addressData,
            line1: address.Line1,
            line2: address.Line2,
            city: address.City,
            countryCode: address.CountryIso2,
            postCode: address.PostalCode
        });
    };

    return (
        <SiteModal>

            <div className="flex flex-wrap -mx-3">

                <div className={`px-3 ${colClassDefault.search}`}>
                    <AddressSearch
                        handleAddressSelect={handleSearchSelect}
                        inputProps={{
                            label: "Search",
                            variant: "standard"
                        }}
                    />
                </div>

                <div className={`px-3 ${colClassDefault.form}`}>
                    <div className="flex flex-wrap -mx-3">
                        {ADDRESS_FORM.map(item => (
                            <div
                                key={`item_${item.name}`}
                                className={`${item.col ? item.col : "w-full"} mb-3 px-3`}
                            >
                                {item.type === "text" ? (
                                    <TextInput
                                        value={!addressData ? "" : addressData[item.name] ?? ""}
                                        label={item.label}
                                        onChange={e => {
                                            let key = item.name;
                                            let value = e.target.value;
                                            handleAddressUpdate(key, value);
                                        }}
                                        autocomplete={item.autocomplete}
                                        error={addressErrors[item.name]}
                                    />
                                ) : null}

                                {item.type === "countrySelector" ? (
                                    <>
                                        <SelectInput
                                            label={item.label}
                                            value={!addressData ? "" : addressData[item.name] ?? ""}
                                            onChange={e => {
                                                let key = item.name;
                                                let value = e.target.value;
                                                handleAddressUpdate(key, value);
                                            }}
                                            autocomplete={item.autocomplete}
                                        >
                                            {countries.map((country: any) => (
                                                <option
                                                    key={`COUNTRY_${country.id}`}
                                                    value={country.alpha2}
                                                >
                                                    {country.name}
                                                </option>
                                            ))}
                                        </SelectInput>
                                    </>
                                ) : null}

                                {addressErrors?.[item.name] && (
                                    <p className="text-red-500 text-[12px] pt-1">
                                        {addressErrors[item.name]}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center mt-3">
                <Button
                    type="transparent"
                    onClick={e => {
                        closeModal();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className="w-[250px]"
                    loader={saving}
                    disabled={saving}
                    onClick={handleSaveClick}
                >
                    Save Address
                </Button>
            </div>
        </SiteModal>
    );
}
