import React, { useMemo } from 'react'
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import CartDisplay from "../../../components/ecommerce/CartDisplay";
import Button from "../../../components/shared/Button";
import { navigate } from "gatsby";
import { formatPrice, getPriceToShow } from "../../../utils/currency";
import OrderInTheNext from "../../../components/ecommerce/OrderInTheNext";
import { useSalesChannel } from '../../../context/SalesChannelContext';

export default function CartPage() {

    // -- HOOKS
    const salesChannel = useSalesChannel();
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode
    );

    const order = useSelector((state: RootState) => state.order?.order);

    const countStr = useMemo(() => {
        var count = (order?.orderItems || []).filter(orderItem => !orderItem.parentId)?.reduce((acc, item) => {
            return acc + item.quantity;
        }, 0);
        var suffix = count > 1 ? "items" : "item";
        return `${count} ${suffix}`
    }, [order?.orderItems]);

    const handleCheckoutClick = () => {
        navigate("/checkout");
    }

    return (
        <section className="py-10 md:py-16 bg-gray-200">
            <div className="container mx-auto">
                <div className="flex flex-wrap -mx-3">
                    <div className="w-full md:w-9/12 px-3 mb-3">

                        <div className='p-5 bg-white'>
                            <h1 className="mb-3 text-3xl">Shopping Cart</h1>
                            <CartDisplay
                                order={order}
                                options={{ showTotals: false, showTitle: false, cardClass: "p-5 border-2 border-gray-100 mb-3" }}
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-3/12 px-3">

                        <div className="p-5 bg-white">

                            {order?.subTotalAmount != null && (
                                <div className="text-2xl mt-3">
                                    <div className="inline-block">
                                        Subtotal ({countStr})
                                    </div>
                                    <div className="inline-block mx-2">
                                        :
                                    </div>
                                    <div className="inline-block font-extrabold">
                                        {formatPrice(order?.subTotalAmount, currencyCode)}
                                    </div>
                                </div>
                            )}

                            <div className="mt-5">
                                <Button
                                    className="w-full"
                                    onClick={handleCheckoutClick}
                                >
                                    Checkout
                                </Button>
                            </div>
                        </div>

                        <div className='mt-3 p-4 bg-white'>
                            <OrderInTheNext hasBreak />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
