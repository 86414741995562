import React, { useMemo } from "react";
import { navigate } from "gatsby";
import { BsBag, BsBasket2, BsCart, BsCart2, BsCart3, BsCart4 } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import CartDisplay from "../../../ecommerce/CartDisplay";
import Button from "../../../shared/Button";
import OrderInTheNext from "../../../ecommerce/OrderInTheNext";
import CartSuggestedProducts from "../../../ecommerce/CartSuggestedProducts";
import { formatPrice } from "../../../../utils/currency";
import { useEcommerceHelper } from "../../../../context/ecommerce";

export default function HeaderCart() {

    // -- HOOKS
    const order = useSelector((state: RootState) => state.order.order);

    const { firstLoaded } = useEcommerceHelper();

    const [flyInOpen, setFlyInOpen] = React.useState(false);

    const count = useMemo(() => {
        return (order?.orderItems || []).filter(orderItem => !orderItem.parentId)?.reduce((acc, item) => {
            return acc + item.quantity;
        }, 0);
    }, [order?.orderItems]);

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    const handleCartClick = () => {
        setFlyInOpen(true);
    };

    const handleCheckoutClick = () => {
        navigate("/checkout");
    };

    const itemsInCart = useMemo(() => order?.orderItems?.reduce((acc, item) => {
        return acc + item.quantity;
    }, 0), [order?.orderItems]);

    React.useEffect(() => {
        const hasItems = (itemsInCart || 0) > 0;
        if (!hasItems) return;
        if (!firstLoaded) return;
        setFlyInOpen(true);
    }, [itemsInCart, firstLoaded]);

    const subTotalAmount = useMemo(() => {
        return (order?.subTotalAmount || 0) < 1 ? undefined : order?.subTotalAmount;
    }, [order?.subTotalAmount]);

    return (
        <>
            <div className="relative h-[80px] flex items-center">
                <button title="Go to Cart Page" className="flex items-center pr-4" onClick={handleCartClick}>
                    <Badge count={count ?? 0}>
                        <BsCart2 className="text-white w-[20px] h-[20px]" />
                    </Badge>
                </button>
            </div>

            {flyInOpen && (
                <div
                    className="
                        fixed top-0 left-0 w-full h-full z-50
                        p-0 bg-black bg-opacity-50 
                        flex justify-end
                        md:p-5
                    "
                    onClick={() => setFlyInOpen(false)}
                >
                    <div onClick={(e) => e.stopPropagation()} className="w-full md:w-[500px] h-full z-60">
                        <div className="relative flex flex-col md:p-5 justify-between bg-white h-full overflow-auto">
                            <div>
                                <h2 className="p-5 pb-1 md:mb-3 md:p-0 md:pb-3">
                                    Shopping Cart
                                </h2>
                                <CartDisplay order={order} textSize="text-base md:text-lg" options={{
                                    showTotals: false,
                                    cardClass: "px-5 pt-3 md:p-5",
                                }} />
                            </div>
                            <div>
                                <CartSuggestedProducts />
                                <div className="mb-3">
                                    <OrderInTheNext />
                                </div>
                                {subTotalAmount && subTotalAmount > 0 && (
                                    <>
                                        <div className="text-3xl text-center pt-3 mb-1">
                                            Total:
                                            <span className="ml-2 font-bold">
                                                {formatPrice(subTotalAmount, currencyCode)}
                                            </span>
                                        </div>
                                        <div className="text-xs text-center">
                                            Shipping and discounts calculated at checkout.
                                        </div>
                                    </>
                                )}
                                <div className="
                                px-2 p-5 w-full flex justify-between
                                md:p-0 md:mt-7
                                ">
                                    <Button type="transparent" onClick={() => setFlyInOpen(false)}>
                                        Continue Shopping
                                    </Button>
                                    <Button onClick={handleCheckoutClick}>
                                        Checkout
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export const Badge = ({ children, count }: {
    children: React.ReactNode;
    count: number;
}) => {

    return (
        <div className="relative">
            {children}
            {count > 0 && (
                <span
                    className="
                        absolute top-[-9px] right-[-9px]
                        w-[18px] h-[18px] flex items-center justify-center rounded-full 
                        bg-primary text-white text-xs"
                >
                    {count}
                </span>
            )}
        </div>
    );

};