import { Link } from "gatsby";
import React from "react";
import SocialsIcon from "../../../../components/content/Socials/Icon";
import TextInput from "../../../../components/shared/FormComponents/TextInput";
import Button from "../../../../components/shared/Button";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import { FooterProps } from "../../Default/Footer";

export default function Footer({ salesChannel, socials, logoFile, menus }: FooterProps) {
    const [email, setEmail] = React.useState("");
    return (
        <footer className="">
            <section className="bg-primary py-10"></section>
            <section className="pt-10 pb-16">
                <div className="container max-w-screen-xl mx-auto">
                    <div className="flex flex-wrap -mx-8">
                        {menus.map(menu => (
                            <div key={menu.id} className="w-full md:w-auto px-8 mb-5 md:mb-0">
                                <h2 className="text-2xl font-bold mb-3">{menu.displayName}</h2>
                                <ul>
                                    {menu.items.map(menuItem => (
                                        <li key={menuItem.id}>
                                            <Link className="text-lg block py-[4px]" to={menuItem.link.src}>{menuItem.link.text || menuItem.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section>
                <div className="container max-w-screen-xl mx-auto border-b-2">
                </div>
            </section>
            <section className="pt-10 pb-20">
                <div className="container max-w-screen-xl mx-auto">
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/2 px-3">
                            <div className="max-w-[200px] max-h-[90px]">
                                <FooterLogo logoFile={logoFile} alt={salesChannel.name + " Footer Logo"} />
                            </div>
                            <div className="mt-8">
                                @ {new Date().getFullYear()} {salesChannel.name}. All rights reserved.
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-3">

                            <div>
                                
                                <h3 className="block text-end mb-3">
                                    Social Media
                                </h3>

                                <ul className="flex justify-center md:justify-end px-3 md:grow -mx-3">
                                    {socials.map(social => (
                                        <li key={social.id} className="px-3">
                                            <a
                                                className="text-primary"
                                                href={social.linkUrl}
                                                target="_blank"
                                            >
                                                <SocialsIcon type={social.type} size={25} />
                                            </a>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}

const FooterLogo = ({ logoFile, alt }: { logoFile?: { node: ImageDataLike }, alt: string }) => {

    if (logoFile) {
        const gatsbyImage = getImage(logoFile);
        if (gatsbyImage) {
            return <GatsbyImage
                image={gatsbyImage}
                alt={alt}
            />;
        }
    }

    return <></>

}