import React from "react";
import Carousel from "react-material-ui-carousel";
import { FieldType } from "../../../types";

import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { FieldProps } from "../../pages/Page/Field";

interface Props {
    field: FieldProps["field"];
}

export default function Slider({ field }: Props) {

    const slider = field.slider;

    return (
        <div className="relative">
            {/* <Carousel animation="slide" indicators={false}>
                {images.map((item, i) => (
                    <Item key={i} image={item.file} overlayText={slider?.overlayText as string} />
                ))}
            </Carousel> */}
            <div
                style={{
                    position: "absolute",
                    zIndex: 1955559,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    top: 0,
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <h2
                    style={{
                        color: "white"
                    }}
                >
                    {slider?.overlayText as string}
                </h2>
            </div>
        </div>
    );
}

interface ItemProps {
    image: IGatsbyImageData;
    overlayText: string;
}
function Item({ overlayText, image }: ItemProps) {
    const imageFile = getImage(image as IGatsbyImageData);
    return (
        <div>
            <GatsbyImage
                image={imageFile as IGatsbyImageData}
                alt={overlayText}
                className="max-w-full h-auto"
            />
        </div>
    );
}
