import React, { useEffect, useMemo, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { formatPrice, getPriceToShow } from "../../../../../utils/currency";
import { useSelector, useDispatch } from "react-redux";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import { AppDispatch, RootState } from "../../../../../store";
import { addToCart } from "../../../../../store/order";
import { Link } from "gatsby";
import Button from "../../../../shared/Button";
import EcommerceApi from "../../../../../services/ecommerceApi";
import { extractScCurrValueFromProductAttr } from "../../../../../utils/string";
import { ProductDataType, ProductType } from "../../../../../types";
import { constructProductMetricHtml, constructProductMetricsHtml } from "../../../../../utils/html";
import { computeItemsInStock } from "../../../../../utils/product";
import ItemsInStock from "../../../../ecommerce/ItemsInStock";

const ProductCard = ({ product }: { product: ProductType }) => {
    const dispatch = useDispatch<AppDispatch>();
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );
    const [adding, setAdding] = React.useState(false);
    const [taxCodes, setTaxCodes] = useState([]);
    const [productData, setProductData] = useState<ProductDataType | null>(null);
    const store = useSelector((state: RootState) => state);
    const { order: orderStore, currencyCode: currencyCodeStore } = store;

    useEffect(() => {
        loadProductData();
    }, []);

    const productUrl = useMemo(() => {
        return product?.url ?? "/home";
    }, [product]);

    const loadProductData = async () => {
        const taxCodes = await EcommerceApi.getProductTaxCodesData(product.id);
        const productData = (await EcommerceApi.getProductData(product.id)) as ProductDataType;
        setProductData(productData);
        setTaxCodes(taxCodes);
    };
    const handleButtonClick = async () => {
        setAdding(true);
        const orderId =
            orderStore?.order && orderStore?.order ? (orderStore?.order as any).id : null;
        const params = {
            orderId: orderId,
            skuId: product.id,
            quantity: 1
        };
        await dispatch(addToCart(params));
        setAdding(false);
    };

    const image = getImage(product?.imageFile);

    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                email
                logo
                phone
                address
                metadata
                type
            }
        }
    `);
    const { salesChannel } = data;

    let priceToShow = getPriceToShow(product.currentPrice, salesChannel, currencyCode);
    let shortDescription = useMemo(
        () =>
            extractScCurrValueFromProductAttr(
                product.shortDescription,
                salesChannel?.id,
                "shortDescription"
            ),
        [salesChannel?.id, product.shortDescription]
    );
    let productMetricsHtml = constructProductMetricsHtml(
        product?.productPricingMetrics,
        product as ProductType,
        productData as ProductDataType
    );

    let productMetricHtml = constructProductMetricHtml(product as ProductType);
    return (
        <div className="p-4 h-full flex flex-col">
            {image && (
                <Link to={productUrl}>
                    <GatsbyImage image={image as any} alt={product?.SKUName} />
                </Link>
            )}

            <div className="text-center ">
                <Link className="block text-lg font-bold" to={productUrl}>
                    {product.SKUName}
                </Link>

                <div className="pb-2">
                    <span className="text-gray-500">{priceToShow}</span>
                </div>
                <div className="h-5">{productMetricsHtml || productMetricHtml}</div>
                <div className="flex -mx-2 mt-2">
                    <Button
                        className="w-1/2 mx-2"
                        padding="py-2 px-3"
                        onClick={handleButtonClick}
                        disabled={adding}
                        loader={adding}
                    >
                        Add to Cart
                    </Button>
                    <Button
                        className="w-1/2 mx-2"
                        type="secondary"
                        padding="py-2 px-3"
                        onClick={() => navigate(productUrl)}
                    >
                        Find out More
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
