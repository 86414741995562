import React, { useEffect, useState, useMemo, useCallback } from "react"
import Cookies from "universal-cookie"
import CookieBanner from ".";
import { graphql, useStaticQuery } from "gatsby";
import { useTrackingContext } from "../../../context/tracking";
import { IConsentEventData, IContentValue } from "../../../context/tracking/types";

export function ConsentForm() {
    const { consentEvent } = useTrackingContext();
    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                email
                logo
                phone
                address
                type
                metadata
            }
        }
    `);

    const [decisionMade, setDecisionMade] = useState(true);
    const cookies = useMemo(() => new Cookies(), []);

    const sendConsent = useCallback((consent) => {
        consentEvent(consent);
    }, []);

    useEffect(() => {
        if (cookies.get("cyclicCookie") !== undefined) {
            setDecisionMade(true)
        } else {
            setDecisionMade(false)
        }
    }, [cookies, setDecisionMade, sendConsent])

    const handleDecision = (outcome: IContentValue) => {

        const consent: IConsentEventData = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
        }

        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 3);
        cookies.set("cyclicCookie", consent, { path: "/", expires: nextYear })

        sendConsent(consent)
        setDecisionMade(true)
    }

    return (
        decisionMade ? (
            <></>
        ) : (
            <CookieBanner
                header={"Cookie Policy"}
                message={JSON.parse(data?.salesChannel?.metadata ?? "{}").settings?.cookieMessage ?? "This site uses cookies to improve your experience."}
                acceptText="Accept"
                denyText="Decline"
                onAccept={() => handleDecision("granted")}
                onDeny={() => handleDecision("denied")}
            />
        )
    )
}
