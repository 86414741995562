import React from "react";
import ProductCard from "./ProductCard";
import { ProductCategoryType } from "../../../../types";

export interface ProductListProps {
    productCategory: ProductCategoryType;
}

const ProductList = ({ productCategory }: ProductListProps) => {
    return (
        <div className="flex flex-wrap">
            {productCategory?.products.map(prd => (
                <React.Fragment key={prd.id}>
                    {prd?.id && (
                        <div key={prd.id} className="w-full md:w-1/3 px-3 mb-6">
                            <ProductCard product={prd} key={prd.id} />
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProductList;
