import React, { useState } from 'react'
import { useEcommerceHelper } from '../../../context/ecommerce';
import SiteModal from '../../shared/SiteModal';
import TextInput from '../../shared/FormComponents/TextInput';
import Button from '../../shared/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export default function EditPostcode() {

    const { postcode, editPostcode, setEditPostcode, setPostcode } = useEcommerceHelper();

    const shippingAddresses = useSelector((state: RootState) => state.order.order?.shippingAddress);

    const [value, setValue] = useState<string>(postcode || "");

    const onApply = (postcode?: string) => () => {

        if (postcode) {
            setValue(postcode);
            setPostcode(postcode);
            setEditPostcode(false);
            return;
        }

        if (value) {
            setPostcode(value);
            setEditPostcode(false);
        }
    }

    const shippingAddress = shippingAddresses?.[0];

    return (
        <>
            {editPostcode && <SiteModal>
                <div className='space-y-4'>

                    <div className='space-y-1'>
                        <h2 className='text-2xl font-bold'>Choose your location</h2>
                        <p className='text-base'>Delivery options and delivery speeds may vary for different locations.</p>
                    </div>

                    {(shippingAddress && shippingAddress.postCode) && <div className='flex items-center space-x-1 p-3 border rounded'>
                        <div className='flex-1'>
                            {`Deliver to ${shippingAddresses[0].line1}, ${shippingAddresses[0].city}, ${shippingAddresses[0].postCode}`}
                        </div>
                        <div>
                            <Button onClick={() => {
                                onApply(shippingAddress.postCode as string)();
                            }}>
                                Use Order Address
                            </Button>
                        </div>
                    </div>}

                    <div className='flex items-end space-x-1'>

                        <div className='flex-1'>
                            <TextInput
                                label='Postcode'
                                value={value}
                                onChange={e => setValue(e.target.value)}
                            />
                        </div>

                        <div>
                            <Button
                                onClick={onApply()}
                                className='w-32 py-3 px-4 border border-primary'>
                                Apply
                            </Button>
                        </div>

                    </div>
                </div>
            </SiteModal>}
        </>
    )
}
