import React from "react";
import { ProductDataType } from "../../../types";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { BsCircle, BsCircleFill } from "react-icons/bs";

interface Props {
    stock?: ProductDataType["stock"];
    loading?: boolean;
}

export default function StockIndicator({ stock, loading = false }: Props) {

    let className = "inline-block py-1 font-extrabold";

    if (!stock || loading) {
        return (
            <div className={className}>
                <div className="w-[75px] h-[18px] bg-gray-200 animate-cyclic-pulse"></div>
            </div>
        );
    }

    return (
        <div
            className={className}
        >
            <div className="flex items-center space-x-3">
                <div style={{ border: `${stock.color}3A 4px solid` }} className={`w-[20px] h-[20px] rounded-full`}>
                    <div style={{ backgroundColor: stock.color }} className={`w-[12px] h-[12px] rounded-full`}>
                    </div>
                </div>
                <div>
                    {stock.text}
                </div>
                {stock?.presaleDate && (
                    <>
                        <span className="px-1">|</span>
                        <div className="uppercase font-bold">
                            EST. DISPATCH: {stock.presaleDate}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
