import React from 'react'
import TrustpilotWidget from '.'

type Props = {
    sku: string;
    locale?: string;
    templateId?: string;
    theme?: string;
    width?: string;
}

export default function TrustpilotMiniMultiSource({
    sku,
    locale = "en-GB",
    theme = "light",
    width = "100%",
}: Props) {
    return (
        <TrustpilotWidget
            className="trustpilot-widget"
            data-locale={locale}
            data-template-id={"577258fb31f02306e4e3aaf9"}
            data-theme={theme}
            data-sku={sku}

            data-style-height="24px"
            data-style-width={width}
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-style-alignment="center"
        />
    )
}
