import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import ecommerceApi from "../../../../services/ecommerceApi";
import { AddressType, CustomerType, IConfirmationDialog } from "../../../../types";
import { updateAddresses } from "../../../../store/order";
import AddressDisplay from "../../AddressDisplay";
import Button from "../../../shared/Button";
import AddressModal from "../../AddressModal";
import NewAddress from "../NewAddress";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { BsPlus } from "react-icons/bs";
import { getCustomerAddresses } from "../../../../store/auth";
import { graphql, useStaticQuery } from "gatsby";
import axios from "axios";
import { validateAddress } from "../../../../utils/loqateValidations";

export default function MyAddresses() {
    const dispatch = useDispatch<AppDispatch>();
    const [confirm, setConfirm] = useState<IConfirmationDialog>({
        show: false,
        onConfirm: () => {
            return;
        },
        onCancel: () => {
            return;
        }
    });

    const customer = useSelector((state: RootState) => state.auth.customer);
    const addresses = useSelector((state: RootState) => state.auth.addresses);
    const [messages, setMessages] = useState<string[]>([]);
    const [addressToEdit, setAddressToEdit] = useState<AddressType | null>(null);
    const [showNewAddress, setNewAddress] = useState(false);

    const loqateData = useStaticQuery(graphql`
        query {
            loqate: allIntegration(filter: { type: { eq: "loqate" } }) {
                nodes {
                    id
                    config {
                        property
                        propertyId
                        value
                    }
                }
            }
        }
    `);

    const integration = loqateData.loqate.nodes[0];

    const load = useCallback(async () => {
        dispatch(getCustomerAddresses());
    }, [customer]);

    const handleSaveAddressClick = async (data: AddressType) => {
        const { valid, messages } = await validateAddress(data, integration);
        if (!valid) {
            setMessages(messages);
            return;
        }

        await ecommerceApi.updateAddress({
            ...data,
            customerId: customer?.id as string,
            addressType: "CUSTOMER_ADDRESS"
        });

        let newAddresses = [...(addresses ?? [])];
        let addrIdx = addresses.findIndex(addr => addr.id === data.id);
        if (addrIdx >= 0) {
            newAddresses[addrIdx] = data;
            setTimeout(() => {
                dispatch(updateAddresses(newAddresses));
            }, 20);
        }
        setAddressToEdit(null);
    };

    const closeDialog = () => {
        setConfirm({
            ...confirm,
            show: false
        });
    };

    React.useEffect(() => {
        load();
    }, [load]);

    return (
        <>
            <div className="">
                {(!addresses || addresses.length == 0) && (
                    <p className="text-center p-5 bg-white mb-2">You currently have no addresses.</p>
                )}
                {addresses?.map(address => {

                    const onConfirmDelete = async () => {

                        if (!address?.id || !customer?.id) return;

                        await ecommerceApi.deleteAddress(address?.id, "CUSTOMER_ADDRESS", customer?.id, "");

                        load();
                        closeDialog();
                    };
                    const onCancelDelete = async () => {
                        closeDialog();
                    };

                    return (
                        <div className="mb-3" key={address?.id as string}>
                            <AddressDisplay bg="bg-white" address={address}>
                                <div className="flex space-x-2">
                                    <Button
                                        className="w-full"
                                        onClick={e => {
                                            e.preventDefault();
                                            setAddressToEdit(address);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        className="w-full"
                                        onClick={async e => {
                                            e.preventDefault();
                                            setConfirm({
                                                onCancel: onCancelDelete,
                                                onConfirm: onConfirmDelete,
                                                show: true
                                            });
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </AddressDisplay>
                        </div>
                    );
                })}

                <div className="flex justify-center pt-3">
                    <Button
                        onClick={async e => {
                            e.preventDefault();
                            setNewAddress(true);
                        }}
                    >
                        <BsPlus className="mr-2" />
                        Add New Address
                    </Button>
                    <br />
                </div>
            </div>

            {/* MODALS */}
            {addressToEdit != null && (
                <AddressModal
                    address={addressToEdit}
                    handleSaveAddressClick={handleSaveAddressClick}
                    handleCancel={() => setAddressToEdit(null)}
                    excludeSearch={true}
                    allowCancel={true}
                    addressErrorsProp={{ "postCode": messages.join(".") }}
                />
            )}

            {customer && showNewAddress && (
                <NewAddress
                    customer={customer}
                    closeModal={() => {
                        setNewAddress(false);
                    }}
                />
            )}

            <ConfirmationDialog
                onCancel={confirm.onCancel}
                onConfirm={confirm.onConfirm}
                show={confirm.show}
                maxWidth="550px"
            >
                <div className="p-3">
                    <h2>Delete Addess?</h2>
                    <p className="mt-2">
                        Are you sure you want to delete this address? This action cannot be undone.
                    </p>
                </div>
            </ConfirmationDialog>

        </>
    );
}
