import React, { useMemo, useState } from "react";
import TextInput from "../../../../../../shared/FormComponents/TextInput";
import { IPaymentGateway } from "../..";
import { BsCreditCard, BsExclamationCircleFill } from "react-icons/bs";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";

export type CardInfo = {
    cardHolderName?: string;
    cardNumber?: string;
    cardExpiryDate?: string;
    securityCode?: string;
};

export type CardDetailsErrorType = {
    cardHolderName: boolean;
    cardNumber: boolean;
    cardExpiryDate: boolean;
    securityCode: boolean;
};

interface Props {
    cardInfo: CardInfo;
    cardType: string | null;
    cardNotSupported: boolean;
    setCardType: (cardType: string | null) => void;
    errors: CardDetailsErrorType | null;
    handleChange: (value: any, field: string) => void;
    acceptedCards?: IPaymentGateway["acceptedCards"];
}

export default function SagePayCardInput({ cardType, cardNotSupported, setCardType, cardInfo, errors, handleChange, acceptedCards }: Props) {

    const [touched, setTouched] = useState<boolean>(false);

    function checkCardTypeByReg(cardNum: string) {

        let type: string | null = null;

        for (const item of acceptedCards || []) {
            let pattern = new RegExp(item.cardRegex);

            const str = cardNum.replace(/\s/g, "");

            if (pattern.test(str)) {
                type = item.code;
            }
        }

        setCardType(type);
    }

    function formatCreditCardNumber(cardNumber: string) {
        const cleanedNumber = cardNumber.replace(/\D/g, "");
        const groups = cleanedNumber.match(/.{1,4}/g);
        const formattedNumber = groups ? groups.join(" ") : "";
        return formattedNumber;
    }

    function formatDate(inputDate) {
        inputDate = inputDate.replace("/", "");
        if (inputDate.length < 4) {
            return inputDate;
        }
        const month = inputDate.substring(0, 2);
        const day = inputDate.substring(2);
        const formattedDate = `${month}/${day}`;
        return formattedDate;
    }

    React.useEffect(() => {
        if (cardInfo.cardNumber) {
            checkCardTypeByReg(cardInfo.cardNumber);
        }
        setTouched((cardInfo?.cardNumber?.length || 0) > 0);
    }, [cardInfo.cardNumber]);

    return (
        <>
            <div>

                <div className="mb-1 flex justify-between">
                    <div>
                        <label className="inline-block text-base font-bold mb-1 uppercase">Card Information</label>
                    </div>
                    <div className="flex justify-end items-center">
                        {(acceptedCards?.length || 0) > 0 && (
                            <>
                                <div className="text-xs">
                                    We accept the following cards.
                                </div>
                                {acceptedCards?.map((item, index) => {
                                    return <div className="ml-2">
                                        <CardIcon key={index} cardType={item.code} touched={true} />
                                    </div>
                                })}
                            </>
                        )}
                    </div>
                </div>

                <div className="relative bg-gray-100">
                    <div className="flex">
                        {(acceptedCards?.length || 0) > 0 && (
                            <div>
                                <CardType cardType={cardType} touched={touched} />
                            </div>
                        )}
                        <div className="grow">
                            <TextInput
                                value={cardInfo.cardNumber || ""}
                                onChange={e => {
                                    setTouched(true)
                                    let formatedNum = formatCreditCardNumber(e.target.value);
                                    checkCardTypeByReg(e.target.value);
                                    handleChange(formatedNum, "cardNumber");
                                }}
                                placeholder="Card Number"
                                error={touched && errors?.cardNumber}
                            />
                        </div>
                        <div className="w-[85px]">
                            <TextInput
                                value={cardInfo.cardExpiryDate || ""}
                                onChange={e =>
                                    handleChange(formatDate(e.target.value), "cardExpiryDate")
                                }
                                placeholder={"MM/YY"}
                                error={touched && errors?.cardNumber}
                                maxLength={4}
                            />
                        </div>
                        <div className="w-[60px]">
                            <TextInput
                                value={cardInfo.securityCode || ""}
                                onChange={e => handleChange(e.target.value, "securityCode")}
                                placeholder={"CVV"}
                                error={touched && errors?.cardNumber}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>

                {touched && cardNotSupported && (
                    <div className="flex items-center text-red-400 mt-2 text-sm pl-5">
                        <BsExclamationCircleFill className="inline mr-3" />
                        Your Card is not supported.
                    </div>
                )}


            </div>
        </>
    );
}

const CardType = ({ cardType, touched }: { cardType: string | null, touched: boolean }) => {

    return <div className={`h-full w-[35px] flex items-center justify-end`}
    >
        <CardIcon cardType={cardType} touched={touched} />
    </div>
}

const CardIcon = ({ cardType, touched }: { cardType: string | null, touched: boolean }) => {

    if (!touched) return <>
        <BsCreditCard size={20} />
    </>

    switch (cardType) {
        case "visa":
            return <FaCcVisa size={20} />
        case "mastercard":
        case "maestro":
            return <FaCcMastercard size={20} />
        default:
            return <>
                <BsCreditCard size={20} className="text-red-400" title="Card not supported" />
            </>;
    }
}