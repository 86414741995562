import React from "react";
import {
    BlockType,
    ContentType,
    FieldType,
    IFaqProp,
    IFrameProps,
    LinkItemType,
    ModelType,
    PageImageType,
    PageListType,
    ProductCategoryType,
    ProductType,
    ReviewType
} from "../../../../types";
import parse from "html-react-parser";

// Pages
import PageImage from "../../../content/PageImage";
import PageList from "../../../content/PageList";
import LinkItem from "../../../content/LinkItem";
import CustomBreadcrumb from "../../../shared/CustomBreadcrumb";
import Slider from "../../../shared/Slider";
import ReviewList from "../../../ecommerce/ReviewsList";
import Faq from "../../../content/Faq";

// Headless CMS
import CMSContentList from "../../../content/CMSContentList";
import CMSContentItem from "../../../content/CMSContentItem";

// Ecommerce
import ProductList from "../../../ecommerce/ProductList";
import ProductDetail from "../../../ecommerce/ProductDetail";
import ProductSelector from "../../../ecommerce/ProductSelector";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import GetComponent from "../../../websiteTemplates/_GetComponent";
import EditorJsRenderer from "../../../shared/EditorJSRender";
import IFrame from "../../../content/IFrame";
import BackgroundImage from "../../../content/BackgroundImage";
import ProductsDisplayByTag from "../../../content/ProductsDisplayByTag";
import RelatedProducts from "../../../ecommerce/RelatedProducts";
import { jsonParseString } from "../../../../utils/string";
export interface FieldProps {
    field: Pick<FieldType, "fieldId" | "value" | "data"> & Partial<FieldType>;
    salesChannel: ISalesChannel;
    block?: BlockType;
    location?: any;
    pageTitle?: string;
}

export default function Field({ field, location, pageTitle, salesChannel, block }: FieldProps) {

    const data = field.data;
    const val = jsonParseString(field?.value)?.value;
    const valType = typeof val;

    try {
        switch (field.fieldId) {
            case "product":
                const defaultProduct = (data.product || val) as ProductType;
                return <ProductSelector {...defaultProduct} />;
            case "products":
                const productCategory = data?.productCategory as ProductCategoryType;
                return <ProductList productCategory={productCategory} />;
            case "related-products":
                return <RelatedProducts />;
            case "product-category-list":
                var categories = (data.categories || []) as ProductCategoryType[];
                var displayType = data.displayType || "list";

                return (
                    <GetComponent
                        template={salesChannel.websiteTemplate}
                        component="ProductCategoryList"
                        props={{
                            categories,
                            salesChannel: salesChannel,
                            displayType,
                            redirectUrl: val.redirectUrl || undefined
                        }}
                    />
                );
            case "product-detail":
                return <ProductDetail {...data} />;
            case "page-list":
                var pageListConfig = val as PageListType;
                return <PageList pageListConfig={pageListConfig} field={field} />;
            case "link":
                var linkItem = val as LinkItemType;
                return <LinkItem linkItem={linkItem} />;
            case "image":
                return <PageImage field={field} val={val} />;
            case "file_url":
                var pageImage = val as PageImageType;
                var url = pageImage.src;
                return typeof url === "string" ? url : null;
            case "reviews":
                return <ReviewList reviews={val?.reviews as ReviewType[]} reviewType={val?.reviewType} skuCode={val?.skuCode} />;
            case "cms_item":
                // TODO: FIX THIS
                return null;
            // return (
            //     <CMSContentItem
            //         id={val.contentId}
            //         selectedTransformation={val.selectedTransformation}
            //     />
            // );
            case "cms_list":
                return (
                    <CMSContentList
                        modelID={val.modelID}
                        transformation={val.selectedTransformation}
                    />
                );
            case "slider":
                return <Slider field={field} />;
            case "faq":
                return <Faq data={val as IFaqProp} />;
            case "iframe":
                return <IFrame data={val as any} />;
            case "breadcrumb":
                return (
                    <CustomBreadcrumb
                        crumbs={[
                            {
                                pathname: location.pathname,
                                crumbLabel: pageTitle,
                                crumbSeparator: " > "
                            }
                        ]}
                    />
                );
            case "html":
                let storedhtml = JSON.stringify(val);
                return <EditorJsRenderer content={storedhtml} />;
            case "wysiwyg":
                return parse(val);
            case "background_image":
                return (
                    <BackgroundImage
                        field={field}
                        block={block as BlockType}
                        salesChannel={salesChannel}
                        pageTitle={pageTitle}
                    />
                );
            case "product_display_by_tag":
                return <ProductsDisplayByTag tagCategories={val?.tagCategories ?? []} />;
            default:
                return valType === "string" || valType === "number" ? val : JSON.stringify(val);
        }
    } catch (e) {
        console.warn("e", e, field);
        return null;
    }
}
