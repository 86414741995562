import React from 'react'
import { AddressType } from '../../../types'

interface Props {
    address: AddressType
    bg?: string
    children?: React.ReactNode
}

export default function AddressDisplay({ address, children, bg = "bg-gray-100" }: Props) {
    return (

        <div className={`p-5 ${bg}`}>

            <div className="flex items-center -mx-3">

                <div className="px-3 grow">
                    {address && (
                        <div className="text-base md:text-lg leading-tight">
                            <div>
                                {address.firstName} {address.lastName}
                            </div>
                            <div>
                                {address.line1}, {address.postCode}
                            </div>
                        </div>
                    )}
                </div>

                {!!children && (
                    <div className="px-3">

                        {children}

                    </div>
                )}

            </div>
        </div>
    )
}
