import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, IconButton, Slide, Stack } from "@mui/material";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";

type IUTSlider = {
    items: any[];
    renderItem: (item: any) => React.ReactNode;
    itemsPerPage: number;
    itemKey: keyof IUTSlider["items"] | "root";
    index: number;
    slideAnimation: boolean;
    onPageChange?: (index: number) => void;
    width?: string;
}

export default function UTSlider({ itemKey, items, itemsPerPage, renderItem, index, slideAnimation, onPageChange, width }: IUTSlider) {
    const [currentPage, setCurrentPage] = useState(0);
    const [slideDirection, setSlideDirection] = useState<
        "right" | "left" | undefined
    >();

    useEffect(() => {
        if (!slideAnimation) {
            setSlideDirection(undefined);
            return;
        }
    }, [slideAnimation]);

    const handleNextPage = () => {
        setSlideDirection("left");
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const handlePrevPage = () => {
        setSlideDirection("right");
        setCurrentPage((prevPage) => prevPage - 1);
    };

    useEffect(() => {
        setCurrentPage(index);
    }, [index]);

    useEffect(() => {
        if (!onPageChange) { return };
        onPageChange(currentPage);
    }, [currentPage]);

    return (
        //  outer box that holds the carousel and the buttons
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                overflow: "hidden",
                width: "100%",
            }}
        >
            <IconButton
                onClick={handlePrevPage}
                sx={{ margin: 1 }}
                disabled={currentPage === 0}
            >
                <MdOutlineArrowBack />
            </IconButton>
            <Stack direction="row" alignContent={"space-evenly"} className="-mx-[4px]" style={{ width: width }}>
                {items.map((item, index) => {
                    const shouldShow = (index >= currentPage * itemsPerPage && index < (currentPage * itemsPerPage) + itemsPerPage);
                    const key = itemKey === "root" ? index : item[itemKey];
                    return <Box
                        key={key}
                        className={`${slideDirection === "left" && "animate-slide-in-from-right"} ${slideDirection === "right" && "animate-slide-in-from-left"}
                            px-[4px]
                        `}
                        sx={{
                            display: shouldShow ? "block" : "none",
                            width: "100%",
                        }}
                    >
                        {renderItem(item)}
                    </Box>
                })}
            </Stack>
            <IconButton
                onClick={handleNextPage}
                sx={{
                    margin: 1,
                }}
                disabled={
                    currentPage >= Math.ceil((items.length || 0) / itemsPerPage) - 1
                }
            >
                <MdOutlineArrowForward />
            </IconButton>
        </Box>
    );
}