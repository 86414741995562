import React from "react";
import { Link } from "gatsby";
import { BsList } from "react-icons/bs";
import HeaderAccount from "../../../layouts/Layout/Header/Account";
import HeaderCart from "../../../layouts/Layout/Header/Cart";
import { HeaderProps } from "../../Default/Header";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";

export default function Header({
    salesChannel,
    isSticky,
    logoFile,
    isDrawerVisible,
    setIsDrawerVisible,
    isMobile,
    windowSize,
    menus
}: HeaderProps) {

    const menu = menus?.length > 0 ? menus[0] : null;

    return (
        <>
            <header
                className={`h-[80px] ${isSticky ? "sticky" : ""
                    } top-0 left-0 w-full bg-secondary z-40`}
            >
                <div className="container max-w-screen-2xl mx-auto">
                    <div className="flex justify-between md:justify-start items-center -mx-3">
                        <div className="px-3 md:hidden">
                            <button
                                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                                className="flex items-center px-4 text-white"
                            >
                                <BsList className="w-[25px] h-[25px]" />
                            </button>
                        </div>
                        <div className="px-3 max-w-[200px] flex justify-center md:justify-start">
                            <Link to="/">
                                <HeaderLogo
                                    logoFile={logoFile}
                                    alt={salesChannel.name + " Logo"}
                                />
                            </Link>
                        </div>
                        <div
                            style={{
                                top: isDrawerVisible || !isMobile ? 0 : "-100%",
                                opacity: isDrawerVisible || !isMobile ? 1 : 0
                            }}
                            className="
                                flex justify-end fixed left-0 w-full h-full bg-black bg-opacity-50 z-30
                                md:static md:h-auto md:bg-transparent
                                md:px-3 md:grow md:justify-end transition-[opacity] md:transition-none
                            "
                            onClick={() => setIsDrawerVisible(false)}
                        >
                            <ul
                                style={{
                                    width:
                                        (windowSize?.width ?? 1000) > 767
                                            ? "auto"
                                            : "calc(100% - 60px)",
                                    right: isDrawerVisible || !isMobile ? 0 : "-100%"
                                }}
                                className="
                                relative flex flex-col h-full bg-secondary py-[50px]
                                md:h-auto md:bg-transparent md:p-0 md:flex-row md:w-auto transition-[right] md:transition-none
                            "
                            >
                                <li>
                                    <span
                                        className="
                                        block px-8 pb-5
                                        md:hidden
                                    "
                                    >
                                        <span className="bg-primary font-bold uppercase text-sm px-2 py-1 rounded">
                                            Menu
                                        </span>
                                    </span>
                                </li>
                                {menu?.items?.map(menuItem => (
                                    <li key={menuItem.id} className="">
                                        <Link
                                            to={`${menuItem.link?.src}`}
                                            className="
                                                block px-8 py-[13px] text-xl text-white font-semibold
                                                md:px-3 md:py-2 md:text-base md:font-normal

                                            "
                                        >
                                            {menuItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="px-3 hidden md:block">
                            <HeaderAccount />
                        </div>
                        <div className="px-3">
                            <HeaderCart />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

const HeaderLogo = ({ logoFile, alt }: { logoFile?: { node: ImageDataLike }, alt: string }) => {

    if (logoFile) {
        const gatsbyImage = getImage(logoFile);
        if (gatsbyImage) {
            return <GatsbyImage
                image={gatsbyImage}
                alt={alt}
            />;
        }
    }

    return <></>

}