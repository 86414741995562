import React, { ReactElement, useRef, useState } from "react";
import { FaCopy, FaPen } from "react-icons/fa";
import { editCustomerReferralCode } from "../../../services/ecommerceApi";
import { CustomerType } from "../../../types";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import { useDispatch } from "react-redux";
import { updateCustomer } from "../../../store/auth";
import SiteModal from "../../shared/SiteModal";
import Button from "../../shared/Button";
import TextInput from "../../shared/FormComponents/TextInput";
import SuccessModal from "../SuccessModal";

type ReferralCodeProps = {
    referralCode: string;
    className?: string;
    copiedTextColor?: string;
    edit?: boolean;
    customer?: CustomerType | null
};

const ReferralCode = ({ referralCode, className, copiedTextColor, edit, customer }: ReferralCodeProps): ReactElement => {
    const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);
    const [successDelay, setSuccessDelay] = useState<NodeJS.Timeout | null>(null);
    const [localReferralCode, setLocalReferralCode] = useState<string | undefined>();
    const salesChannel = useSalesChannel();
    const referralCodeRef = useRef<HTMLParagraphElement | null>(null);
    const [displayingMessage, setDisplayingMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [modalLoading, setModalLoading] = useState(false);

    const copyCodeToClipboard = (e: React.MouseEvent<HTMLDivElement>) => {
        navigator.clipboard.writeText(referralCode)
        temporaryReplace("Copied", referralCode);
    };

    const temporaryReplace = (newString: string, returnString: string) => {
        if (!referralCodeRef || !referralCodeRef.current) return;
        const pWidth = referralCodeRef.current.clientWidth || "auto";
        referralCodeRef.current.innerText = newString;
        referralCodeRef.current.style.width = `${pWidth}px`;
        referralCodeRef.current.classList.add(copiedTextColor || "text-tertiary");
        if (delay) clearTimeout(delay);
        setDisplayingMessage(true);
        setDelay(setTimeout(() => {
            if (!referralCodeRef || !referralCodeRef.current) return;
            setDisplayingMessage(false);
            referralCodeRef.current.innerText = returnString;
            referralCodeRef.current.style.width = "auto";
            referralCodeRef.current.classList.remove(copiedTextColor || "text-tertiary");
        }, 2000));
    }

    const updateReferralCode = async () => {
        if (!customer || !customer.id) {
            return;
        }
        if (!salesChannel.id) {
            return;
        }
        if (!localReferralCode) {
            setErrorMessages(["Referral code cannot be empty."])
            return;
        }
        setModalLoading(true);
        const response = await editCustomerReferralCode(localReferralCode, salesChannel.id, customer.id);
        setModalLoading(false);
        if (response !== true) {
            setErrorMessages([response]);
            return;
        }
        await dispatch(updateCustomer({ ...customer, referralCode: localReferralCode }));
        setShowModal(false);
        setShowSuccessModal(true);
        setErrorMessages([])
    };

    return <div className={`w-full flex flex-row gap-2 ${className}`}>
        <div className="cursor-copy" onClick={copyCodeToClipboard}>
            <p ref={referralCodeRef} className="text-lg text-center min-w-[100px]">
                {referralCode}
            </p>
        </div>
        <div className="cursor-copy content-center" onClick={copyCodeToClipboard}>
            <FaCopy />
        </div>
        {edit && <div className="cursor-pointer content-center" >
            <FaPen onClick={() => { if (!displayingMessage) setLocalReferralCode(referralCode); setShowModal(true) }} />
        </div>}
        {showModal && <SiteModal>
            <div className="flex flex-col gap-4">
                <h3>Edit Referral Code</h3>
                <TextInput
                    label="Referral Code"
                    name="referralCode"
                    value={localReferralCode || ""}
                    onChange={(e) => setLocalReferralCode(e.target.value)}
                />
                {errorMessages.length > 0 && <div className="bg-red-100 text-red-500 p-2 rounded">
                    {errorMessages.map((message, index) => <p key={index}>{message}</p>)}
                </div>}
                <div className="flex flex-row justify-between">
                    <Button type={"secondary"} onClick={() => { setShowModal(false); setErrorMessages([]); }}>
                        Close
                    </Button>
                    <Button loader={modalLoading} onClick={() => { updateReferralCode() }}>
                        Save
                    </Button>
                </div>
            </div>
        </SiteModal>}
        <SuccessModal show={showSuccessModal} setShow={setShowSuccessModal} message={"Your Referral code has been updated."} />
    </div>
};

export default ReferralCode;