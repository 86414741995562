import React from "react";
import ProductCard from "./ProductCard";
import { ProductCategoryType } from "../../../../types";

export interface ProductListProps {
    productCategory: ProductCategoryType;
}

const ProductList = ({ productCategory }: ProductListProps) => {

    return (
        <div className="flex flex-wrap -mx-2">
            {productCategory?.products.map(prd => (
                <React.Fragment key={prd.id}>
                    {prd?.id && (
                        <div key={prd.id} className="
                                w-full px-2 mb-2
                                md:w-1/4 md:mb-4
                            ">
                            <ProductCard product={prd} key={prd.id} />
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProductList;
