import React, { useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import EcommerceService from "../../../../../../services/ecommerceApi";
import { updateOrder } from "../../../../../../store/order";
import Spinner from "../../../../../shared/Spinner";
import Button from "../../../../../shared/Button";
import { RootState } from "../../../../../../store";
import TextInput from "../../../../../shared/FormComponents/TextInput";
import CardNumberInput from "../../../../../shared/FormComponents/CardNumberInput";
import SagePayCardInput, { CardDetailsErrorType, CardInfo } from "./SagePayCardInput";
import { IPaymentGateway } from "..";
import { BsExclamationCircle, BsExclamationCircleFill } from "react-icons/bs";

interface Props {
    paymentGatewayId: string;
    setRedirecting: (redirecting: boolean) => void;
    rendered: () => void;
    handlePlaceOrder: (order: any) => Promise<void>;
    TotalComponent?: React.ReactNode;
    acceptedCards?: IPaymentGateway["acceptedCards"];
}

export default function SagePay({ paymentGatewayId, setRedirecting, rendered, handlePlaceOrder, TotalComponent, acceptedCards }: Props) {

    const orderStore = useSelector((state: RootState) => state.order);
    const order = orderStore.order;

    const [loading, setLoading] = useState(false);

    const [cardInfo, setCardInfo] = useState<CardInfo>({
        cardHolderName: "",
        cardNumber: "",
        cardExpiryDate: "",
        securityCode: ""
    });


    const [cardType, setCardType] = useState<string | null>(null);

    const [errors, setErrors] = useState<CardDetailsErrorType | null>({
        cardHolderName: false,
        cardNumber: false,
        cardExpiryDate: false,
        securityCode: false
    });

    const handleChange = (value: any, field: string) => {
        setCardInfo({
            ...cardInfo,
            [field]: value
        });
    };

    const onSubmit = async (e: React.MouseEvent) => {
        e.preventDefault();

        setLoading(true);

        const updatedErrors = checkErrors();

        if (updatedErrors && Object.values(updatedErrors).some(error => error)) {
            setLoading(false);
            return;
        }

        setRedirecting(true);

        try {
            const cardNumber = (cardInfo.cardNumber || "").replace(/\s/g, "");
            const cardExpiryDate = (cardInfo.cardExpiryDate || "").replace(/\//g, "");

            const response = await EcommerceService.placeOrder(order?.id, paymentGatewayId, {
                sagepay: {
                    cardDetails: {
                        cardholderName: cardInfo.cardHolderName,
                        cardNumber: cardNumber,
                        expiryDate: cardExpiryDate,
                        securityCode: cardInfo.securityCode
                    }
                }
            });

            await handlePlaceOrder(response);

            setLoading(false);

        } catch (error) {
            console.error("error", error);
            setRedirecting(false);
            setLoading(false);
        }
    };

    const checkErrors = () => {
        const updatedErrors = {
            cardHolderName: !cardInfo.cardHolderName || cardInfo.cardHolderName === "",
            cardNumber: !cardInfo.cardNumber || cardInfo.cardNumber === "",
            cardExpiryDate: !cardInfo.cardExpiryDate || cardInfo.cardExpiryDate === "",
            securityCode: !cardInfo.securityCode || cardInfo.securityCode === ""
        };

        setErrors(updatedErrors);

        return updatedErrors;
    };

    const cardNotSupported = useMemo(() => {
        return ((acceptedCards?.length || 0) > 0) ? !cardType : false;
    }, [acceptedCards, cardType]);

    useEffect(() => {
        rendered();
    }, []);

    useEffect(() => {
        checkErrors();
    }, [cardInfo]);

    return (
        <form className="">
            <div className="hidden">
                <div className="flex justify-end">
                    <div className="w-full max-w-[200px]">
                        <div className="p-5 bg-secondary text-white rounded">
                            <div className="h-[20px] flex items-center">{cardInfo.cardNumber}</div>
                            <div className="h-[20px] flex items-center text-xs py-1">
                                {cardInfo.cardExpiryDate}
                            </div>
                            <div className="h-[20px] flex items-center text-xs">
                                {cardInfo.cardHolderName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <TextInput
                    value={cardInfo.cardHolderName || ""}
                    onChange={e => handleChange(e.target.value, "cardHolderName")}
                    label="Card Holder Name"
                    error={errors?.cardHolderName}
                />
            </div>

            <div className="mb-6">
                <SagePayCardInput
                    acceptedCards={acceptedCards}
                    cardType={cardType}
                    cardNotSupported={cardNotSupported}
                    setCardType={setCardType}
                    cardInfo={cardInfo}
                    errors={errors}
                    handleChange={handleChange}
                />
            </div>

            <div className="flex items-center justify-end">

                {TotalComponent && (
                    <div className="mr-3">
                        {TotalComponent}
                    </div>
                )}

                <div>
                    <Button
                        onClick={onSubmit}
                        disabled={loading || (!!errors && Object.values(errors).some(error => error)) || cardNotSupported}
                        className="w-full min-w-[150px] max-w-[200px]"
                    >
                        Buy Now
                        {loading && <Spinner stroke="#fff" className="ml-2" />}
                    </Button>
                </div>
            </div>
        </form>
    );
}
