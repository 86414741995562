import React, { useEffect, useMemo } from "react";
import { IOrderItem, OrderType } from "../../../../types";
import OrderItemQuantityModifier from "../../OrderItemQuantityModifier";
import { formatPrice } from "../../../../utils/currency";
import Button from "../../../shared/Button";
import { maximumQuantityToAddHelper } from "../../../websiteTemplates/Default/ProductCategoryListItem/maximumQuantityToAddWithoutCurrentShoppingCart";
import ecommerceApi from "../../../../services/ecommerceApi";

interface Props {
    order: OrderType | null;
    item: IOrderItem;
    options: any;
    textSize: string;
    currencyCode: string;
    handleOnRemove: (orderItemId: string) => void;
}

interface OrderItemFile {
    id: string;
    url: string;
    name: string;
}

export default function CartItem({ order, item, options, textSize, currencyCode, handleOnRemove }: Props) {
    const [selectedProductData, setSelectedProductData] = React.useState<any>(null);
    const file: OrderItemFile | undefined = useMemo(() => {
        try {
            if (item.imageUrl) {
                const file: OrderItemFile = JSON.parse(item.imageUrl) as OrderItemFile;
                return file;
            }
        } catch (e) {
            console.log(e);
        }
        return;
    }, [item.imageUrl]);

    const cartItemAmount = useMemo(() => {
        return item?.totalAmount;
    }, [item.totalAmount]);

    const loadProductData = async () => {
        const selectedProductDataRes = await ecommerceApi.getProductData(item.skuId);
        setSelectedProductData(selectedProductDataRes);
    };

    useEffect(() => {
        loadProductData()
    }, [item.skuId])

    const maximumQuantityToAdd = useMemo(() => {
        if (!selectedProductData) {
            return undefined;
        }
        if (selectedProductData) {
            return maximumQuantityToAddHelper(order, item.skuId, selectedProductData, true);
        }
    }, [item, selectedProductData, order]);

    return (
        <div className={options.cardClass}>
            <div key={`${item.id}`} className="flex flex-wrap md:flex-nowrap justify-between -mx-3">
                <div className="px-3 w-2/6 md:grow-0 md:w-32">
                    {!!file && (
                        <img
                            alt={item.name + " cart item."}
                            className="
                            mb-3 w-full h-28 object-contain
                            md:mb-0 md:w-full md:h-full "
                            src={file.url}
                            width={"128px"}
                            height={"112px"}
                        />
                    )}
                </div>

                <div className="h-full w-4/6 md:grow px-3">
                    <div className="flex flex-col space-between">
                        <div className="mb-2">
                            <h3 className={`mb-2 ${textSize}`} style={{ lineHeight: 1.1 }}>
                                {item.name}
                            </h3>
                            {!options.allowQuantityChange && (
                                <div className="pb-2 font-bold">
                                    <span className="text-gray-500 uppercase text-sm">qty</span>
                                    <span className="px-1">:</span>
                                    <span className="">{item.quantity}</span>
                                </div>
                            )}
                            {cartItemAmount && (
                                <strong className="block font-bold">
                                    {formatPrice(cartItemAmount, currencyCode)}
                                </strong>
                            )}
                        </div>

                        <div
                            className="
                            flex flex-wrap justify-end -mx-3 my-0 items-center
                            md:flex-nowrap md:justify-between
                        "
                        >
                            {options.allowQuantityChange && (
                                <div className="px-3">
                                    <OrderItemQuantityModifier
                                        orderItemId={item.id}
                                        quantity={item.quantity}
                                        max={maximumQuantityToAdd}
                                    />
                                </div>
                            )}
                            {options?.showRemove && (
                                <div className="p-3">
                                    <Button
                                        className="text-red-500"
                                        type="transparent"
                                        onClick={e => {
                                            e.preventDefault();
                                            handleOnRemove(item.id);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
