import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DateTime } from 'luxon';
import _ from "lodash";
import { useSalesChannel } from "../../../../context/SalesChannelContext";
import { Stack } from "@mdi/react";
import { formatPrice } from "../../../../utils/currency";

export default function MyCredit() {
    const customer = useSelector((state: RootState) => state.auth.customer);
    const creditHistory = customer?.credits;

    return <div className="space-y-3">
        <div className="bg-white p-4 space-y-3">
            <h3>How to Use Your Referral Code</h3>
            <p>
                Share your referral code with your friends and family. Each time your referral code is successfully used on a new customers first order, you will earn a a £10 credit to be used on your account, and they will save 5% on their first order.
                <br /><br />
                To change the referral code to something more convenient, click the pen next to your referral code.
                <br /><br />
                As per our T&C’s referral credit can only be used on future orders, and can not be redeemed for cash.
            </p>
        </div>
        <div className="bg-white p-4">
            {(!creditHistory || creditHistory.length == 0) && (
                <p className="text-center p-5 mb-2">You have not received or used credit.</p>
            )}
            <div className={`grid divide-y divide-gray-300`}>
                {creditHistory?.map((creditHistory) => {
                    return (
                        <div className="flex items-center p-3" key={creditHistory?.id as string}>
                            <div className="flex-1 flex flex-col space-y-1">
                                <CreditHistoryDate value={creditHistory.date} />
                                <CreditHistoryDescription value={creditHistory.description} />
                            </div>
                            <CreditHistoryAmount value={creditHistory.amount} />
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
}

const CreditHistoryDate = ({ value }: { value: string }) => {
    if (!value || value === "{}") {
        return <span className={"my-auto text-center p-2"}> Invalid Date </span>
    }
    const dateTime = DateTime.fromJSDate(new Date(value));
    return <span className="text-sm">
        {`${dateTime.toFormat('dd MMM yyyy hh:mm a')}`}
    </span>
}

const CreditHistoryAmount = ({ value }: { value: number }) => {
    if (!value) {
        return <span className="my-auto p-2 rounded-md bg-gray-500"> {"No Value found."}</span>
    }
    return <span className={`my-auto p-2 rounded-md font-bold text-3xl text-center text-${value > 0 ? "primary" : "secondary"}`}>
        {value < 0 ? "-" : "+"} {formatPrice(value).replace("-", "")}
    </span>
}

const CreditHistoryDescription = ({ value }: { value: string }) => {
    return <span className="font-semibold"> {value || "No Description entered."}</span>
}
