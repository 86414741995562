import React, { useCallback, useEffect, useMemo } from "react";
import { DateTime, Duration } from "luxon";

import { formatDate } from "../../../utils/dateTime";
import { useEcommerceHelper } from "../../../context/ecommerce";
import ecommerceApi from "../../../services/ecommerceApi";

interface Props {
    productId: string;
}

export default function OrderInTheNextProduct({ productId }: Props) {

    const { postcode, setEditPostcode } = useEcommerceHelper();

    const [estDispatchDate, setEstDispatchDate] = React.useState<DateTime | null>(null);
    const [estDeliverDate, setEstDeliverDate] = React.useState<DateTime | null>(null);
    const [shippingAmount, setShippingAmount] = React.useState<number | null>(null);

    const [dateError, setDateError] = React.useState(false);
    const [countdown, setCountdown] = React.useState<Duration | "loading" | "expired">("loading");

    const checkCountdown = useCallback(() => {

        if (!estDispatchDate) return;

        const now = DateTime.now();
        const remainingTime = estDispatchDate.diff(now, ["milliseconds"]);
        const milliseconds = remainingTime.as("milliseconds");

        if (milliseconds <= 0) {
            setCountdown("expired");
            return;
        }

        setCountdown(remainingTime);

    }, [estDispatchDate]);

    const loadDates = useCallback(async () => {
        if (!productId || !postcode) {
            return;
        }

        setCountdown("loading");

        const response = await ecommerceApi.getProductDates(productId, postcode);

        if (!response?.estDeliveryDate || !response?.estDispatchDate) {
            setDateError(true);
            setEstDeliverDate(null);
            setEstDispatchDate(null);
            return;
        }

        setDateError(false);

        const estDispatchDateTime = DateTime.fromISO(response.estDispatchDate);
        const estDeliverDateTime = DateTime.fromISO(response.estDeliveryDate);
        setEstDeliverDate(estDeliverDateTime);
        setEstDispatchDate(estDispatchDateTime);
        setShippingAmount(response.totalShippingAmount || 0);
    }, [productId, postcode]);

    const onEditPostcodeClick = useCallback((e) => {
        e.preventDefault();
        setEditPostcode(true);
    }, [setEditPostcode]);

    const deliveryOn = useMemo(() => {
        if (estDeliverDate) {
            return formatDate(estDeliverDate, "EEEE, dd MMMM");
        }
        return "";
    }, [estDeliverDate]);

    const dipsatchedOn = useMemo(() => {
        if (estDispatchDate) {
            // if tomorrow

            const diff = estDispatchDate.diff(DateTime.now(), "days").days;

            if (estDispatchDate.hasSame(DateTime.now(), "day")) {
                return "today";
            }
            if (estDispatchDate.hasSame(DateTime.now().plus({ days: 1 }), "day")) {
                return "tomorrow";
            }
            if (diff && diff < 4) {
                return "in " + Math.ceil(diff) + " days";
            }
            return "on " + formatDate(estDispatchDate);
        }
        return "";
    }, [estDispatchDate]);

    const countdownFormatted = useMemo(() => {

        if (countdown === "loading") {
            return <div className="inline-block bg-gray-200 animate-cyclic-pulse w-[100px] h-[12px] ml-[2px]" />
        }

        if (countdown === "expired") {
            return "EXPIRED";
        }

        const mins = countdown.as("minutes");

        if (mins < 1) {
            return "EXPIRED";
        }

        // if countdown is less than 1 hour
        if (countdown.as("hours") < 1) {
            return countdown.toFormat("mm 'mins'")
        }

        // if countdown is less than 1 day
        if (countdown.as("days") < 1) {
            return countdown.toFormat("hh 'hrs' mm 'mins'")
        }

        return countdown.toFormat("dd 'days' hh 'hrs' mm 'mins'")
    }, [countdown]);

    React.useEffect(() => {
        const interval = setInterval(checkCountdown, 1000)
        return () => {
            clearInterval(interval);
        };
    }, [checkCountdown]);

    React.useEffect(() => {
        loadDates();
    }, [loadDates]);

    useEffect(() => {
        if (countdown === "expired") {
            loadDates();
        }
    }, [countdown]);

    if (!postcode || !productId) {
        return <></>
    }

    return (
        <div className="p-3 bg-white text-center space-y-1">

            {!dateError && (
                <div>
                    <span className="font-bold">
                        {`Get it delivered ${shippingAmount === 0 ? "Free " : ""}on ${deliveryOn}.`}
                        &nbsp;
                    </span>
                    {`Order within`}
                    &nbsp;
                    <span className="text-primary">
                        {countdownFormatted}
                    </span>
                </div>
            )}

            {dateError && (
                <div className="text-red-500">
                    Please ensure the postcode entered is valid.
                </div>
            )}

            <div className="text-sm">
                {`Delivery to ${postcode}`}
                <a href="#" className="ml-2 text-primary underline"
                    onClick={onEditPostcodeClick}
                >Edit</a>
            </div>
        </div>
    );
}
