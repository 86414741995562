import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin, BsPinterest, BsTwitter, BsYoutube } from "react-icons/bs";

interface Props {
    type: string;
    size?: number;
}

export default function SocialsIcon({ type, size = 20 }: Props) {
    switch (type) {
        case "facebook":
            return <BsFacebook size={size} />;
        case "instagram":
            return <BsInstagram size={size} />;
        case "twitter":
            return <BsTwitter size={size} />;
        case "youtube":
            return <BsYoutube size={size} />;
        case "linkedin":
            return <BsLinkedin size={size} />;
        case "pinterest":
            return <BsPinterest size={size} />;
    }

    return <></>;
}
