import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../shared/Button";
import CheckoutSection from "../CheckoutSection";
import ShippingAddress from "./ShippingAddress";
import { AppDispatch, RootState } from "../../../../store";
import { setStep } from "../../../../store/order";
import { addressSchema, validateAddress } from "../../../../utils/validations";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FaExclamationCircle } from "react-icons/fa";

interface Props {
    temporaryDisable: string | undefined;
}

export default function CheckoutDelivery({ temporaryDisable }: Props) {
    const dispatch = useDispatch<AppDispatch>();


    const user = useSelector((state: RootState) => state.auth.user);
    const currentStep = useSelector((state: RootState) => state.order.stepOpen);
    const customer = useSelector((state: RootState) => state.order.order?.customer);

    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const shippingAddress = useSelector((state: RootState) => state.order.order?.shippingAddress?.[0]);

    const [message, setMessage] = React.useState<string | null>(null);
    const [continuing, setContinuing] = React.useState(false);
    const [options, setOptions] = React.useState({ open: false });
    const [editing, setEditing] = React.useState(shippingAddress == null);

    const setOpen = (value: boolean) => {
        setOptions({ ...options, open: value });
    };

    useEffect(() => {
        setOpen(currentStep === "shipping");
    }, [currentStep]);

    const handleContinue = async () => {
        setContinuing(true);

        // check shipping address

        if (!shippingAddress) {
            setContinuing(false);
            setEditing(true);
            setMessage("Please enter a shipping address.");
            return;
        }

        const validationErrors = await validateAddress(shippingAddress);

        if (validationErrors != true) {
            // get first error message
            const firstKey = Object.keys(validationErrors)[0];
            setMessage(validationErrors[firstKey]);
            setEditing(true);
            setContinuing(false);
            return;
        }

        setMessage(null);

        setTimeout(async () => {
            await dispatch(setStep("shipments"));
            setContinuing(false);
        }, 250);
    };

    const handleSectionClick = () => {
        dispatch(setStep("shipping"));
    };

    React.useEffect(() => {
        setMessage(null)
    }, [shippingAddress]);

    return (
        <section>
            <CheckoutSection
                title={"Shipping Address"}
                sectionNumber={2}
                options={options}
                disabled={!customer ? true : false}
                onClick={handleSectionClick}
            >

                {!customer && (
                    <div className="flex items-center justify-center p-5 bg-red-100">
                        <div className="w-[100px] flex items-end justify-center">
                            <FaExclamationCircle size={25} />
                        </div>
                        <div className="text-lg font-bold">
                            Please enter your customer details to continue your order.
                        </div>
                    </div>
                )}

                {!!customer && (
                    <>
                        <div className="mb-8">
                            <ShippingAddress temporaryDisable={temporaryDisable} editing={editing} setEditing={setEditing} />
                        </div>

                        <div className="flex items-center justify-end mt-5">
                            {message && (
                                <div className="py-3 px-4 flex items-center text-red-500 font-bold mr-3">
                                    <BsExclamationCircleFill />
                                    <span className="ml-3">
                                        {message}
                                    </span>
                                </div>
                            )}
                            <Button
                                loader={continuing}
                                className="w-full max-w-[200px]"
                                padding="py-3 px-5"
                                disabled={!shippingAddress || continuing || !!temporaryDisable}
                                onClick={handleContinue}
                            >
                                Continue
                            </Button>
                        </div>
                    </>
                )}
            </CheckoutSection>
        </section>
    );
}
