import React, { createContext, useContext, useEffect } from 'react'

import { loadAirwallex } from 'airwallex-payment-elements';
import Airwallex from 'airwallex-payment-elements/types/airwallex';
import { IPaymentGateway } from '..';
import { useSalesChannel } from '../../../../../../context/SalesChannelContext';

const AuthContext = createContext({
    airwallex: null,
    loading: false
} as { airwallex: Airwallex | null, loading: boolean });

type Props = {
    paymentGateway: IPaymentGateway;
    children?: React.ReactNode;
}

export default function AirwallexProvider({ paymentGateway, children }: Props) {

    const salesChannel = useSalesChannel();

    const [airwallex, setAirwallex] = React.useState<Airwallex | null>(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {

        const load = async () => {

            if (!salesChannel?.id) {
                return;
            }

            const property = paymentGateway.config.find(p => p.property === "env" && p.salesChannelId === salesChannel.id);
            const env = property?.value || 'demo';

            const airwallex = await loadAirwallex({
                env,
                origin: window.location.origin
            });

            setAirwallex(airwallex);
            setLoading(false);
        }

        load();

    }, [salesChannel?.id])

    return (
        <AuthContext.Provider value={{
            airwallex,
            loading
        }}>
            {children}
        </AuthContext.Provider>
    )
}


export const useAirwallex = () => useContext(AuthContext)