import React from 'react'

import { BsArrowClockwise } from 'react-icons/bs';

export default function DevTools() {


    const handleRefresh = () => {
        fetch('/__refresh', {
            method: 'POST',
        });
    }

    return (
        <>
            <button className='
                fixed
                bottom-5
                left-5
                p-3
                rounded
                bg-black
                text-white
            ' onClick={handleRefresh}>
                <BsArrowClockwise size={30}  />
            </button>
        </>
    )
}
