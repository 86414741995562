import React from 'react'
import { ProductType } from '../../../../types'
import { usePageContext } from '../../../pages/context'
import RecommendedProduct from '../../../ecommerce/RecommendedProduct'
import { ProductDetailHeading } from '../ProductDetail'

export default function RelatedProducts() {

    const { productDetailProduct: selectedProduct } = usePageContext()

    const count = selectedProduct?.relatedProducts?.length || 0;

    if (count === 0 || !selectedProduct) return <></>;

    return (
        <section className="py-10 bg-gray-100">
            <div className="container max-w-screen-xl mx-auto px-3">
                <ProductDetailHeading>Related Products</ProductDetailHeading>
                <div className="flex flex-wrap -mx-3">
                    {selectedProduct.relatedProducts.map(prd => (
                        <RecommendedProduct key={prd.id} product={prd} />
                    ))}
                </div>
            </div>
        </section>
    )
}
