import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Address } from "react-loqate";
import AddressSearch from "./AddressSearch";
import { AppDispatch, RootState } from "../../../store";
import { fetchAllCountries } from "../../../store/country";
import TextInput from "../../shared/FormComponents/TextInput";
import Collapse from "../../shared/Collapse";
import SelectInput from "../../shared/FormComponents/SelectInput";

type Props = {
    type: 1 | 2;
    CONFIG: any;
    addressData: any;
    setAddressData: (data: any) => void;
    addressErrors;
    colClass?: {
        search: string;
        form: string;
    };
    excludeSearch?: boolean;
};

const colClassDefault = {
    search: "w-full mb-4",
    form: "w-full"
};

export default function OrderAddress({
    type,
    CONFIG,
    addressData,
    setAddressData,
    addressErrors,
    colClass,
    excludeSearch = false
}: Props) {
    const dispatch = useDispatch<AppDispatch>();

    colClass = {
        ...colClassDefault,
        ...colClass
    };

    const countries = useSelector((state: RootState) => state.country.countries);
    const orderStore = useSelector((state: RootState) => state.order);

    const [fullEditOpen, setFullEditOpen] = React.useState(false);

    useEffect(() => {
        dispatch(fetchAllCountries());
    }, []);

    useEffect(() => {
        const newAddressData = {
            ...addressData
        };

        newAddressData.firstName =
            (newAddressData.firstName != null && newAddressData.firstName != ""
                ? newAddressData.firstName
                : orderStore.order?.customer?.firstName) || "";

        newAddressData.lastName =
            (newAddressData.lastName != null && newAddressData.lastName != ""
                ? newAddressData.lastName
                : orderStore.order?.customer?.lastName) || "";

        setAddressData(newAddressData);
    }, [orderStore.order?.customer]);

    const handleAddressDataChange = (value: any, name: string) => {
        setAddressData({
            ...addressData,
            [name]: value
        });
    };

    const handleSearchSelect = (address: Address) => {
        setAddressData({
            ...addressData,
            line1: address.Line1,
            line2: address.Line2,
            city: address.City,
            countryCode: address.CountryIso2,
            postCode: address.PostalCode
        });
    };

    const idPrefix = useMemo(() => {
        return type === 1 ? "PRIMARY" : "SECONDARY";
    }, [type]);

    return (
        <>
            <div>
                <div className="flex flex-wrap -mx-3">
                    {excludeSearch ? null : (
                        <div className={`px-3 ${colClass.search}`}>
                            <AddressSearch
                                handleAddressSelect={handleSearchSelect}
                                inputProps={{
                                    label: "Search",
                                    variant: "standard"
                                }}
                            />
                        </div>
                    )}
                    <div className={`px-3 ${colClass.form}`}>
                        <div className="flex flex-wrap -mx-3">
                            {CONFIG.map(item => (
                                <div
                                    key={`${idPrefix}_${item.name}`}
                                    className={`${item.col ? item.col : "w-full"} mb-3 px-3`}
                                >

                                    {item.type === "text" ? (
                                        <TextInput
                                            value={!addressData ? "" : addressData[item.name] ?? ""}
                                            label={item.label}
                                            onChange={e => {
                                                handleAddressDataChange(e.target.value, item.name);
                                            }}
                                            error={addressErrors[item.name]}
                                            autocomplete={item.autocomplete}
                                        />
                                    ) : null}

                                    {item.type === "countrySelector" ? (
                                        <>
                                            <SelectInput
                                                label={item.label}
                                                autocomplete={item.autocomplete}
                                                value={
                                                    !addressData ? "" : addressData[item.name] ?? ""
                                                }
                                                onChange={e => {
                                                    handleAddressDataChange(
                                                        e.target.value,
                                                        item.name
                                                    );
                                                }}
                                            >
                                                <option value="">Select country</option>
                                                {countries.map((country: any) => (
                                                    <option
                                                        key={`COUNTRY_${country.id}`}
                                                        value={country.alpha2}
                                                    >
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </SelectInput>
                                        </>
                                    ) : null}

                                    {addressErrors?.[item.name] && (
                                        <p className="text-red-500 text-[12px] pt-1">
                                            {addressErrors[item.name]}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
