import React, { useEffect, useMemo, useState } from "react";
import {
    CountryType,
    CourierType,
    IAvailableShippingMethod,
    IOrderShipment,
    IShipmentService,
    IShippingMethod
} from "../../../../../../types";
import RadioGroupInput from "../../../../../shared/FormComponents/RadioGroupInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { formatPrice, getPriceToShow } from "../../../../../../utils/currency";
import { getDateFormatted, getDateLocaleStr } from "../../../../../../utils/dateTime";
import SelectInput from "../../../../../shared/FormComponents/SelectInput";
import { BsCheckCircle, BsCheckCircleFill, BsCircle, BsCircleFill, BsPalette, BsRadioactive, BsUiRadios } from "react-icons/bs";
import { useSalesChannel } from "../../../../../../context/SalesChannelContext";
import Spinner from "../../../../../shared/Spinner";
import Checkbox from "../../../../../shared/Checkbox";
import ecommerceApi from "../../../../../../services/ecommerceApi";
import { jsonParseString } from "../../../../../../utils/string";
import Tooltip from "../../../../../shared/Tooltip";
import { arrayToMap } from "../../../../../../utils/array";
import _, { set } from "lodash";
import Button from "../../../../../../components/shared/Button";
import { ISalesChannel } from "../../../../../../services/ecommerceApi/SalesChannel/types";
import Slider from "../../../../../shared/Slider";
import UTSlider from "../../../../../shared/UTSlider";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { DataStore } from "aws-amplify";
import { MdRadio, MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { DateTime } from "luxon";
interface Props {
    shipment: IOrderShipment;
    loading: boolean;
    handleShippingMethodSelect: (
        shipmentId: string
    ) => (shippingMethodId: string, services: IShipmentService[], selectedEstDeliveryDate?: string) => Promise<void>;
    setTemporaryDisable: (temporaryDisable: string | undefined) => void;
    temporaryDisable: string | undefined;
}

export default function ShipmentEdit({ shipment, loading, handleShippingMethodSelect, setTemporaryDisable, temporaryDisable }: Props) {
    const salesChannel = useSalesChannel();
    const [hasBookInService, setBookInService] = useState(false);
    const [hasRestrictedAccessService, setRestrictedAccessService] = useState(false);
    const [bookInForced, setBookInForced] = useState(false);
    const [restrictedAccessForced, setRestrictedAccessForced] = useState(false);

    const [shCourier, setCourier] = useState<null | CourierType>(null);
    const [cLoading, setCLoading] = useState(true);
    const [services, setServices] = useState<IShipmentService[]>([]);
    const [delay, setDelay] = useState<NodeJS.Timeout | undefined>();
    const [awaitingCall, setAwaitingCall] = useState<boolean>(false);

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    useEffect(() => {
        loadCourier();
    }, []);

    const loadCourier = async () => {
        let courierId = shipment?.shippingMethod?.courier?.id;
        const courier = await ecommerceApi.getCourier(courierId as string);
        if (courier) {
            setCourier(courier);
            setCLoading(false);
        }
    };

    const handleServiceClick = (method: IAvailableShippingMethod, selectedEstDeliveryDate?: string) => async (e: any) => {
        if (delay) { clearTimeout(delay) }
        setTemporaryDisable(shipment.id);
        const promise = new Promise((resolve) => {
            setDelay(setTimeout(async () => {
                setAwaitingCall(true)
                await handleShippingMethodSelect(shipment.id)(method.id, [...services, ...getServices()], selectedEstDeliveryDate);
                setAwaitingCall(false)
                setTemporaryDisable(undefined);
                resolve(true)
            }, 1000))
        });
        await promise;
    };

    const getServices = (hasRestrictedAccessServiceProp = hasRestrictedAccessService) => {
        let services: IShipmentService[] = [];

        if (hasRestrictedAccessServiceProp) {
            services.push({
                type: "RESTRICTED_ACCESS",
                surchargeCode: "restricted-access",
                id: "restricted-access",
                note: "system-configured"
            });
        }

        return services;
    };

    const dispatchDate = useMemo(() => {
        if (shipment.estDispatchDate) {
            return getDateLocaleStr(shipment.estDispatchDate, DateTime.DATE_HUGE);
        }
        return "";
    }, [shipment.estDispatchDate]);

    const deliveryDate = useMemo(() => {
        if (shipment.estDeliveryDate) {
            return getDateLocaleStr(shipment.estDeliveryDate, DateTime.DATE_HUGE);
        }
        return "";
    }, [shipment.estDeliveryDate]);

    useEffect(() => {
        if (shipment.services) {
            setRestrictedAccessService(shipment.services.some(s => s.type == "RESTRICTED_ACCESS"));
            setRestrictedAccessForced(
                shipment.services.some(s => s.type == "RESTRICTED_ACCESS" && s.force)
            );

            setBookInService(shipment.services.some(s => s.type == "BOOK_IN_SERVICE"));
            setBookInForced(shipment.services.some(s => s.type == "BOOK_IN_SERVICE" && s.force));
        }
    }, [shipment.services]);

    let servicesMap = arrayToMap(services, "id");

    let serviceConfigs = (shCourier?.serviceConfig ?? []).filter(el => el.websiteEnabled);

    let isShipmentBig = (shipment.totalWeight || 0) > 750;

    return (
        <div key={shipment.id} className="bg-gray-100 p-5 mb-4">
            <div className="flex flex-wrap -mx-2">

                <div className="flex w-full justify-center mb-2 gap-2">
                    {shipment.orderItems?.map(item => {
                        let src = jsonParseString(item.imageUrl)?.url;
                        return (
                            <div key={item.id} className="p-2 bg-white border-2 rounded-md border-secondary w-[80px] h-[80px]">
                                <img
                                    src={src}
                                    className="m-auto h-[100%]"
                                    title={item.name}
                                />
                            </div>
                        );
                    })}
                </div>

                {shipment.isShipmentValid && (
                    <div className="w-full px-2">
                        <div className={`flex flex-col flex-wrap gap-6`}>
                            <CombinedAvailableShippingMethods awaitingCall={awaitingCall || (!!temporaryDisable && temporaryDisable !== shipment.id)} shipment={shipment} methods={shipment.availableShippingMethods} loading={loading} handleServiceClick={handleServiceClick} salesChannel={salesChannel} currencyCode={currencyCode} />
                        </div>
                    </div>
                )}
                <div className="px-2 grow mt-3">
                    {!shipment.isShipmentValid && (
                        <div className="text-red-500 mb-3">
                            Unfortunately we can't ship this shipment. Please choose another
                            shipping method or change your shipping address.
                        </div>
                    )}

                    {shipment.isShipmentValid && (
                        <>
                            {shipment.shippingAmount != null && (
                                <div className="text-center text-lg space-y-3 md:space-y-1">
                                    <div>
                                        {"Est. Dispatch Date is "}
                                        <br className="md:hidden" />
                                        <strong>
                                            {dispatchDate}
                                        </strong>
                                    </div>
                                    <div>
                                        {"Est. Delivery Date is "}
                                        <br className="md:hidden" />
                                        <strong>{deliveryDate}</strong>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {shipment.isShipmentValid && (
                <div className="my-4">
                    {cLoading ? (
                        <Spinner stroke="black" />
                    ) : (
                        <div className="flex flex-col items-center">
                            {/* HARD CODED CHECK FOR SHIPMENT WEIGHT NEEDS TO BE REMOVED IN FUTURE */}
                            {shCourier?.hasRestrictedAccessService && (
                                <Tooltip
                                    text={
                                        isShipmentBig
                                            ? "Please contact customer service if you need assistance."
                                            : "Restricted Access - Smaller Lorry Required ? please keep this box selected"
                                    }
                                >
                                    <div className={isShipmentBig ? "opacity-50" : ""}>
                                        <Checkbox
                                            textSize="text-base md:text-lg"
                                            label={`Restricted Access - ${isShipmentBig ? "Not Available" : "Smaller Lorry Required?"}`}
                                            checked={hasRestrictedAccessService}
                                            disabled={restrictedAccessForced || isShipmentBig || (!!temporaryDisable && temporaryDisable === shipment.id)}
                                            onChange={async (val) => {
                                                setRestrictedAccessService(val);
                                                setAwaitingCall(true);
                                                await handleShippingMethodSelect(shipment.id)(shipment.shippingMethod?.id as string, getServices(val));
                                                setAwaitingCall(false);
                                            }}
                                        />
                                        {isShipmentBig &&
                                            <div className="ml-10 mt-2">
                                                "Your shipment is too big for a restricted access delivery."
                                            </div>
                                        }
                                    </div>
                                </Tooltip>
                            )}
                            {shCourier?.hasBookInService && (
                                <Tooltip text="If you wish to have an arranged delivery date with the depot, please keep this box selected.">
                                    <Checkbox
                                        textSize="text-base md:text-lg"
                                        label={"Book in Service ?"}
                                        checked={hasBookInService}
                                        disabled={bookInForced}
                                        onChange={setBookInService}
                                    />
                                </Tooltip>
                            )}
                            {serviceConfigs.map(el => {
                                return (
                                    <React.Fragment key={el.id}>
                                        <Tooltip text={el.note}>
                                            <Checkbox
                                                textSize="text-base md:text-lg"
                                                label={el.surchargeCode}
                                                checked={servicesMap[el.id] ? true : false}
                                                onChange={checked => {
                                                    let newServices = [...services];
                                                    if (checked) {
                                                        newServices.push({
                                                            type: el.type,
                                                            surchargeCode: el.surchargeCode,
                                                            id: el.id,
                                                            note: el.note
                                                        });
                                                        setServices(newServices);
                                                    } else {
                                                        let idx = services.findIndex(
                                                            el => el.id === el.id
                                                        );
                                                        if (idx >= 0) {
                                                            newServices.splice(idx, 1);
                                                            setServices(newServices);
                                                        }
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

type ICombinedAvailableShippingMethods = {
    shipment: IOrderShipment;
    methods: IAvailableShippingMethod[];
    loading: boolean;
    handleServiceClick: (method: IAvailableShippingMethod, selectedEstDeliveryDate?: string) => (e: any) => Promise<void>;
    salesChannel: ISalesChannel;
    currencyCode: string;
    awaitingCall: boolean;
}

const getDays = (numberOfDays: number) => {
    const days: Date[] = [];
    const getDay = (daysFromToday: number) => {
        const date = new Date();
        const luxonDate = DateTime.fromJSDate(date).setZone("Europe/London").startOf("day");
        date.setDate(luxonDate.toJSDate().getDate() + daysFromToday);
        return date;
    }
    for (let i = 0; i < numberOfDays; i++) {
        days.push(getDay(i));
    }
    return days;
}

const CombinedAvailableShippingMethods = ({ shipment, methods, loading, handleServiceClick, salesChannel, currencyCode, awaitingCall }: ICombinedAvailableShippingMethods) => {

    const [selectedDay, setSelectedDay] = useState<Date | null>(null);
    const windowSize = useWindowSize();
    const [methodIsLyingAboutBeingSelected, setMethodIsLyingAboutBeingSelected] = useState<string | undefined>(undefined);
    const [currentSliderIndex, setCurrentSliderIndex] = useState(0);

    const days = useMemo(() => {
        let latestDeliveryDate;
        methods.forEach((method) => {
            if (!method?.estDeliveryDates?.length) {
                return;
            }
            const deliveryDate = new Date(method.estDeliveryDates[method.estDeliveryDates.length - 1]);
            latestDeliveryDate = latestDeliveryDate > deliveryDate ? latestDeliveryDate : deliveryDate;
        });
        const daysBetweenTodayAndLastestDeliveryDate = latestDeliveryDate ? Math.ceil((new Date(latestDeliveryDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;
        return getDays(daysBetweenTodayAndLastestDeliveryDate + 1);
    }, [methods]);

    useEffect(() => {
        if (shipment.estDeliveryDate) {
            setSelectedDay(new Date(shipment.estDeliveryDate));
        }
    }, [shipment])

    const getMethodsForDay = (day: Date) => {
        const methodsForDay: IAvailableShippingMethod[] = [];
        methods.forEach(method => {
            method.estDeliveryDates?.forEach(estDeliveryDate => {
                if (estDeliveryDate.substring(0, 10) === day.toISOString().substring(0, 10)) {
                    methodsForDay.push(method);
                }
            });
        });
        return methodsForDay;
    }

    const methodsForSelectedDay = useMemo(() => {
        if (selectedDay) {
            return getMethodsForDay(selectedDay);
        }
        return [];
    }, [selectedDay, methods]);

    const filteredDays = useMemo(() => {
        let filter = true;
        return days.filter((day) => {
            const methodsForDay: IAvailableShippingMethod[] = getMethodsForDay(day);
            if (!methodsForDay.length && filter) {
                return false;
            }
            if (methodsForDay.length) {
                filter = false;
            }
            return true;
        })
    }, [days])

    const itemsPerPage = useMemo(() => {
        const width = (windowSize?.width || Infinity);
        if (width > 650) return 5;
        if (width > 560) return 4;
        if (width > 440) return 3;
        return 2;
    }, [windowSize?.width])


    useEffect(() => {
        let currentSliderIndex: number | undefined;
        filteredDays.forEach((day, index) => {
            const active = shipment.estDeliveryDate?.substring(0, 10) === day.toISOString().substring(0, 10);
            const selected = selectedDay?.toISOString()?.substring(0, 10) === day?.toISOString()?.substring(0, 10);
            if (selected) {
                currentSliderIndex = Math.floor(index / itemsPerPage);
            }
            if (active && !currentSliderIndex && currentSliderIndex === 0) {
                currentSliderIndex = Math.floor(index / itemsPerPage);
            }
        })
        setCurrentSliderIndex(currentSliderIndex || 0);
    }, [filteredDays, shipment.estDeliveryDate, selectedDay, itemsPerPage])

    const onPageChange = (index: number) => {
        setCurrentSliderIndex(index);
    }

    const sharedWidth = `calc(${itemsPerPage * 90}px + ${(itemsPerPage - 1) * 0.5}rem)`;

    return <div className="flex flex-col gap-2 max-w-full">
        {filteredDays.length > 1 && (
            <UTSlider slideAnimation={!awaitingCall} itemsPerPage={itemsPerPage} items={filteredDays} itemKey={"root"} index={currentSliderIndex} onPageChange={onPageChange} renderItem={
                (day) => {
                    const methodsForDay: IAvailableShippingMethod[] = getMethodsForDay(day);

                    if (!methodsForDay.length) {
                        return <Button className={"border-2 border-secondary min-w-[90px]"} padding="px-1 py-1" type="transparent" disabled >
                            <div>
                                <p className="text-lg font-bold">{getDateFormatted(day.toISOString(), "EEE")}</p>
                                <p className="text-sm">{getDateFormatted(day.toISOString(), "dd MMM")}</p>
                                <p className="text-sm font-bold">N/A</p>
                            </div>
                        </Button>
                    }

                    const sortedByCheapest = methodsForDay.sort((a, b) => {
                        const aAmount = ((a.shippingAmount || 0 + (a.shippingTaxAmount || 0)) * (a.packageCount || 1)) + (a.shippingMethodSurcharge || 0) + (a.shippingMethodSurchargeTaxAmount || 0);
                        const bAmount = ((b.shippingAmount || 0 + (b.shippingTaxAmount || 0)) * (b.packageCount || 1)) + (b.shippingMethodSurcharge || 0) + (b.shippingMethodSurchargeTaxAmount || 0);
                        return (aAmount < bAmount) ? -1 : 1;
                    });
                    const cheapestMethod = sortedByCheapest[0];

                    const selected = selectedDay?.toISOString()?.substring(0, 10) === day?.toISOString()?.substring(0, 10);
                    const color = selected ? "primary" : "transparent";

                    const shippingAmount = cheapestMethod.shippingAmount ?? -1;
                    const shippingAmountValid = shippingAmount > -1;

                    const cheapestShippingTotal = useMemo(() => {
                        const shippingTaxAmount = cheapestMethod.shippingTaxAmount || 0;
                        const packageCount = cheapestMethod.packageCount || 1;
                        const shippingMethodSurcharge = cheapestMethod.shippingMethodSurcharge || 0;
                        const shippingMethodSurchargeTaxAmount = cheapestMethod.shippingMethodSurchargeTaxAmount || 0;

                        const surcharge = shippingMethodSurcharge + shippingMethodSurchargeTaxAmount;

                        const cheapestMethodShippingAmount = shippingAmountValid ? (
                            ((shippingAmount + shippingTaxAmount) * packageCount) + surcharge
                        ) : 0;

                        return cheapestMethodShippingAmount;

                    }, [cheapestMethod, shippingAmount, shippingAmountValid])

                    return <>
                        <Button style={{ borderColor: salesChannel.secondaryColour }} className={"border-2 min-w-[90px]"} padding="px-1 py-1" type={color} disabled={awaitingCall} onClick={async () => {
                            setSelectedDay(day);
                            setMethodIsLyingAboutBeingSelected(cheapestMethod.id);
                            await handleServiceClick(cheapestMethod, day.toISOString())(null);
                            setMethodIsLyingAboutBeingSelected(undefined);
                        }}>
                            <div>
                                <p className="text-lg font-bold">{getDateFormatted(day.toISOString(), "EEE")}</p>
                                <p className="text-sm">{getDateFormatted(day.toISOString(), "dd MMM")}</p>
                                <p className="text-sm">{(shippingAmountValid) ? (methodsForDay.length === 1 && cheapestShippingTotal === 0) ? "Free" : `From ${formatPrice(cheapestShippingTotal, currencyCode)}` : undefined}</p>
                            </div>
                        </Button>
                        <div style={{ opacity: selected ? 1 : 0 }} className="w-full flex justify-center">
                            <div style={{ borderTopColor: salesChannel.secondaryColour, opacity: awaitingCall ? 0.5 : 1 }} className="h-0 w-0 border-x-[14px] border-x-transparent border-t-[10px]"></div>
                        </div>
                    </>
                }} />
        )}
        {
            selectedDay && methodsForSelectedDay && <div>
                <div className="flex flex-col gap-2 mx-auto" style={{ width: sharedWidth }}>
                    {methodsForSelectedDay.map(method => {
                        return <AvailableShippingMethod
                            awaitingCall={awaitingCall}
                            methodIsLyingAboutBeingSelected={methodIsLyingAboutBeingSelected === method.id}
                            setMethodIsLyingAboutBeingSelected={(lying: boolean) => {
                                setMethodIsLyingAboutBeingSelected(lying ? method.id : undefined);
                            }}
                            anotherMethodIsLyingAboutBeingSelected={!!methodIsLyingAboutBeingSelected}
                            key={`${selectedDay.toISOString().substring(0, 10)}-${method.id}`}
                            day={selectedDay}
                            shipment={shipment}
                            method={method}
                            loading={loading}
                            handleServiceClick={handleServiceClick}
                            salesChannel={salesChannel}
                            currencyCode={currencyCode} />
                    })}
                </div>
            </div>
        }
    </div >
}

type IAvailableShippingMethodProps = {
    awaitingCall: boolean;
    day: Date;
    shipment: IOrderShipment;
    method: IAvailableShippingMethod;
    loading: boolean;
    handleServiceClick: (method: IAvailableShippingMethod, selectedEstDeliveryDate?: string) => (e: any) => Promise<void>;
    salesChannel: ISalesChannel;
    currencyCode: string;
    methodIsLyingAboutBeingSelected: boolean;
    setMethodIsLyingAboutBeingSelected: (lying: boolean) => void;
    anotherMethodIsLyingAboutBeingSelected: boolean;
}

const AvailableShippingMethod = ({ awaitingCall, methodIsLyingAboutBeingSelected, setMethodIsLyingAboutBeingSelected, anotherMethodIsLyingAboutBeingSelected, day, shipment, method, loading, handleServiceClick, salesChannel, currencyCode }: IAvailableShippingMethodProps) => {
    const deliveryDate = day;
    const selected = method.id == shipment.shippingMethod?.id && shipment.estDeliveryDate?.substring(0, 10) === deliveryDate.toISOString().substring(0, 10);

    const handleClick = async (e) => {
        setMethodIsLyingAboutBeingSelected(true);
        await handleServiceClick(method, deliveryDate.toISOString())(e);
        setMethodIsLyingAboutBeingSelected(false);
    }

    const finalSelected = (selected && !anotherMethodIsLyingAboutBeingSelected) || methodIsLyingAboutBeingSelected;

    const shippingAmount = method.shippingAmount ?? -1;
    const shippingAmountValid = shippingAmount > -1;

    const methodShippingAmount = useMemo(() => {
        const shippingTaxAmount = method.shippingTaxAmount || 0;
        const packageCount = method.packageCount || 1;
        const shippingMethodSurcharge = method.shippingMethodSurcharge || 0;
        const shippingMethodSurchargeTaxAmount = method.shippingMethodSurchargeTaxAmount || 0;

        const surcharge = shippingMethodSurcharge + shippingMethodSurchargeTaxAmount;

        const cheapestMethodShippingAmount = shippingAmountValid ? (
            ((shippingAmount + shippingTaxAmount) * packageCount) + surcharge
        ) : 0;

        return cheapestMethodShippingAmount;

    }, [method, shippingAmount, shippingAmountValid])

    const priceToShow = (methodShippingAmount) ? getPriceToShow(methodShippingAmount, salesChannel, currencyCode) : "Free";

    return (
        <Button
            disabled={awaitingCall}
            padding="py-2 px-3 sm:px-4"
            style={{ borderColor: salesChannel.secondaryColour }} className={"border-2"} childrenWrapperClassName={"w-full"} type={finalSelected ? "primary" : "transparent"} onClick={handleClick}>
            <div className="flex flex-row items-center justify-between w-full gap-4">
                <div className="my-auto">
                    {finalSelected ? (
                        <BsCheckCircleFill />
                    ) : (
                        <BsCircle />
                    )}
                </div>
                <p className="flex-1 text-left text-sm sm:text-base">{method.displayName || method.name}</p>
                <p className="text-sm sm:text-base">{priceToShow}</p>
            </div>
        </Button >
    );
}