import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ProductCategoryType, TagCategoryTagType, TagCategoryType } from "../../../types";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { jsonParseString } from "../../../utils/string";
import Button from "../../shared/Button";
import { arrayToMap } from "../../../utils/array";
interface ProductsDisplayByTagProps {
    tagCategories: TagCategoryType[];
}
export default function ProductsDisplayByTag({ tagCategories }: ProductsDisplayByTagProps) {
    const { websiteTemplate } = useSalesChannel();
    const [selectedTags, setSelectedTags] = useState<TagCategoryTagType[]>([]);
    const { allTagCategory, allProductCategory } = useStaticQuery(graphql`
        query {
            allTagCategory {
                edges {
                    node {
                        id
                        name
                        tags {
                            name
                            icon
                            tag
                            tagCategory
                            id
                        }
                    }
                }
            }

            allProductCategory {
                nodes {
                    name
                    products {
                        id
                        SKUName
                        SKUCode
                        currentPrice
                        imageFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                    id
                }
            }
        }
    `);

    let categories = allTagCategory?.edges.map(el => el.node);
    let productCat = allProductCategory?.nodes ?? [];
    let selectedProductCat = productCat[0];
    let selectedTagsDict = arrayToMap(selectedTags, "id");
    return (
        <div className="container mx-auto">
            <div className="flex flex-row flex-wrap py-4 w-full min-h-[400px]">
                <aside className="w-full sm:w-1/3 md:w-1/4 px-2 border border-solid">
                    {(categories ?? []).map(cat => {
                        return (
                            <div
                                className="flex items-center justify-center w-full m-2 flex-col"
                                key={cat.id}
                            >
                                <div className="p-2 w-full flex-col mb-3">
                                    <h3 className="mb-2">{cat.name}</h3>

                                    <p>Select one</p>
                                </div>

                                <div className="p-2 flex items-center justify-start w-full m-2">
                                    {(cat?.tags ?? []).map(item => {
                                        let file = jsonParseString(item?.icon);
                                        return (
                                            <button
                                                className={`bg-${
                                                    selectedTagsDict[item?.id]
                                                        ? "secondary"
                                                        : "gray"
                                                } flex flex-col m-2 cursor-pointer p-2 rounded`}
                                                key={item?.id}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (selectedTagsDict[item?.id]) {
                                                        let newSelectedTags = [...selectedTags];
                                                        let idx = newSelectedTags.findIndex(
                                                            el => el.id === item.id
                                                        );
                                                        if (idx >= 0) {
                                                            newSelectedTags.splice(idx, 1);
                                                        }
                                                        setSelectedTags(newSelectedTags);
                                                    } else {
                                                        setSelectedTags([...selectedTags, item]);
                                                    }
                                                }}
                                            >
                                                <img src={file?.url} />
                                                <p>{item.name}</p>
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    <div className="container full-width p-2 flex items-center justify-center">
                        <Button className="full-width">Submit</Button>
                    </div>
                </aside>
                <main
                    role="main"
                    className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2 border border-solid"
                >
                    <div className="flex flex-wrap">
                        <GetComponent
                            template={websiteTemplate}
                            component="ProductList"
                            props={{
                                productCategory: selectedProductCat
                            }}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
}
