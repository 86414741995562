import React from "react";
import { ProductSelectorProps } from "../../websiteTemplates/Default/ProductSelector";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import { ProductType } from "../../../types";

export default function ProductSelector(defaultProduct: ProductType) {
    let props: ProductSelectorProps =
    { defaultProduct: defaultProduct, slug: "" }
    const salesChannel = useSalesChannel();

    return <GetComponent component="ProductSelector" template={salesChannel?.websiteTemplate} props={props} />
}
