import { FormInput } from "./types";

export const ADDRESS_FORM: FormInput[] = [
    {
        name: "firstName",
        label: "First name",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "given-name"
    },
    {
        name: "lastName",
        label: "Last name",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "family-name"
    },
    {
        name: "line1",
        label: "Address Line 1",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "address-line1"
    },
    {
        name: "line2",
        label: "Address Line 2",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "address-line2"
    },
    {
        name: "city",
        label: "City",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "address-level2"
    },
    {
        name: "postCode",
        label: "Postcode",
        type: "text",
        col: "w-full lg:w-1/2",
        autocomplete: "postal-code"
    },
    {
        name: "countryCode",
        label: "Country",
        type: "countrySelector",
        col: "w-full",
        autocomplete: "country"
    }
];

export const CONTACT_FORM: FormInput[] = [
    {
        name: "firstName",
        label: "First name",
        type: "text",
        col: "w-full lg:w-1/2"
    },
    {
        name: "lastName",
        label: "Last name",
        type: "text",
        col: "w-full lg:w-1/2"
    },
    {
        name: "email",
        label: "Your email",
        type: "text",
        col: "w-full lg:w-1/2"
    },
    {
        name: "phoneNumber",
        label: "Your phone",
        type: "text",
        col: "w-full lg:w-1/2"
    }
];

export const ORDER_NOTES_FORM: FormInput = {
    name: "orderNotes",
    label: "Order Note",
    type: "text",
    col: "",
    placeholder: "Please enter order notes for the courier here."
};
