import Header from "./Header";
import Button from "./Button";
import Footer from "./Footer";
import Cart from "./Cart";
import ProductList from "./ProductList";
import ReviewList from "./ReviewsList";

export const SCOComponents: { [key: string]: any } = {
    Header: Header,
    Button: Button,
    Footer: Footer,
    Cart: Cart,
    ProductList: ProductList,
    ReviewList: ReviewList,
};
