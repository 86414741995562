export const CUSTOMER_SCHEMA: any[] = [
    {
        name: "firstName",
        label: "First Name",
        readonly: false
    },
    {
        name: "lastName",
        label: "Last Name",
        readonly: false
    }
];
