import React, { ReactElement, useMemo } from 'react'
import { ProductType } from '../../../../../types'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import EditorJsRenderer from '../../../../shared/EditorJSRender'
import { salesChannel } from '../../../../../config'
import { jsonParseString, jsonParseStringArray } from '../../../../../utils/string'

interface Props {
    product: ProductType
}

export default function ProductDetailAccordion({ product }: Props) {

    const specs = (product.specifications || []).sort((a, b) => a.sort - b.sort);

    const fullDescription = useMemo(() => {
        if (product.fullDescriptionBySalesChannel) {
            try {
                const fullDescriptionBySalesChannel = JSON.parse(product.fullDescriptionBySalesChannel);
                if (fullDescriptionBySalesChannel[salesChannel]) {
                    return fullDescriptionBySalesChannel[salesChannel];
                };
            } catch (e) {
                console.error('Error parsing fullDescriptionBySalesChannel', e);
                return product.fullDescription;
            }
        }
        return product.fullDescription;
    }, [product])

    return (
        <div className='border-t-2'>
            <TabContent tabName='Product Description'>
                <EditorJsRenderer content={fullDescription} />
            </TabContent >
            {specs.length > 0 && (
                <TabContent tabName='Key Features'>
                    <div className='border-2 rounded-md'>
                        {specs.map((spec, index) => {
                            const borderClass = !index ? '' : 'border-t-2';
                            let specValue = spec.value;
                            const specValueParsed = jsonParseStringArray(spec.value);
                            if (specValueParsed) {
                                specValue = specValueParsed.join(", ");
                            }
                            return <div className="flex w-full" key={index}>
                                <span className={`p-3 py-4 font-bold w-[150px] ${borderClass}`}>{spec.specificationLabel}</span>
                                <span className={`p-3 py-4 flex-1 ${borderClass}`}>{specValue}</span>
                            </div>
                        })}
                    </div>
                </TabContent>
            )}
        </div >
    )
}

const TabContent = ({ tabName, children }: { tabName: string, children: ReactElement | ReactElement[] }) => {

    const [active, setActive] = React.useState(false)

    return <div className='border-b-2'>
        <div className='flex justify-between items-center py-6 cursor-pointer' onClick={() => setActive(!active)}>
            <h3 className='text-xl font-bold mb-0 '>
                {tabName}
            </h3>
            <div>
                {active ? (
                    <BsChevronUp size={25} />
                ) : (
                    <BsChevronDown size={25} />
                )}
            </div>
        </div>
        {active && <div className='pb-8 px-3'>
            {children}
        </div>}
    </div>
};