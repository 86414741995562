import React, { useState, useEffect } from "react";
import StripePayment from "./Stripe";
import SagePay from "./SagePay";
import PayPal from "./Paypal";
import { FaCcStripe, FaCcPaypal, FaExclamationCircle, FaCreditCard } from "react-icons/fa";
import { BsCheck, BsExclamationCircleFill } from "react-icons/bs";
import EcommerceService from "../../../../../services/ecommerceApi";
import SiteModal from "../../../../shared/SiteModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import Button from "../../../../shared/Button";
import order, { updateOrder } from "../../../../../store/order";
import { navigate } from "gatsby";
import { OrderType } from "../../../../../types";
import { formatPrice } from "../../../../../utils/currency";
import Spinner from "../../../../shared/Spinner";
import Airwallex from "./Airwallex";

export interface IPaymentGateway {
    id: string;
    name: string;
    type: string;
    icon: React.ReactNode | null;
    description: string;
    config: any;
    acceptedCards?: {
        id: string;
        name: string;
        code: string;
        cardRegex: string;
        icon: string;
    }[];
}

export default function PaymentOptions({ className, setOpen }: any) {

    const dispatch = useDispatch<AppDispatch>();
    const [redirecting, setRedirecting] = useState(false);
    const [orderErrors, setOrderErrors] = useState<string[]>([]);

    const order = useSelector((state: RootState) => state.order.order);

    const totalAmount = useSelector(
        (state: RootState) => state.order.order?.totalAmount || 0
    );
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<IPaymentGateway | null>(
        null
    );

    const [configuredPaymentGateways, setConfiguredPaymentGateways] = useState<IPaymentGateway[] | undefined>(undefined);

    useEffect(() => {
        loadPaymentGateways();
    }, []);

    useEffect(() => {
        if (!selectedPaymentGateway && configuredPaymentGateways && configuredPaymentGateways.length > 0) {
            setSelectedPaymentGateway(configuredPaymentGateways[0]);
        }
    }, [configuredPaymentGateways, selectedPaymentGateway]);

    const loadPaymentGateways = async () => {
        const data = await EcommerceService.getPaymentGateways();

        const gateways: IPaymentGateway[] = (data ?? []).map(paymentGateway => {
            const { name, type } = paymentGateway;

            const pg: IPaymentGateway = {
                id: paymentGateway.id,
                name: name,
                type: type,
                icon: <FaCreditCard style={{ width: "80px", height: "50px", padding: "10px" }} />,
                description: name,
                config: paymentGateway.config,
                acceptedCards: paymentGateway?.acceptedCards ?? []
            };

            if (type === "stripe") {
                pg.icon = (
                    <FaCcStripe style={{ width: "80px", height: "50px", color: "#4379FF" }} />
                );
            }

            if (type === "sagepay") {
                pg.icon = (
                    <div className="py-1 px-3 border-2 text-sm">
                        Powered by{" "}
                        <span className="font-extrabold" style={{ color: "#00dc00" }}>
                            SagePay
                        </span>
                    </div>
                );
            }

            if (type === "paypal") {
                pg.icon = (
                    <FaCcPaypal
                        style={{
                            width: "80px",
                            height: "50px",
                            color: "#3b7bbf "
                        }}
                    />
                );
            }

            return pg;
        });

        gateways.sort((a, b) => {
            // Stripe should always be first
            if (a.type === "stripe") return -1;
            if (b.type === "stripe") return 1;
            return a.name.localeCompare(b.name)
        });

        setConfiguredPaymentGateways(gateways);
    };

    const handlePlaceOrder = async (order: OrderType) => {

        let errors = order?.errors;
        if (errors && errors.length > 0) {
            setOrderErrors(errors);
            return;
        }

        await dispatch(updateOrder(order));

        switch (order?.status) {
            case "secureProcessing":
                await navigate("/sagepay/secure", {
                    state: {
                        orderId: order?.id
                    }
                });
                break;
            case "shoppingCart":
                setRedirecting(false);
                break;
            default:
                navigate("/order/" + order?.id);
                break;
        }
    };

    if (!configuredPaymentGateways) return (
        <div className="flex items-center justify-center py-14">
            <Spinner width={"35px"} height={"35px"} />
        </div>
    );

    if (!order?.customer || !order.billingAddress || (order.isShipped && !(order.shippingAddress.length > 0))) {
        return (
            <div className="flex items-center justify-center p-5 bg-red-100">
                <div className="w-[100px] flex items-end justify-center">
                    <FaExclamationCircle size={25} />
                </div>
                <div className="text-lg font-bold">
                    Please provide your billing and shipping address before proceeding to payment.
                </div>
            </div>
        )
    }

    const orderHasErrors = Array.isArray(orderErrors) && orderErrors.length > 0;

    return (
        <div className={`${className}`}>

            {redirecting && (!orderHasErrors) && (
                <SiteModal>
                    <div className="flex items-center justify-center min-h-[50vh]">
                        <div className="text-2xl font-bold mb-3">Processing...</div>
                    </div>
                </SiteModal>
            )}

            {orderHasErrors && (
                <SiteModal width="700px">
                    <div className="flex items-center text-center justify-center">
                        <div>
                            <h3 className="text-2xl mb-2">Invalid Cart</h3>
                            <p>
                                The following issues were found with your order. Please correct them
                                and try again
                            </p>
                            <div className="pt-3 pb-6">
                                {orderErrors.map((err, idx) => {
                                    return (
                                        <div
                                            key={`cart-error-${idx}`}
                                            className="my-3 p-4 flex items-center bg-gray-200 mb-3"
                                        >
                                            <BsExclamationCircleFill />
                                            <span className="ml-3">{err}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex justify-center">
                                <Button
                                    className="w-full"
                                    onClick={() => {
                                        navigate("/cart")
                                    }}
                                >
                                    Back to Cart
                                </Button>
                            </div>
                        </div>
                    </div>
                </SiteModal>
            )}

            {!orderHasErrors && (
                <>
                    {/* No PGs */}
                    {configuredPaymentGateways.length === 0 && (
                        <div className="flex items-center justify-center p-5 bg-red-100">
                            <div className="w-[100px] flex items-end justify-center">
                                <FaExclamationCircle size={25} />
                            </div>
                            <div className="text-lg font-bold">
                                We are experiencing major issues with our payment provider. We are working hard on a resolution. We expect to be live by 11 AM on Monday, 1st of July.
                            </div>
                        </div>
                    )}

                    {configuredPaymentGateways.length > 1 && (
                        <div className="flex flex-col mb-3">
                            {configuredPaymentGateways.map(option => (
                                <PaymentOption
                                    option={option}
                                    selectedOption={selectedPaymentGateway}
                                    setSelectedPaymentGateway={setSelectedPaymentGateway}
                                />
                            ))}
                        </div>
                    )}

                    {configuredPaymentGateways.length === 1 && (
                        <div className="flex justify-between mb-2">
                            <div></div>
                            {configuredPaymentGateways[0].icon}
                        </div>
                    )}

                    {selectedPaymentGateway && (
                        <div className="w-full justify-center items-center">
                            {selectedPaymentGateway?.type === "stripe" && (
                                <StripePayment
                                    rendered={() => setOpen(true)}
                                    paymentGateway={selectedPaymentGateway}
                                    handlePlaceOrder={handlePlaceOrder}
                                />
                            )}
                            {selectedPaymentGateway.type === "sagepay" && (
                                <SagePay
                                    paymentGatewayId={selectedPaymentGateway.id}
                                    setRedirecting={setRedirecting}
                                    handlePlaceOrder={handlePlaceOrder}
                                    rendered={() => setOpen(true)}
                                    acceptedCards={selectedPaymentGateway?.acceptedCards}
                                    TotalComponent={<>
                                        <div className="flex justify-end text-lg">
                                            <div className="flex items-end px-3 py-1">
                                                <div className="mr-3">
                                                    Total
                                                </div>
                                                <div className="text-4xl font-bold">
                                                    {formatPrice(totalAmount, currencyCode)}
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                />
                            )}
                            {selectedPaymentGateway?.type === "paypal" && (
                                <PayPal
                                    rendered={() => setOpen(true)}
                                    paymentGateway={selectedPaymentGateway}
                                    handlePlaceOrder={handlePlaceOrder}
                                />
                            )}
                            {selectedPaymentGateway?.type === "airwallex" && (
                                <Airwallex
                                    paymentGateway={selectedPaymentGateway}
                                    handlePlaceOrder={handlePlaceOrder}
                                />
                            )}
                        </div>
                    )}
                </>
            )}

        </div>
    );
}

const PaymentOption = ({
    option,
    selectedOption,
    setSelectedPaymentGateway
}: {
    option: IPaymentGateway;
    selectedOption?: IPaymentGateway | null;
    setSelectedPaymentGateway: (option: IPaymentGateway) => void;
}) => {

    const isSelectedOption = option.id === selectedOption?.id;

    return (
        <div className="px-3 w-full bg-gray-100 mb-3" key={option.name}>
            <div
                className={`flex items-center w-full py-1 border-gray-200 cursor-pointer`}
                onClick={() => setSelectedPaymentGateway(option)}
            >
                <div className="px-2 flex items-center">
                    <span
                        className={`flex items-center justify-center rounded-full w-6 h-6 border-2 ${isSelectedOption && "bg-green-500 border-green-500"
                            }`}
                    >
                        {isSelectedOption && <BsCheck style={{ color: "white" }} />}
                    </span>
                </div>
                <div className="block grow font-bold mx-3">
                    <label>{option.description}</label>
                </div>
                <span className="px-2">{option.icon}</span>
            </div>
        </div>
    );
};
