import { validatePhoneNumber } from "../../../utils/loqateValidations";

export const SIGN_UP_FORM = [
    {
        name: "email",
        label: "Email",
        validate: (input) => {
            if (!input) {
                return { valid: false, message: `Email is required.` };
            }
            return { valid: true }
        }
    },
    {
        name: "firstName",
        label: "First Name",
        validate: (input) => {
            if (!input) {
                return { valid: false, message: `First Name is required.` };
            }
            return { valid: true }
        }
    },
    {
        name: "lastName",
        label: "Last Name",
        validate: (input) => {
            if (!input) {
                return { valid: false, message: `Last Name is required.` };
            }
            return { valid: true }
        }
    },
    {
        name: "phoneNumber",
        label: "Phone Number",
        validate: validatePhoneNumber
    }
];
