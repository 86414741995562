import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { ProductDataType, RelatedProductType } from "../../../types";
import ecommerceApi from "../../../services/ecommerceApi";
import { getPriceToShow } from "../../../utils/currency";
import Button from "../../shared/Button";
import { addToCart } from "../../../store/order";
import { jsonParseString } from "../../../utils/string";
import { useSalesChannel } from "../../../context/SalesChannelContext";

import ReviewSummary from "../ReviewSummary";

export default function CartSuggestedProducts() {
    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const orderItems = useSelector((state: RootState) => state.order.order?.orderItems);

    const [products, setProducts] = React.useState<RelatedProductType[] | undefined>(undefined);

    useEffect(() => {
        load(orderId);
    }, []);

    useEffect(() => {
        load(orderId);
    }, [orderItems]);

    const load = async (orderId?: string) => {
        if (orderId) {
            let suggested = await ecommerceApi.getCartSuggestedProducts(orderId);
            if (suggested && (suggested?.length || 0) > 0) {
                // select top twp
                suggested = suggested.slice(0, 2);
            }
            setProducts(suggested || undefined);
        }
    };

    if ((products?.length || 0) < 1) {
        return <></>;
    }

    return (
        <div className="px-5 md:px-0">
            <h2 className="text-lg">Recommended Products</h2>
            <div className="flex flex-wrap mb-3">
                {products?.map(product => (
                    <SuggestedProduct key={product.addedProduct} product={product} />
                ))}
            </div>
        </div>
    );
}

const SuggestedProduct = ({ product }: { product: RelatedProductType }) => {
    const dispatch = useDispatch<AppDispatch>();

    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const salesChannel = useSalesChannel();
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode
    );

    const [adding, setAdding] = React.useState(false);
    const [selectedProductData, setSelectedProductData] = React.useState<ProductDataType | null>(
        null
    );

    const loadProductData = useCallback(async () => {
        if (product?.addedProduct) {
            const data = await ecommerceApi.getProductData(product.addedProduct);
            setSelectedProductData(data);
        }
    }, [product?.addedProduct]);

    const handleAddToCart = async () => {
        setAdding(true);
        if (orderId) {
            await dispatch(addToCart({ orderId, skuId: product.addedProduct, quantity: 1 }));
        }
        setAdding(false);
    };

    const imageUrl = useMemo(() => {
        if (product?.imageUrl) {
            const file = jsonParseString(product?.imageUrl);
            if (file) {
                return file.url;
            }
        }
        return undefined;
    }, [product?.imageUrl]);

    useEffect(() => {
        loadProductData();
    }, [loadProductData]);

    const reviewSummary = useMemo(() => {
        return selectedProductData?.reviewSummary
    }, [selectedProductData?.reviewSummary]);

    return (
        <div className="py-4 px-5 w-full bg-gray-100 mt-3">
            <div className="flex -mx-2">
                <div className="px-2">
                    <img
                        src={imageUrl}
                        className="w-20 h-20 object-contain"
                        width={"80px"}
                        height={"80px"}
                    />
                </div>
                <div className="px-2 grow">
                    <div className="flex items-center justify-between -mx-3">
                        <div className="flex-1 px-3">
                            <div className="font-bold mt-2 mb-1">{product?.SKUName}</div>
                            <ReviewSummary
                                size={12}
                                reviewSummary={reviewSummary}
                                hideText
                                hideRating
                                Wrapper={({ children }) => <div className="w-full mb-2">{children}</div>}
                            />
                            <div>
                                {getPriceToShow(product?.currentPrice, salesChannel, currencyCode)}
                            </div>
                        </div>
                        <div className="px-3">
                            <div className="flex">
                                <Button
                                    className=""
                                    loader={adding}
                                    disabled={adding}
                                    onClick={handleAddToCart}
                                >
                                    Add to Cart
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
