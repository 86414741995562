import React, { useEffect, useRef, useState } from 'react'
import SiteModal from '../../components/shared/SiteModal'
import CheckoutLayout from '../../components/checkout/CheckoutPage/CheckoutLayout'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { getOrder, updateOrder } from '../../store/order'
import { navigate } from 'gatsby'
import ecommerceApi from '../../services/ecommerceApi'

export default function SagePaySecure({ location }) {

    const orderId = location.state?.orderId;

    const [metadata, setMetadata] = useState<{
        sagepay?: {
            acsUrl: string;
            cReq: string;
        }
    } | null
    >(null);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        load()
    }, [])

    const load = async () => {

        const order = await ecommerceApi.getOrder(orderId);

        if (order) {
            try {
                const metadata = JSON.parse(order.metadata);
                if (metadata.sagepay?.acsUrl) {
                    setMetadata(metadata);
                }
            } catch (err) {
                console.error("load try catch error", err);
            }
        }
    }

    useEffect(() => {
        if (metadata?.sagepay?.acsUrl) {
            formRef.current?.submit();
        }
    }, [metadata])


    return (
        <CheckoutLayout hideNav={true} seo={{ title: "Checkout" }}>
            <form ref={formRef} className="hidden" action={metadata?.sagepay?.acsUrl} method="post">
                <input type="hidden" name="creq" value={metadata?.sagepay?.cReq} />
                <input type="hidden" name="threeDSSessionData" value={orderId} />
                <p>Please click button below to proceed to 3D secure.</p> <input type="submit" value="Go" />
            </form>

            <SiteModal
            >
                <div className='flex items-center justify-center min-h-[50vh]'>
                    <div className='text-2xl font-bold mb-3'>
                        Processing...
                    </div>
                </div>
            </SiteModal>
        </CheckoutLayout>
    )
}
