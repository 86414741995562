import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../../../store';
import cognito from '../../../../../services/cognito';
import { setStep, updateOrder } from '../../../../../store/order';
import { navigate } from 'gatsby';
import Button from '../../../../shared/Button';

export default function LoggedInView() {

    const dispatch = useDispatch<AppDispatch>();
    const authCustomer = useSelector((state: RootState) => state.auth.customer);

    const orderIsShipped = useSelector((state: RootState) => state.order.order?.isShipped || false);

    const handleSignOut = async () => {
        await cognito.signOut();
        dispatch(updateOrder(null));
        navigate("/");
    };

    const handleContinueClick = () => {
        dispatch(setStep(orderIsShipped ? "shipping" : "payment"));
    }

    return (
        <>
            <div className='bg-gray-100 p-5'>
                <p>
                    You are logged in as {authCustomer?.firstName} {authCustomer?.lastName}
                </p>
                <p className='mt-2'>
                    Not you? <a className='cursor-pointer text-primary underline' onClick={handleSignOut} >Log out</a>
                </p>
            </div>
            <div className="flex justify-end mt-5">
                <Button type="secondary" className="w-full max-w-[300px] mr-3" padding={"py-3 px-5"} onClick={handleContinueClick}>
                    Continue
                </Button>
            </div>
        </>

    )
}
