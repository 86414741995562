import { graphql, useStaticQuery } from "gatsby";
import { IReviewPlatform } from "../../../../build/BlockHelper/types";

export const useLayout = () => {

    const data = useStaticQuery(graphql`query {
    salesChannel {
        id
        name
        websiteTemplate
        email
        logoFile {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
        }
        iconFile {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            publicURL
        }
        phone
        address
        metadata
        type
        noIndex
        taxes {
            id
            name
            taxRates {
                id
                name
                taxRate
                countryCodeRegex
                zipCodeRegex
            }
        }
        defaultPostcode
        defaultCountry
        
        primaryColour
        secondaryColour

        donations {
            id
            name
            desc
            type
            value
            image
            enabled
            salesChannel
        }
    }
    reviewPlatform {
        id
        type
        name
        config{
            property
            value
        }
    }
    menuItems: allMenuItem {
        nodes {
            id
            name
            target
            menu
            sort
            icon
            iconColor
            link {
                pageId
                src
                target
                text
            }
        }
    }
    allTemplates: allPageTemplate(limit: 100) {
        nodes {
            id
            name
            headerLogoFile {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
            }
            footerLogoFile {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
            }
            header {
                enabled
                menu
                menus {
                    id
                    name
                    displayName
                }
                template
                logo
            }
            footer {
                enabled
                menu
                template
                logo
                leftTitle
                leftContent
                menus {
                    id
                    name
                    displayName
                }
            }
        }
    }
    socials: allSocial(filter: { displayOnSite: { eq: true } }) {
        nodes {
            id
            type
            linkUrl
            displayOnSite
        }
    }
}`);

    const reviewPlatform = data.reviewPlatform as IReviewPlatform;

    const { salesChannel, allTemplates, menuItems, socials } = data;

    return { salesChannel, allTemplates, menuItems, socials, reviewPlatform };
};
