import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import Button from "../../shared/Button";
import Collapse from "../../shared/Collapse";
import Spinner from "../../shared/Spinner";
import CaretDown from "../../shared/SVG/CaretDown";
import { updateDiscountAmount } from "../../../store/order";
import TextInput from "../../shared/FormComponents/TextInput";
import { formatPrice } from "../../../utils/currency";
import Message from "../../shared/Message";
import { OrderType } from "../../../types";

export default function CouponCode() {
    const dispatch = useDispatch<AppDispatch>();

    const orderId = useSelector((state: RootState) => state.order?.order?.id);
    const discounts = useSelector((state: RootState) => state.order?.order?.discountsApplied);

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    const [codeValue, setCodeValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [messages, setMessages] = React.useState<string[]>([]);

    const handleApplyClick = async (e: any) => {
        e.preventDefault();
        const newMessages: string[] = [];
        if (!orderId) {
            newMessages.push("Order ID is missing.");
        }

        if (!codeValue) {
            newMessages.push("Coupon code is required.");
        }

        if (newMessages.length) {
            setMessages(newMessages);
            return;
        }

        setLoading(true);
        const response = await dispatch(updateDiscountAmount({ orderId: orderId as string, couponCode: codeValue.trim() }));
        if ((response?.payload as OrderType | null)?.errors?.length) {
            setMessages((response.payload as OrderType).errors as string[]);
            setCodeValue("");
        } else {
            setMessages([]);
        }
        setLoading(false);

    };

    return (
        <div className="bg-white relative p-5 mb-3">

            <div className="mb-2 text-xl font-bold">
                Discount
            </div>
            <div className="flex -mx-2">
                <div className="px-2 grow">
                    <TextInput
                        placeholder="Enter Coupon Code Here"
                        value={codeValue}
                        onChange={e => setCodeValue(e.target.value)}
                    />
                </div>

                <div className="px-2 flex items-center">
                    <Button
                        className="bg-black text-white px-3 py-3 flex h-full"
                        onClick={handleApplyClick}
                    >
                        {loading ? <Spinner stroke="#fff" /> : "Apply"}
                    </Button>
                </div>
            </div>
            <Message severity="error" messages={messages} className={"my-4"} />
            {discounts && discounts.length > 0 && (
                <table className="w-full mt-3">
                    <tbody>
                        {discounts.map((discount, index) => (
                            <tr>
                                <td>
                                    <span className="font-bold text-sm">
                                        {discount.name} Applied
                                    </span>
                                </td>
                                <td className="w-[50px] text-right">
                                    <span className="text-sm">
                                        {formatPrice(discount.amountApplied || 0, currencyCode)}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
