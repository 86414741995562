import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DateTime, Duration } from "luxon";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { formatDate } from "../../../utils/dateTime";
import { IOrderShipment } from "../../../types";

interface Props {
    align?: "start" | "center" | "end";
    hasBreak?: boolean;
}

export default function OrderInTheNext({ align = "center", hasBreak }: Props) {

    const [estDispatchDate, setEstDispatchDate] = React.useState<DateTime | null>(null);
    const [estDeliverDate, setEstDeliverDate] = React.useState<DateTime | null>(null);
    const [price, setPrice] = React.useState<number>(0);

    const [dateError, setDateError] = React.useState(false);
    const [isExpired, setIsExpired] = React.useState(false);
    const [countdown, setCountdown] = React.useState<Duration | "loading" | "expired">("loading");

    const orderItemsLength = useSelector((state: RootState) => state.order.order?.orderItems?.length);
    const shipments = useSelector((state: RootState) => state.order.order?.shipments);

    const checkCountdown = useCallback(() => {

        if (!estDispatchDate) return;

        const now = DateTime.now();
        const remainingTime = estDispatchDate.diff(now);
        const milliseconds = remainingTime.as("milliseconds");

        if (milliseconds <= 0) {
            setCountdown("expired");
            setIsExpired(true);
            return;
        }

        setCountdown(remainingTime);

    }, [estDispatchDate]);

    const loadCartInfo = useCallback(() => {

        if (!shipments || (shipments?.length || 0) < 1) return;

        const soonest = shipments?.reduce((prev, current) => {
            const prevDate = DateTime.fromISO(prev.estDispatchDate as string);
            const currentDate = DateTime.fromISO(current.estDispatchDate as string);
            return prevDate < currentDate ? prev : current;
        });
        if (!soonest) return;

        if (!soonest || !soonest?.estDeliveryDate || !soonest?.estDispatchDate) {
            setDateError(true);
            return;
        }

        setIsExpired(false);

        const estDispatchDateTime = DateTime.fromISO(soonest.estDispatchDate);
        const estDeliverDateTime = DateTime.fromISO(soonest.estDeliveryDate);
        const price = (soonest.shippingAmount || 0) + (soonest.shippingTaxAmount || 0);

        setEstDeliverDate(estDeliverDateTime);
        setEstDispatchDate(estDispatchDateTime);
        setPrice(price);
    }, [orderItemsLength, shipments, setEstDeliverDate, setEstDispatchDate, setIsExpired]);

    useEffect(() => {
        loadCartInfo();
    }, [loadCartInfo]);

    React.useEffect(() => {

        if (isExpired) return;

        const interval = setInterval(checkCountdown, 1000)
        return () => {
            clearInterval(interval);
        };
    }, [checkCountdown, isExpired]);

    const deliveryOn = useMemo(() => {
        if (estDeliverDate?.isValid) {
            return formatDate(estDeliverDate, "EEEE, dd MMMM");
        }
        return "";
    }, [estDeliverDate]);

    const countdownFormatted = useMemo(() => {

        // OF: should be skeleton
        if (countdown === "loading") {
            return <div className="inline-block bg-gray-200 animate-cyclic-pulse w-[100px] h-[12px] ml-[2px]" />
        }

        if (countdown === "expired") {
            return "EXPIRED";
        }

        const mins = countdown.as("minutes");

        if (mins < 1) {
            return "EXPIRED";
        }

        // if countdown is less than 1 hour
        if (countdown.as("hours") < 1) {
            return countdown.toFormat("mm 'mins'")
        }

        // if countdown is less than 1 day
        if (countdown.as("days") < 1) {
            return countdown.toFormat("hh 'hrs' mm 'mins'")
        }

        return countdown.toFormat("dd 'days' hh 'hrs' mm 'mins'")
    }, [countdown]);

    useEffect(() => {
        if (countdown === "expired") {
            loadCartInfo();
        }
    }, [countdown]);

    if (!shipments || (shipments?.length || 0) < 1) {
        return <></>;
    }

    if (dateError) {
        return <></>
    }

    return (
        <div className={`text-${align}`}>
            <div>
                <span className="font-bold">
                    {`Get It Delivered ${price === 0 ? "Free" : ""} On ${deliveryOn}.`}
                    &nbsp;
                </span>
                {hasBreak && <br />}
                {`Order within`}
                &nbsp;
                <span className="text-primary">
                    {countdownFormatted}
                </span>
            </div>
        </div>
    );
}
