import React, { useState, useEffect, useMemo } from "react";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./form";
import { getPaymentGatewayConfig } from "../../../../../../utils/paymentGateway";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { jsonParseString } from "../../../../../../utils/string";

export default function StripePayment({
    rendered,
    paymentGateway,
    handlePlaceOrder
}) {
    const [publicKey, setPublicKey] = useState(null);
    const [config, setConfig] = useState<any>(null);

    const metadata: any = useSelector<RootState>((state) => state.order?.order?.metadata);

    useEffect(() => {
        const config = getPaymentGatewayConfig(paymentGateway);
        setConfig(config);
        const publicKey = config["publicKey"];
        if (publicKey) {
            setPublicKey(publicKey);
        }
        rendered();
    }, []);

    const intentClientSecret = useMemo(() => {
        const data = jsonParseString(metadata);
        return data?.stripe?.paymentIntentClientSecret as string;
    }, [metadata]);

    if (config !== null && publicKey && intentClientSecret) {
        return (
            <Elements stripe={loadStripe(publicKey)} options={{
                clientSecret: intentClientSecret
            }}>
                <StripeForm
                    paymentGatewayId={paymentGateway.id}
                    handlePlaceOrder={handlePlaceOrder}
                    config={config}
                />
            </Elements>
        );
    }

    return <div className="
        bg-red-100
        border border-red-400
        text-red-700
        px-4
        py-3
        rounded
        relative
        my-4
        text-center
    ">
        Something went wrong with this payment method!
    </div>;
}