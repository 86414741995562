export const CARD_OPTIONS = {
    iconStyle: "solid" as const,
    hidePostalCode: true,
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#000",
            fontWeight: 700,
            fontFamily: "Raleway, Helvetica, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#444" },
            "::placeholder": { color: "#444" }
        },
        invalid: {
            iconColor: "#ef4444",
            color: "#ef4444"
        }
    }
};