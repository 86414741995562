import { renderToString } from "react-dom/server";
import { Text } from "domhandler";

import parse, { DOMNode } from "html-react-parser";
import { ProductDataType, ProductType } from "../types";
import { jsonParseString } from "./string";
import { getSiteKeys } from "./config";
import Handlebars from "handlebars";
import { ISalesChannel } from "../services/ecommerceApi/SalesChannel/types";
import { getPriceToShow, round2dp } from "./currency";

const ELEMENT_REGEX = /({{.*?}})/gs;
const STATIC_REGEX = /(\[\[.*?\]\])/gs;

const { salesChannel } = getSiteKeys();

export function parsedHtmlTransformation(html: string, data: Record<string, any> = {}) {
    let staticMatches = html.match(STATIC_REGEX);

    if (staticMatches && (staticMatches?.length || 0) > 0) {
        for (const match of staticMatches) {
            const key = match
                .replace("[[", "")
                .replace("]]", "")
                .toLowerCase()
                .replace(" ", "")
                .trim();

            const stringToInsert = renderToString(data[key]);

            const regex = new RegExp("(\\[\\[" + key + "\\]\\])", "gs");
            html = html.replace(regex, stringToInsert);
        }
    }

    // find all in data that is undefined
    const toRemove = html.match(ELEMENT_REGEX)?.filter(match => {
        const key = match.replace("{{", "").replace("}}", "").toLowerCase().replace(" ", "").trim();
        return data[key] === undefined;
    });

    const options = {
        replace: (domNode: DOMNode) => {
            if (domNode.type === "text") {
                const text = domNode as Text;
                const value = text.data.trim();

                if ((value.match(ELEMENT_REGEX)?.length || 0) > 0) {
                    const key = value
                        .replace("{{", "")
                        .replace("}}", "")
                        .toLowerCase()
                        .replace(" ", "")
                        .trim();
                    const newValue = data[key];
                    return newValue;
                }
            }

            return "";
        }
    };

    toRemove?.forEach(match => {
        html = html.replace(match, "");
    });

    return parse(html, options) as JSX.Element;
}

export function constructProductMetricsHtml(
    product: ProductType,
    productData: ProductDataType,
    salesChannel: ISalesChannel,
    currencyCode?: string
) {
    try {
        const productPricingMetrics = product?.productPricingMetrics;

        const productContext = {
            ...product,
            ...productData,
            salesChannel,
            currencyCode
        };

        // loop through each metric for product and render string
        const templatedHtmlArr = productPricingMetrics
            ?.map(ppm => templateProductMetricHtml(ppm.calculationHtml, productContext))
            .filter(Boolean);

        return templatedHtmlArr?.join("<br />") || constructProductMetricHtml(product);
    } catch (error) {
        console.error("constructProductMetricsHtml", error);
        return "";
    }
}

export function constructProductMetricHtml(selectedProduct?: ProductType) {
    try {
        const parsed = jsonParseString(selectedProduct?.productMetricHtml ?? "[]");

        if (Array.isArray(parsed) && parsed.length > 0) {
            let item = parsed.find(p => p.salesChannelId === salesChannel);
            if (!item) {
                item = parsed.find(p => p.salesChannelId === "global");
            }
            if (!item) {
                item = parsed[0];
            }
            if (item) {
                return item.productMetricHtml;
            }
            return "";
        }
        return selectedProduct?.productMetricHtml ?? "";
    } catch {
        return undefined;
    }
}

export const templateProductMetricHtml = (
    template: string,
    context: ProductType & {
        salesChannel: ISalesChannel;
        currencyCode?: string;
    }
) => {
    try {
        const handlebarsTemplate = Handlebars.compile(template);
        return handlebarsTemplate(context);
    } catch (e) {
        console.warn("Error in templateProductMetricHtml", e, template);
        return undefined;
    }
};

Handlebars.registerHelper(
    "helperCalculate",
    (
        value1: number,
        symbol: string,
        value2: number,
        mutation: string,
        salesChannel: ISalesChannel | undefined
    ) => {
        let calculationResult: number = 0;

        switch (symbol) {
            case "+":
                calculationResult = value1 + value2;
                break;
            case "-":
                calculationResult = value1 - value2;
                break;
            case "*":
                calculationResult = value1 * value2;
                break;
            case "/":
                if (!value2) {
                    throw new Error("Division by zero");
                }
                calculationResult = value1 / value2;
                break;
            default:
                throw new Error("Invalid symbol");
        }

        // Render Price
        if (salesChannel) {
            return getPriceToShow(calculationResult, salesChannel);
        }

        return calculationResult.toFixed(2);
    }
);
