import React from "react";
import { ProductType } from "../../../../../types";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";

interface Props {
    accreditations: ProductType["accreditations"];
}

export default function ProductDetailAccreditations({ accreditations }: Props) {
    return (
        <div className="flex flex-wrap items-center -mx-2">
            {accreditations?.map(accreditation => (
                <AccreditationItem accreditation={accreditation} key={accreditation.name} />
            ))}
        </div>
    );
}
const AccreditationItem = ({ accreditation }: { accreditation: any | undefined }) => {
    let image = getImage(accreditation.imageFile) as IGatsbyImageData;

    if (!accreditation?.displayOnProductDetail) return null;

    return (
        <div className="px-2 max-w-[145px]">
            <GatsbyImage
                image={image}
                alt={`${accreditation.name}`}
            />
        </div>
    );
};
