import React from "react";
import { DefaultComponents } from "../Default";
import { EcoblazeComponents } from "../Ecoblaze";
import { SCOComponents } from "../SCO";

export const TEMPLATES: {
    [key: string]: Record<string, any>
} = {
    Default: DefaultComponents,
    Ecoblaze: EcoblazeComponents,
    SCO: SCOComponents
};

interface Props {
    template?: string;
    component: string;
    props?: any;
}

const GetComponent = ({ template, component, props }: Props) => {

    let templateConfig = TEMPLATES[template || "Default"];

    if (!templateConfig) {
        templateConfig = TEMPLATES["Default"];
    }

    let Component = templateConfig[component];

    if (!Component) {
        Component = TEMPLATES["Default"]?.[component];
    }

    if (!Component) {
        console.error(`${component} is not a valid component`);
        return <></>;
    }

    return <Component {...props} />;
};

export default GetComponent;