import React, { useEffect, useMemo, useState } from "react";
import { ProductCategoryType, ProductDataType, ProductType } from "../../../../types";
import ecommerceApi from "../../../../services/ecommerceApi";
import { formatPrice, getPriceAsNumber, getPriceToShow, round2dp } from "../../../../utils/currency";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { addToCart } from "../../../../store/order";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../../../../components/shared/Button";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import StockIndicator from "../../../../components/ecommerce/StockIndicator";
import OrderItemQuantityModifier from "../../../../components/ecommerce/OrderItemQuantityModifier";
import QuantityModifier from "../../../../components/ecommerce/QuantityModifier";
import { useSalesChannel } from "../../../../context/SalesChannelContext";
import { sortByKey } from "../../../../utils/array";
import { extractScCurrValueFromProductAttr } from "../../../../utils/string";
import { constructProductMetricHtml, constructProductMetricsHtml } from "../../../../utils/html";
import ReviewSummary from "../../../ecommerce/ReviewSummary";
import { useLayout } from "../../../layouts/Layout/hook";
import { maximumQuantityToAddHelper } from "./maximumQuantityToAddWithoutCurrentShoppingCart";
import SiteModal from "../../../shared/SiteModal";

export interface ProductCategoryListItemProps {
    category: ProductCategoryType;
    salesChannel: ISalesChannel;
    redirectUrl?: string;
}

export default function ProductCategoryListItem({
    category,
    redirectUrl
}: ProductCategoryListItemProps) {

    const dispatch = useDispatch<AppDispatch>();
    const salesChannel = useSalesChannel();
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [modalData, setModalData] = React.useState<string[]>([]);
    const { reviewPlatform } = useLayout();

    const order = useSelector((state: RootState) => state.order.order);
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    const [selectedProduct, setSelectedProduct] = React.useState<ProductType | null>(
        category.products.length > 0 ? category.products[0] : null
    );

    const [imagePreview, setImagePreview] = React.useState<Partial<
        ProductType["assets"][0]
    > | null>(
        selectedProduct?.imageUrl
            ? {
                url: selectedProduct.imageUrl
            }
            : null
    );

    const [selectedProductData, setSelectedProductData] = React.useState<ProductDataType | null>(
        null
    );
    const [taxCodes, setTaxCodes] = useState([]);
    const [adding, setAdding] = React.useState(false);
    const [quantity, setQuantity] = React.useState(1);

    const loadProductData = async () => {
        setSelectedProductData(null);
        if (selectedProduct) {
            const productId = selectedProduct.product || selectedProduct.id;

            const [product, taxCodes] = await Promise.all([
                ecommerceApi.getProductData(productId),
                ecommerceApi.getProductTaxCodesData(productId)
            ]);

            setTaxCodes(taxCodes);
            setSelectedProductData(product || null);
        }
        setImagePreview(selectedProduct ? { url: selectedProduct.imageUrl } : null);
    };

    const handleAddToCartClick = async () => {
        setAdding(true);
        const orderId = order ? order.id : null;
        const params = {
            orderId: orderId,
            skuId: selectedProduct?.id,
            quantity
        };
        setQuantity(1);
        const addToCartResponse = await dispatch(addToCart(params));
        const hasErrors = !!addToCartResponse?.payload?.errors;
        setShowModal(hasErrors);
        setModalData(addToCartResponse?.payload?.errors || []);
        setAdding(false);
    };

    const handleHeadingClick = () => {
        navigate(selectedProduct?.url || "/");
    };

    useEffect(() => {
        loadProductData();
    }, [selectedProduct]);

    const pricing = useMemo(() => {

        if (!selectedProductData) return null;

        const priceVal = getPriceAsNumber(selectedProductData.currentPrice, salesChannel, currencyCode);
        const listPriceVal = selectedProductData.listPrice ? getPriceAsNumber(selectedProductData.listPrice, salesChannel, currencyCode) : undefined;

        const price = formatPrice(
            priceVal,
            currencyCode
        );

        const listPrice = listPriceVal ? formatPrice(listPriceVal) : undefined;

        let diff = listPriceVal ? round2dp(listPriceVal - priceVal) : undefined;

        return {
            priceVal,
            price,
            listPriceVal,
            listPrice,
            diff: diff ? formatPrice(diff) : undefined,
            metrics: selectedProduct ? constructProductMetricsHtml(selectedProduct, {
                ...selectedProductData,
                currentPrice: priceVal,
                listPrice: listPriceVal
            }, salesChannel, currencyCode) : undefined
        };

    }, [selectedProductData, taxCodes, currencyCode, salesChannel.metadata]);

    const image = selectedProduct?.imageFile ? getImage(selectedProduct?.imageFile) : undefined;

    const reviewSummary = useMemo(() => {
        return selectedProductData?.reviewSummary
    }, [selectedProductData?.reviewSummary]);


    const maximumQuantityToAdd = useMemo(() => {
        return maximumQuantityToAddHelper(order, selectedProduct?.id, selectedProductData);
    }, [selectedProductData, selectedProduct, order]);

    return (
        <>
            {showModal && <SiteModal>
                <div className="flex flex-col gap-4">
                    <h3>Unable to add to cart</h3>
                    {modalData.map((message) => (
                        <p key={message}>{message}</p>
                    ))}
                    <div className="flex flex-row gap-2 w-full">
                        <div className="p-2 bg-secondary text-bold text-center rounded-md w-full text-white cursor-pointer" onClick={() => {
                            setShowModal(false);
                        }}>Close</div>
                    </div>
                </div>
            </SiteModal>}
            <div className="bg-white p-8 h-full flex flex-col justify-between">
                {/* Top */}
                <div className="mb-3">
                    {image && (
                        <Link
                            to={selectedProduct?.url || "/"}
                            className="flex justify-center cursor-pointer"
                        >
                            <GatsbyImage
                                image={image as any}
                                alt={selectedProduct?.SKUName || "Product"}
                                className="w-full max-w-[350px] h-full object-contain"
                            />
                        </Link>
                    )}
                </div>
                {/* Bottom */}
                <div className="grow mb-5">
                    <h2
                        className="text-xl mb-1 cursor-pointer font-extrabold"
                        onClick={handleHeadingClick}
                    >
                        {category.name}
                    </h2>
                    <div className="flex flex-wrap items-center -mx-2 mb-3">
                        <div className="text-2xl px-2 h-[32px] flex items-center">
                            {pricing == null ? (
                                <span className="inline-block px-5">
                                    <Icon path={mdiLoading} spin={0.5} size={1} />
                                </span>
                            ) : (
                                <>
                                    {pricing?.listPrice && (
                                        <span className="text-gray-500 line-through mr-2 opacity-99">
                                            {pricing?.listPrice}
                                        </span>
                                    )}
                                    <span>{pricing?.price}</span>
                                </>
                            )}
                        </div>
                        <div className="px-2 w-full mb-3 md:mb-0 md:w-auto">
                            {selectedProductData &&
                                selectedProductData?.stock &&
                                selectedProductData?.stock?.type &&
                                selectedProductData?.stock?.type !== "STOCK_TYPE_NOT_SET" && (
                                    <div className="mt-2 md:mt-0">
                                        <StockIndicator
                                            stock={selectedProductData?.stock}
                                            loading={selectedProductData == null}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                    {pricing?.metrics &&
                        <div
                            className="mb-3 w-full"
                            dangerouslySetInnerHTML={{
                                __html: pricing?.metrics
                            }}
                        ></div>
                    }
                    <div className="mb-5">
                        <label className="block mb-2 font-bold text-secondary text-xs uppercase">
                            Quantity
                        </label>
                        <QuantityModifier quantity={quantity} disabled={maximumQuantityToAdd <= 0} handleQuantityChange={setQuantity} max={maximumQuantityToAdd} />
                    </div>
                    <div>
                        <label className="block mb-2 font-bold text-secondary text-xs uppercase">
                            Options
                        </label>
                        <div className="flex flex-wrap -m-1">
                            {category.products.map((product, index) => (
                                <div
                                    key={product.id}
                                    className={`
                                mx-1 py-[4px] px-4 my-1
                                border-2 border-gray-300 text-gray-500 cursor-pointer rounded
                                ${selectedProduct?.id === product.id &&
                                        "border-secondary text-secondary"
                                        }
                                ${adding && "opacity-99"}
                                `}
                                    onClick={() => !adding && setSelectedProduct(product)}
                                >
                                    <h3 className="text-base font-extrabold font-primary">
                                        {product.shortName || product.SKUName}
                                    </h3>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center -mx-2">
                        <div className="px-2 w-full">
                            {redirectUrl ? (
                                <Button
                                    padding="py-3 px-10"
                                    loader={adding}
                                    onClick={e => {
                                        e.preventDefault();
                                        window.open(
                                            `${redirectUrl}${selectedProduct?.url}`,
                                            "_blank"
                                        );
                                    }}
                                >
                                    View More
                                </Button>
                            ) : (
                                <Button
                                    padding="py-3 px-10"
                                    loader={adding}
                                    onClick={handleAddToCartClick}
                                    disabled={
                                        adding || quantity > maximumQuantityToAdd
                                    }
                                >
                                    Add to Cart
                                </Button>
                            )}
                        </div>
                        <div className="space-y-2">
                            <ReviewSummary width={"200px"} product={selectedProduct || undefined} reviewPlatform={reviewPlatform} reviewSummary={reviewSummary} size={22} hideText />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
