import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { ProductCategoryType } from "../../../../types";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ProductCategoryListItem from "../ProductCategoryListItem";

export interface ProductCategoryListProps {
    categories: ProductCategoryType[];
    displayType?: "list" | "slider";
    salesChannel?: any;
    redirectUrl?: string;
}

export default function ProductCategoryList({
    categories,
    displayType,
    salesChannel,
    redirectUrl
}: ProductCategoryListProps) {
    switch (displayType) {
        case "slider":
            return (
                <Slider
                    categories={categories}
                    salesChannel={salesChannel}
                    redirectUrl={redirectUrl}
                />
            );
        default:
            return (
                <List
                    categories={categories}
                    salesChannel={salesChannel}
                    redirectUrl={redirectUrl}
                />
            );
    }
}

const Slider = ({ categories, salesChannel, redirectUrl }) => {
    return (
        <>
            <div className="-mx-3">
                <Splide
                    options={{
                        autoplay: false,
                        type: "slide",
                        perPage: 3,
                        pagination: false,
                        breakpoints: {
                            640: {
                                perPage: 1,
                                type: "loop"
                            }
                        }
                    }}
                >
                    {categories?.map((category, index) => (
                        <SplideSlide>
                            <div className="px-3 h-full">
                                <ProductCategoryListItem
                                    category={category}
                                    salesChannel={salesChannel}
                                    redirectUrl={redirectUrl}
                                />
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </>
    );
};
const List = ({ categories, salesChannel, redirectUrl }) => {
    return (
        <div className="flex flex-wrap -mx-3">
            {categories?.map((category, index) => (
                <div className="px-3 w-full md:w-1/2 mb-6" key={index}>
                    <ProductCategoryListItem
                        category={category}
                        salesChannel={salesChannel}
                        redirectUrl={redirectUrl}
                    />
                </div>
            ))}
        </div>
    );
};
