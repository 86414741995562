import { OrderType, ProductDataType, ProductType } from "../../../../types";

export const maximumQuantityToAddWithoutCurrentShoppingCart = (selectedProductData: ProductDataType | null) => {
    // HH: If the product is not in stock or the stock is not set, the customer can add 0 items to the cart
    if (selectedProductData?.stock?.type === null || selectedProductData?.stock?.type === "outOfStock") {
        return 0;
    }
    // HH: If the product is not in stock but in presale, the customer can add up to the presale limit to the cart
    if (selectedProductData?.stock?.type === "presale" && selectedProductData.stock.presaleLimit) {
        return parseInt(selectedProductData.stock.presaleLimit);
    }
    // HH: If the product is in stock, the customer can add up to the stock limit to the cart
    if ((selectedProductData?.stock?.type === "inStock" || selectedProductData?.stock?.type === "lowStock") && selectedProductData.onHand) {
        return selectedProductData.onHand;
    }
    return Infinity;
}

export const maximumQuantityToAddHelper = (order: OrderType | null, selectedProductId: string | undefined, selectedProductData: ProductDataType | null, ignoreInCart: boolean = false) => {
    const quantity = maximumQuantityToAddWithoutCurrentShoppingCart(selectedProductData);
    if (ignoreInCart) {
        return quantity;
    }
    // HH: Get the quantity of the product already in the cart
    const quantityInCart = order?.orderItems?.find(item => item.skuId === selectedProductId)?.quantity || 0;
    // HH: Return the maximum quantity that can be added minus the quantity already in the cart
    return quantity - quantityInCart;
}