import React, { ReactElement } from "react";

type MessageProps = {
    severity: "error" | "warning" | "info" | "success";
    messages: string[];
    className?: string;
}
const Message = ({ severity, messages, className }: MessageProps): ReactElement => {
    if (!messages || !messages.length) {
        return <></>;
    }

    let severityColor;
    switch (severity) {
        case "error":
            severityColor = "red";
            break;
        case "warning":
            severityColor = "yellow";
            break;
        case "info":
            severityColor = "blue";
            break;
        case "success":
            severityColor = "green";
            break;
        default:
            severityColor = "gray";
            break;
    }

    return <div className={`bg-${severityColor}-300 p-2 ${className}`}>
        {messages.map((message, index) => (
            <p key={index}>{message}</p>
        ))}
    </div>;

}

export default Message;