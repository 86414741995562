import React from "react";
import TextInput from "../../../../shared/FormComponents/TextInput";
import Button from "../../../../shared/Button";
import { AuthModes } from "../../../../auth/LoginPage/hook";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { isEmail } from "../../../../../utils/validations";

interface Props {
    setMode: (mode: AuthModes) => void;
    setValue: (name: string, value: string) => void;
    getSecretCode: () => void;
}

export default function EnterEmail({ email, loading, setMode, setValue, getSecretCode }) {
    const customerEmail = useSelector((state: RootState) => state.order.order?.customer?.email);

    const [error, setError] = React.useState<null | string>(null);

    const handleInputChange = (e: any) => {
        let email = e.target.value as string;
        let isValid = isEmail(email);
        setError(isValid ? null : "Invalid email");
        setValue("email", e.target.value);
    };
    let hasError = error != null;
    return (
        <div>
            <TextInput
                label={"Email"}
                value={email}
                onChange={handleInputChange}
                error={error != null}
                placeholder={"email@example.com"}
                type={"email"}
                autocomplete={"email"}
            />

            <div className="flex justify-end mt-5">
                <Button
                    type="secondary"
                    disabled={loading || hasError}
                    className="w-full max-w-[300px] mr-3"
                    padding={"py-3 px-5"}
                    onClick={() => {
                        if (!error) {
                            setMode("GUEST");
                        }
                    }}
                >
                    Continue as Guest
                </Button>
                <Button
                    loader={loading}
                    disabled={loading || hasError}
                    className="w-full max-w-[300px]"
                    padding={"py-3 px-5"}
                    onClick={() => {
                        if (!error) {
                            getSecretCode();
                        }
                    }}
                >
                    Login
                </Button>
            </div>
        </div>
    );
}
