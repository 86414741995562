import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CMSContentItem, { ModelContentParsed } from "../CMSContentItem";
import { jsonParseString } from "../../../utils/string";
import { ModelType } from "../../../types";
interface CMSContentListProps {
    modelID: string;
    transformation: string;
}
export default function CMSContentList({ modelID, transformation, ...props }: CMSContentListProps) {

    const { allContent, allModel } = useStaticQuery(graphql`
        query {
            allContent {
                nodes{
                id
                modelId
                data
                }
            }
            allModel {
                nodes {
                    id
                    modelId
                    fieldsArr{
                        id
                        fieldId
                        type
                        label
                    }
                    transformations {
                        html
                        id
                        name
                    }
                }
            }
        }
    `);

    const model = allModel.nodes.find(node => node.id === modelID) as (ModelType | undefined);

    const modelContent = allContent.nodes
        .filter(content => content.modelId === modelID)
        .map(content => ({
            ...content,
            ...jsonParseString(content.data)
        })) as ModelContentParsed[];

    if (!model) {
        return <></>
    }

    return (
        <>
            {modelContent.map(cmsItem => {
                return (
                    <CMSContentItem
                        key={cmsItem.id}
                        model={model}
                        item={cmsItem}
                        selectedTransformation={transformation}
                    />
                );
            })}
        </>
    );
}
