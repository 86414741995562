import { DateTime } from "luxon";

export const getDateLocaleStr = (dateStr: string, formatStr?: Intl.DateTimeFormatOptions) => {
    return DateTime.fromISO(dateStr).toLocaleString(formatStr ?? DateTime.DATETIME_FULL);
};
export const getDateFormatted = (dateStr: string, formatStr?: string) => {
    return DateTime.fromISO(dateStr).toFormat(formatStr || "dd/MM/yyyy");
};
export const getDateTimeFormatted = (dateStr: string) => {
    return DateTime.fromISO(dateStr).toFormat("dd/MM/yyyy HH:mm");
};
export const formatDate = (date: DateTime, formatStr = "dd/MM/yyyy") => {
    return date.toFormat(formatStr);
};
export const formatDateTime = (date: DateTime) => {
    return date.toFormat("dd/MM/yyyy HH:mm");
};
