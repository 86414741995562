import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react'
import { mdiLoading } from "@mdi/js";
import { disable } from 'gatsby/dist/schema/infer/inference-metadata';

interface Props {
    quantity: number;
    label?: string;
    labelVisible?: boolean;
    disabled?: boolean;
    loading?: boolean;
    handleQuantityChange: (val: number) => void;
    max?: number;
    min?: number;
}

export default function QuantityModifier({ quantity, label, loading, labelVisible = false, disabled = false, handleQuantityChange, max = Infinity, min = 1 }: Props) {
    const [localQuantity, setLocalQuantity] = useState<number>(quantity);
    const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setLocalQuantity(quantity);
    }, [quantity])

    const quantityChange = (val: number) => (e: any) => {
        if (disabled || loading) { return; }

        if (val > max) { val = max }
        if (val < min) { val = min }
        if (val === localQuantity) {
            return;
        }

        setLocalQuantity(val);

        if (delay) { clearTimeout(delay); }
        setDelay(setTimeout(() => {
            if (!disabled && val >= 1) {
                handleQuantityChange(val);
            }
        }, 500));
    }

    return (
        <>
            {labelVisible && (
                <label className="block mb-2 font-bold text-secondary text-xs uppercase">{label}</label>
            )}
            <div className={`inline-block ${disabled ? "opacity-50" : ""}
                bg-secondary text-white rounded
                border-2 border-secondary
            `}>
                <div className="flex overflow-hidden">
                    <QtyButton onClick={quantityChange(localQuantity - 1)}>-</QtyButton>
                    {loading ?
                        <div className="w-14 flex justify-center items-center bg-white text-secondary">
                            <Icon spin={0.8} path={mdiLoading} size={0.75} />
                        </div> :
                        <input
                            value={disabled ? "0" : localQuantity}
                            disabled={disabled || loading}
                            className="w-14 font-extrabold flex text-center items-center bg-white text-secondary"
                            readOnly
                            aria-label="Quantity"
                        />}
                    <QtyButton onClick={quantityChange(localQuantity + 1)}>+</QtyButton>
                </div>
            </div>
        </>
    );
}

const QtyButton = ({ children, onClick }: any) => (
    <button
        className="h-10 w-8"
        onClick={onClick}
    >
        {children}
    </button>
)
