import React from 'react'

interface Props {
    children: React.ReactNode
    width?: string
}

export default function SiteModal({ children, width = "700px" }: Props) {
    return (
        <div className='
            fixed top-0 left-0 w-full h-full
            flex justify-center items-center
            z-50 p-5
            '
            style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
            }}>
            <div className='max-w-[100vw] max-h-full overflow-auto' style={{ width }}>
                <div className='p-5 bg-white '>
                    {children}
                </div>
            </div>
        </div>
    )
}
