import React, { useState } from 'react';
import { BsInfoCircle, BsInfoCircleFill } from 'react-icons/bs';

interface Props {
    text: string;
    children: React.ReactNode;
    hidden?: boolean;
}

const Tooltip = ({ text, hidden = false, children }: Props) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const toggleTooltip = () => {
        setTooltipVisible(!isTooltipVisible);
    };

    const showTooltip = () => {
        setTooltipVisible(true);
    };

    const hideTooltip = () => {
        setTooltipVisible(false);
    };

    if (hidden) return (<>{children}</>);

    return (
        <div className="flex items-center">
            <div>
                {children}
            </div>
            <div
                className="relative cursor-pointer ml-2"
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                onClick={toggleTooltip}
            >
                {isTooltipVisible ? <BsInfoCircleFill /> : <BsInfoCircle />}
                {isTooltipVisible && <div className="absolute bottom-full left-full transform translate-x-[-100px] w-[250px] ">
                    <div className='mb-2 p-3 bg-secondary text-white'>
                        {text}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Tooltip;