import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { FieldProps } from "../../pages/Page/Field";
import { PageImageType } from "../../../types";
interface Props {
    field: FieldProps["field"];
    val: any;
}
export default function PageImage({ field, val }: Props) {

    if (field.imageFile?.childImageSharp) {
        const image = getImage(field.imageFile as IGatsbyImageData);
        const val = JSON.parse(field.value).value;

        return (
            <>
                <GatsbyImage image={image as IGatsbyImageData} alt={val?.title} />
            </>
        );
    }

    const src = field.imageFile?.publicURL || val?.src;

    return (
        <img
            src={src}
            alt={field.value}
            style={{ width: "6rem" }}
            width={"96px"}
            height={"96px"}
        />
    );
}
