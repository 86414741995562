import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

interface RatingsStarsProps {
    rating: number;
    size?: number;
    maxRating?: number;
}

const RatingsStars: React.FC<RatingsStarsProps> = ({ rating, size, maxRating = 5 }) => {

    const roundedRating: number = Math.round(rating * 2) / 2;

    // Generate an array of star icons based on the rounded rating
    const stars: JSX.Element[] = [];
    for (let i = 1; i <= maxRating; i++) {
        if (i <= roundedRating) {
            // Full star if rating is equal or greater than current index
            stars.push(<FaStar size={size} key={i} className="text-yellow-400" />);
        } else if (i - 0.5 === roundedRating) {
            // Half star if rating is exactly half a step below the current index
            stars.push(<FaStarHalfAlt size={size} key={i} className="text-yellow-400" />);
        } else {
            // Empty star for all other cases
            stars.push(<FaStar size={size} key={i} className="text-gray-400" />);
        }
    }

    return <div className="flex flex-row items-center space-x-1 ">{stars}</div>;
};

export default RatingsStars;
