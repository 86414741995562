import { DateTime } from 'luxon';
import React, { memo, useEffect, useMemo } from 'react'
import { useLayout } from '../../layouts/Layout/hook';

const TrustpilotWidget = memo((props: React.HTMLAttributes<HTMLDivElement>) => {

    const key = `trustpilot-widget-${DateTime.local().toISO()}`;

    const widgetRef = React.createRef<HTMLDivElement>();

    const { reviewPlatform } = useLayout();

    useEffect(() => {

        if (!widgetRef.current) {
            return;
        }

        const Trustpilot = (window as any).Trustpilot;

        if (!Trustpilot) {
            return;
        }

        Trustpilot.loadFromElement(widgetRef.current, true);

    }, [widgetRef, props["data-sku"]]);

    const businessUnitId = useMemo(() => {
        if (!reviewPlatform.config) {
            return;
        }

        return reviewPlatform.config.find((c) => c.property === "businessUnitId")?.value;

    }, [reviewPlatform.config]);

    const trustpilotUrl = useMemo(() => {
        if (!reviewPlatform.config) {
            return;
        }

        return reviewPlatform.config.find((c) => c.property === "trustpilotUrl")?.value;

    }, [reviewPlatform.config]);

    if (!businessUnitId) {
        return null;
    }

    return (
        <div ref={widgetRef}
            key={key}
            {...props}
            data-businessunit-id={businessUnitId}
        >
            {trustpilotUrl && (<a href={trustpilotUrl} target="_blank" rel="noopener">Trustpilot</a>)}
        </div>
    )
});

export default TrustpilotWidget;