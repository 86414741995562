import React, { useState } from 'react';
import { BsCheckSquare, BsCheckSquareFill, BsSquare } from 'react-icons/bs';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  textSize?: string;
  disabled?: boolean;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, className, onChange, textSize = "text-lg md:text-xl", disabled = false }) => {

  const handleCheckboxChange = () => {

    if (disabled) return;

    onChange(!checked);
  };

  return (
    <label className={`flex items-center -mx-1 ${!disabled && "cursor-pointer"} ${textSize} ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="hidden form-checkbox mx-1 h-4 w-4 text-indigo-600"
        disabled={disabled}
      />
      <span className={`text-3xl mr-2 ${disabled && "opacity-50"}`}>
        {checked ? <BsCheckSquareFill /> : <BsSquare />}
      </span>
      <span className="mx-1 text-gray-700">{label}</span>
    </label>
  );
};

export default Checkbox;
