import React from 'react'
import TrustpilotWidget from '.'

type Props = {
    locale?: string;
    templateId?: string;
    theme?: string;
    width?: string;
    height?: string;
}

export default function TrustpilotCarousel({
    locale = "en-GB",
    theme = "light",
    width = "100%",
    height = "140px"
}: Props) {
    return (
        <TrustpilotWidget
            className="trustpilot-widget"
            data-locale={locale}
            data-template-id="53aa8912dec7e10d38f59f36"
            data-theme={theme}
            data-style-height={height}
            data-style-width={width}
            data-stars="4,5"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-style-alignment="center"
        />
    )
}
