import React, { useMemo } from "react";
import { AssetType, ProductType } from "../../../../../types";
import { getProductImage } from "../../../../../utils/image";
import { BsArrowLeft, BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";

import { GatsbyImage, GatsbyImageProps, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ProductDetailAccreditations from "../Accreditations";

interface Props {
    product: ProductType;
    images: AssetType[];
}

const DEFAULT_G_IMAGE_PROPS: Partial<GatsbyImageProps> = {
    style: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    imgStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: "0.5rem",
    },
};

export default function ProductDetailGallery({
    product,
    images,
}: Props) {
    const windowSize = useWindowSize();

    const isMobileView = useMemo(() => {
        return windowSize?.width ? windowSize.width < 768 : false;
    }, [windowSize]);

    if (!isMobileView) {
        return <DesktopGallery product={product} images={images} />;
    }

    return <MobileGallery product={product} images={images} arrows={false} />
}

const DesktopGallery = ({ images, product }: Props) => {

    const first = images[0];

    const second = images[1];
    const third = images[2];

    const rest = images.slice(3);

    return (
        <div className="space-y-3">

            {first?.imageFile &&
                <div className="relative w-full">
                    <div className="relative w-full pb-[100%]" />
                    <GatsbyImage
                        image={getImage(first.imageFile) as IGatsbyImageData}
                        alt={getAlt(second, product, 0)}
                        {...DEFAULT_G_IMAGE_PROPS}
                    />
                    <div className="
                        absolute w-full bottom-0 left-0
                        p-3
                    ">
                        <ProductDetailAccreditations
                            accreditations={product.accreditations}
                        />
                    </div>
                </div>
            }

            {(second || third) && (
                <div className="flex items-center space-x-3">

                    {second?.imageFile &&
                        <div className="relative w-1/2">
                            <div className="relative w-full pb-[100%]" />
                            <GatsbyImage
                                image={getImage(second.imageFile) as IGatsbyImageData}
                                alt={getAlt(second, product, 1)}
                                {...DEFAULT_G_IMAGE_PROPS}
                            />
                        </div>
                    }

                    {third?.imageFile &&
                        <div className="relative w-1/2">
                            <div className="relative w-full pb-[100%]" />
                            <GatsbyImage
                                image={getImage(third.imageFile) as IGatsbyImageData}
                                alt={getAlt(third, product, 2)}
                                {...DEFAULT_G_IMAGE_PROPS}
                            />
                        </div>
                    }
                </div>
            )}

            {/* Rest Slider */}
            <MobileGallery images={rest} product={product} />

        </div>
    );
}

const MobileGallery = ({ images, product, arrows = true }: Props & { arrows?: boolean }) => {
    return <div
        style={{
            overflow: "hidden",
            borderRadius: "0.5rem",
        }}
    >
        <Splide
            options={{
                autoplay: false,
                type: "slide",
                arrows: arrows,
                pagination: true,
            }}
        >
            {images?.map((image, index) => {

                if (!image.imageFile) return null;

                return <SplideSlide className={""}>
                    <div className="relative w-full">
                        <div className="relative w-full pb-[100%]" />
                        <GatsbyImage
                            key={"MOBILE_IMAGE_" + image.id}
                            image={getImage(image.imageFile) as IGatsbyImageData}
                            alt={getAlt(image, product, index)}
                            {...DEFAULT_G_IMAGE_PROPS}
                        />
                    </div>
                </SplideSlide>
            })}
        </Splide>
    </div>
}

const getAlt = (image: AssetType, product: ProductType, index: number, fallback?: string) => {
    return image.altTag || image.title || image.name || `${product?.SKUName} Image ${index + 1}` || fallback || "";
}