import React from "react";
import { ProductDetailProps } from "../../websiteTemplates/Default/ProductDetail";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { useSalesChannel } from "../../../context/SalesChannelContext";

export default function ProductDetail(props: ProductDetailProps) {

    const salesChannel = useSalesChannel();

    return <GetComponent component="ProductDetail" template={salesChannel?.websiteTemplate} props={props} />
}
