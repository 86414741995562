import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { navigate } from "gatsby-link";
import { isEmpty } from "../../../utils/string";
import { salesChannel } from "../../../config";
import { answerCustomChallenge, signIn, signUp } from "../../../services/cognito";

import EcommerceApi from "../../../services/ecommerceApi";
import { getUser, updateCustomer } from "../../../store/auth";
import { useSalesChannel } from "../../../context/SalesChannelContext";

export type AuthModes = "SIGN_IN" | "VERIFY_CODE" | "SIGN_UP" | "GUEST";

export function useLoginSignUp(location?: any, isRedirect: boolean = true) {
    const state = location?.state;
    const redirectUrl = state?.redirectUrl;

    const user = useSelector((state: RootState) => state?.auth?.user);
    const salesChannel = useSalesChannel();

    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<AuthModes>("SIGN_IN");

    const [data, setData] = useState<{
        email: string;
        firstName: string;
        lastName: string;
        code: string;
        phoneNumber: string;
    }>({
        email: "",
        firstName: "",
        lastName: "",
        code: "",
        phoneNumber: ""
    });

    const [error, setError] = useState<string | null>(null);

    if (user && isRedirect) {
        navigate(redirectUrl ?? "/account");
    }

    const getSecretCode = async (e?: any) => {
        if (e) e.preventDefault();

        setLoading(true);

        if (isEmpty(data.email)) {
            setLoading(false);
            return;
        }

        setError(null);

        switch (mode) {
            case "SIGN_IN":
                const { user, customer, apiError } = await signIn(data.email);

                if (apiError) {
                    setError("An error occurred, please try again later");
                    setLoading(false);
                    return;
                }

                if (user && customer) {
                    setMode("VERIFY_CODE");
                }

                if (!user && !customer) {
                    setMode("SIGN_UP");
                }

                if (customer && !user) {
                    await signUpAndSignIn(
                        data.email,
                        customer.firstName,
                        customer.lastName,
                        customer.phoneNumber
                    );
                }

                break;
            case "VERIFY_CODE":
                try {
                    await signIn(data.email);
                    setMode("VERIFY_CODE");
                } catch (error) {
                    console.error(error);
                }
                break;
            case "SIGN_UP":
                await signUpAndSignIn(data.email, data.firstName, data.lastName, data.phoneNumber);
                break;
        }

        setLoading(false);
    };

    const signUpAndSignIn = async (
        email: string,
        firstName: string,
        lastName: string,
        phoneNumber: string
    ) => {
        try {
            if (!isEmpty(firstName) && !isEmpty(lastName)) {
                await signUp(email, firstName, lastName, phoneNumber, salesChannel);

                await signIn(email);

                setMode("VERIFY_CODE");
            }
        } catch (error) {
            setError("User already exists, please login");
        }
    };

    const setValue = (field: string, value: any) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const verify = async (): Promise<boolean> => {
        const code = data.code;
        setLoading(true);

        if (!isEmpty(code)) {
            let res = await answerCustomChallenge(code);
            if (!res) {
                setError("Incorrect code entered,please check your emails.");
                setLoading(false);
                return false;
            }

            if (res) {
                let loggedInCustomer;
                const customerLookup = await EcommerceApi.getCustomerByEmail(data.email);

                if (!customerLookup || customerLookup.length === 0) {
                    let newCustomer = {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phoneNumber: "",
                        cognitoUser: `${salesChannel}-${data.email}`
                    };

                    const response = await EcommerceApi.createCustomer(newCustomer);

                    if (response) {
                        loggedInCustomer = response;
                    }
                } else {
                    const customer =
                        customerLookup && customerLookup.length > 0 ? customerLookup[0] : null;
                    loggedInCustomer = customer;
                }

                await dispatch(updateCustomer(loggedInCustomer));
                await dispatch(getUser());

                if (isRedirect) {
                    navigate(redirectUrl ?? "/account");
                }

                setLoading(false);
                return true;
            }
        }
        setLoading(false);
        return false;
    };

    return {
        loading,
        mode,
        data,
        error,
        setError,
        setValue,
        setMode,
        getSecretCode,
        verify
    };
}
