/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { useEffect, ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { fetchAllCurrencyCodes } from "../../../store/currencyCode";
import CookieBanner from "../../seo/CookieBanner";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import CustomBreadcrumb from "../../shared/CustomBreadcrumb";
import SalesChannelContext from "../../../context/SalesChannelContext";
import { ConsentForm } from "../../seo/CookieBanner/Consent";

interface Props {
    children: ReactNode;
    pageTemplate?: any;
    className?: string;
}

const EmptyLayout = ({ children, pageTemplate, className }: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode
    );

    useEffect(() => {
        if (!currencyCode) {
            dispatch(fetchAllCurrencyCodes());
        }
    }, []);

    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                websiteTemplate
                email
                logo
                icon
                phone
                address
                metadata
                type

                defaultPostcode
                defaultCountry
                primaryColour
                secondaryColour

                donations {
                    id
                    name
                    desc
                    type
                    value
                    image
                    enabled
                    salesChannel
                }
            }
        }
    `);

    const { salesChannel } = data;

    return (
        <>
            <SalesChannelContext.Provider value={{ salesChannel }}>
                <main className={`text-secondary ${className}`}>{children}</main>
                <ConsentForm />
            </SalesChannelContext.Provider>
        </>
    );
};

export default EmptyLayout;
