'use client';

import React, { useCallback, useEffect } from 'react'
import Loading from '../../components/shared/Loading';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { airwallexCheckPaymentIntent, getOrder } from '../../services/ecommerceApi';
import SiteModal from '../../components/shared/SiteModal';
import Button from '../../components/shared/Button';
import SalesChannelContext from '../../context/SalesChannelContext';

const TRY_AGAIN = 10;
const WAIT_TIME = 500;

export default function redirect() {
    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                websiteTemplate
                email
                logo
                icon
                phone
                address
                metadata
                type

                defaultPostcode
                defaultCountry
                primaryColour
                secondaryColour

                donations {
                    id
                    name
                    desc
                    type
                    value
                    image
                    enabled
                    salesChannel
                }
            }
        }
    `);

    const { salesChannel } = data;

    const orderId = useSelector((state: RootState) => state.order?.order?.id);
    const [modalContent, setModalContent] = React.useState<string | undefined>();

    const checkOrder = useCallback(async (index = 1) => {

        if (index > TRY_AGAIN) {
            navigate("/order/" + orderId);
            return;
        }

        if (!orderId) {
            return;
        }

        const order = await getOrder(orderId);

        if (order?.status === "shoppingCart") {
            setTimeout(() => {
                checkOrder(index + 1);
            }, WAIT_TIME);
            return;
        }

        navigate("/order/" + orderId);
    }, [orderId]);

    const checkPaymentIntent = useCallback(async () => {
        if (!orderId) {
            setModalContent("missingOrderId");
            return;
        };

        // check airwallex paymentintent
        const hasBeenProcessed = await airwallexCheckPaymentIntent(orderId);

        if (!hasBeenProcessed) {
            setModalContent("notBeenProcessed");
            return;
        }

        checkOrder();
    }, [orderId, checkOrder])

    useEffect(() => {
        setTimeout(() => {
            checkPaymentIntent();
        }, WAIT_TIME);
    }, [checkPaymentIntent])

    return (
        <SalesChannelContext.Provider value={{ salesChannel }}>
            <SiteModal>
                <div className='text-center'>
                    <h2 className='text-xl font-bold'>Payment Details</h2>
                    {!modalContent && <p>Please wait while we process your payment.</p>}
                    {modalContent === "missingOrderId" && <p>Order not found. Please try again later.</p>}
                    {modalContent === "notBeenProcessed" && <div className='flex flex-col gap-2'><p>We could not find your payment.</p>
                        <div className='flex flex-row gap-2 justify-center'>
                            <Button onClick={() => { navigate("/checkout") }}>Return to Shopping Cart</Button>
                        </div>
                    </div>}

                </div>
            </SiteModal >
        </SalesChannelContext.Provider>
    )
}
