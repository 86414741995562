export const firstOrDefault = (array: any[], predicate?: (item: any) => any[]): null | any[] => {
    if (!array || !array.length) {
        return null;
    }
    if (!predicate) {
        return array[0];
    }
    return array.find(predicate) || null;
};

export const sortByKey = <T>(data: T[], key: keyof T) => {
    let newData = [...data];
    newData.sort((a: T, b: T) => {
        return (a[key] as number) - <number>b[key];
    });
    return newData;
};

export const arrayToMap = <T>(data: T[], key: keyof T) => {
    let newMap = {} as Record<string, T>;
    for (const item of data) {
        if (!newMap[item[key] as string]) {
            newMap[item[key] as string] = item;
        }
    }
    return newMap;
};
