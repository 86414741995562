import React from 'react'
import { ProductType } from '../../../../../types'
import { usePageContext } from '../../../../pages/context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useSalesChannel } from '../../../../../context/SalesChannelContext';
import { jsonParseString } from '../../../../../utils/string';
interface Props {
    product: ProductType
    from?: number
    to?: number
}
export default function ProductUSPs({ product, from = 0, to = 3 }: Props) {

    const { contentDict } = usePageContext();
    const salesChannel = useSalesChannel();

    const productUsps = jsonParseString((product.uniqueSellingPoints || "{}"));
    const usps = productUsps?.[salesChannel.id] || [];

    const items = usps.map((usp) => contentDict?.[usp]).splice(from, to);

    return (
        <div className='flex flex-wrap -mx-1 lg:-mx-2'>
            {items.map((item) => (
                <div className='
                    w-1/3
                    px-1
                    lg:px-2
                '>
                    <div className='p-3 bg-gray-100 h-full flex items-center'>
                        <div className="
                            flex flex-wrap items-center text-center space-y-1 w-full
                            lg:flex-nowrap lg:space-x-3 lg:text-left lg:space-y-0
                        ">
                            <div className='
                                w-[35px] mx-auto
                                md:w-[30px]
                                lg:w-[50px] lg:mx-0 mb-2 lg:mb-0'
                            >
                                {item?.imageFile?.childImageSharp &&
                                    <GatsbyImage
                                        image={getImage(item.imageFile) as any}
                                        alt={item.Heading}
                                    />
                                }
                            </div>
                            <div className='
                                w-full text-sm font-bold leading-tight
                                lg:w-auto
                            '>
                                {item?.Heading}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
