export const getPaymentGatewayConfig = paymentGateway => {
    if (paymentGateway) {
        const processedConfigData: any = {};
        if (paymentGateway.config) {
            for (let k = 0; k < paymentGateway.config.length; k++) {
                const currConfig = paymentGateway.config[k];
                processedConfigData[currConfig.property] = currConfig.value;
            }
        }
        return processedConfigData;
    }

    return null;
};
