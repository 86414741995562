import React, { useContext } from "react";

import { useMemo, useState, useEffect, useCallback, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useSalesChannel } from "../SalesChannelContext";
import { ISalesChannel } from "../../services/ecommerceApi/SalesChannel/types";
import { getOrder } from "../../store/order";

type IEcommerceContext = {
    firstLoaded: boolean;
    postcode: string | undefined;
    setPostcode: (postcode: string) => void;
    editPostcode: boolean;
    setEditPostcode: (edit: boolean) => void;
    setSalesChannel: (salesChannel: ISalesChannel) => void;
}

// ============== CONTEXT ==============
export const EcommerceContext = createContext({} as IEcommerceContext);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
};

export function EcommerceProvider({ children }: Props) {

    const myAddresses = useSelector((state: RootState) => state.order.addresses);
    const orderAddresses = useSelector((state: RootState) => state.order.order?.shippingAddress);
    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const dispatch = useDispatch<AppDispatch>();

    const [firstLoaded, setFirstLoaded] = React.useState(false);
    const [salesChannel, setSalesChannel] = useState<ISalesChannel | undefined>(undefined);
    const [internalPostcode, setInternalPostcode] = useState<string | undefined>(undefined);
    const [editPostcode, setEditPostcode] = useState(false);

    const refreshOrder = useCallback(async () => {
        if (!orderId || !dispatch || !getOrder) {
            return;
        }
        await dispatch(getOrder(orderId));
        setFirstLoaded(true);
    }, [orderId, dispatch, getOrder, setFirstLoaded]);

    useEffect(() => {
        refreshOrder();
    }, [refreshOrder]);

    const postcode = useMemo(() => {

        if (internalPostcode) {
            return internalPostcode;
        }

        if (orderAddresses?.length) {
            return orderAddresses[0].postCode;
        }

        if (myAddresses.length) {
            return myAddresses[0].postCode;
        }

        return salesChannel?.defaultPostcode;
    }, [salesChannel?.defaultPostcode, internalPostcode, myAddresses]);

    const initialize = useCallback(async () => {
        const lsPostcode = localStorage.getItem("ecomHelper:postcode");
        if (lsPostcode) {
            setInternalPostcode(lsPostcode);
        }
    }, []);

    const setPostcode = useCallback((postcode: string) => {
        setInternalPostcode(postcode);
    }, [postcode]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    useEffect(() => {
        if (internalPostcode) {
            localStorage.setItem("ecomHelper:postcode", internalPostcode);
        }
    }, [internalPostcode]);

    const memoizedValue = useMemo(
        () =>
        ({
            firstLoaded,
            postcode,
            setPostcode,
            editPostcode,
            setEditPostcode,
            setSalesChannel
        } as IEcommerceContext),
        [postcode, setPostcode, internalPostcode, editPostcode, setEditPostcode, setSalesChannel]
    );

    return (
        <EcommerceContext.Provider value={memoizedValue}>
            {children}
        </EcommerceContext.Provider>
    );
}

// ============== HOOK ==============
export const useEcommerceHelper = () => {
    const context = useContext(EcommerceContext);
    if (!context)
        throw new Error("useEcommerceHelper context must be use inside EcommerceProvider");
    return context;
};