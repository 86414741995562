import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ReviewType } from "../../../types";
import { ProductJsonLd } from "gatsby-plugin-next-seo";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { ReviewListProps } from "../../websiteTemplates/Default/ReviewsList";
import { useSalesChannel } from "../../../context/SalesChannelContext";

const ReviewList = (props: ReviewListProps) => {
    const { websiteTemplate } = useSalesChannel();
    return <GetComponent template={websiteTemplate} component="ReviewList" props={props} />;
}
export default ReviewList;
