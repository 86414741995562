import React, { useEffect } from "react";
import {
    ProductCategoryType,
    ProductType
} from "../../../../types";
import { usePageContext } from "../../../pages/context";
import ProductDetailView from "./Detail";

export interface ProductDetailProps {
    productCategory: ProductCategoryType;
    defaultProduct: ProductType;
    slug: string;
}

export default function ProductDetail({
    productCategory,
    defaultProduct,
    slug
}: ProductDetailProps) {

    const { productDetailProduct, setProductDetailProduct } = usePageContext();

    useEffect(() => {
        if (defaultProduct) {
            setProductDetailProduct(defaultProduct);
        }
        return () => {
            setProductDetailProduct(undefined);
        }
    }, [defaultProduct]);

    if (!productDetailProduct) {
        return <></>;
    }

    return <ProductDetailView key={productCategory.id} productCategory={productCategory} slug={slug} selectedProduct={productDetailProduct} />;
}

export const ProductDetailHeading = ({ children }: { children: React.ReactNode }) => (
    <h2 className="text-3xl uppercase text-extrabold mb-5">{children}</h2>
);
