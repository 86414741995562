import React, { ReactElement, useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import SiteModal from "../../shared/SiteModal";

type SuccessModalProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    message: string;
};

const SuccessModal = ({ show, setShow, message }: SuccessModalProps): ReactElement | undefined => {
    const [dismissDelay, setDismissDelay] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (show) {
            if (dismissDelay) clearTimeout(dismissDelay);
            setDismissDelay(setTimeout(() => {
                setShow(false);
            }, 2000));
        }
    }, [show]);

    if (!show) return undefined;

    return <SiteModal>
        <div className="flex justify-center p-10 flex-col">
            <BsCheckCircle className="animate-jump-in mx-auto mb-6" size={"100px"} />
            <p className="mx-auto">{message}</p>
        </div>
    </SiteModal>
};

export default SuccessModal;