import React, { ChangeEvent, DetailedHTMLProps } from "react";


interface Props {
    value: string;
    label?: string;
    className?: string;
    placeholder?: string;
    onChange: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error?: boolean;
    multiline?: boolean;
    maxLength?: number;
    readonly?: boolean;
    disabled?: boolean;
    name?: string;
    type?: string;
    autocomplete?: string;
}

export default function TextInput({
    value,
    label,
    className,
    placeholder,
    onChange,
    error = false,
    multiline = false,
    maxLength,
    readonly = false,
    disabled = false,
    name,
    type = "text",
    autocomplete
}: Props) {
    const labelClassName = error
        ? "inline-block text-base font-bold mb-1 text-red-500 uppercase"
        : "inline-block text-base font-bold mb-1 uppercase";

    let inputClassName = error
        ? "p-2 px-4 text-lg border border-red-500 bg-gray-100 w-full"
        : "p-2 px-4 text-lg border border-gray-100 bg-gray-100 w-full";

    const InputComponent = multiline ? "textarea" : "input";

    if (disabled) {
        inputClassName = inputClassName + " opacity-75";
    }

    return (
        <div className={className}>
            <label className={labelClassName}>{label}</label>
            <InputComponent
                value={value}
                onChange={onChange}
                type={type}
                maxLength={maxLength}
                placeholder={placeholder}
                className={inputClassName}
                readOnly={readonly}
                name={name}
                disabled={disabled}
                autoComplete={autocomplete}
                rows={multiline ? 5 : undefined}
            />
        </div>
    );
}
