import React, { useEffect } from "react";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import Layout from "../components/layouts/Layout";
import Button from "../components/shared/Button";
import { jsonParseString } from "../utils/string";
export default function ErrorPage() {

    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        const url = window.location.href;
        const lowercase = url.toLowerCase();
        if (url != lowercase) {
            navigate(lowercase);
        }
        setLoaded(true);
    }, []);

    const browser = typeof window !== "undefined" && window;
    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                email
                logo
                phone
                address
                type
                metadata
            }
        }
    `);

    if(!loaded) return <></>;

    const message = jsonParseString(data?.salesChannel?.metadata)?.settings?.page404Text || "Something went wrong when trying to find this page.";
    return <Layout>
        <section className="min-h-[50vh] py-10 flex items-center">
            <div className="container max-w-screen-lg mx-auto px-3 text-center text-secondary">
                <div className="text-8xl font-bold mb-4">
                    404
                </div>
                <h1 className="text-[30px] font-bold mb-5">
                    Page not found
                </h1>
                <p className="text-xl font-normal normal-case text-[28px]">
                    {message}
                </p>
                <div className="flex justify-center mt-5">
                    <Button onClick={() => navigate("/")}>
                        Back to Home
                    </Button>
                </div>
            </div>
        </section>
    </Layout>;
}
