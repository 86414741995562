import React from "react";
import MyAccount from "../../components/ecommerce/MyAccount";
import { AccountTabTypes } from "../../components/ecommerce/MyAccount/AccountTabs";

type Props = {
    tab?: string;
}
export default function OrderSuccess({ tab }: Props) {

    let defaultTab: AccountTabTypes | undefined = undefined;

    switch (tab) {
        case "orders":
            defaultTab = AccountTabTypes.ORDERS;
            break;
        case "addresses":
        case "address-book":
            defaultTab = AccountTabTypes.ADDRESSES;
            break;
        case "referrals":
        case "referral-code":
            defaultTab = AccountTabTypes.CREDIT;
            break;
    }

    return <MyAccount defaultTab={defaultTab} />;
};