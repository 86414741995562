import React, { useEffect, useMemo, useState } from "react";
import {
    AssetType,
    ProductCategoryType,
    ProductDataType,
    ProductTaxCodeType,
    ProductType
} from "../../../../../types";
import ProductDetailGallery from "../ProductDetailGallery";
import Button from "../../../../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { addToCart } from "../../../../../store/order";
import { graphql, navigate, useStaticQuery } from "gatsby";
import OrderInTheNext from "../../../../ecommerce/OrderInTheNext";
import { formatPrice, getPriceAsNumber, getPriceIncTax, getPriceToShow, round2dp } from "../../../../../utils/currency";
import EcommerceApi from "../../../../../services/ecommerceApi";
import StockIndicator from "../../../../ecommerce/StockIndicator";
import { sortByKey } from "../../../../../utils/array";
import { useSalesChannel } from "../../../../../context/SalesChannelContext";
import { extractScCurrValueFromProductAttr } from "../../../../../utils/string";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import ReviewSummary from "../../../../ecommerce/ReviewSummary";
import OrderInTheNextProduct from "../../../../ecommerce/OrderInTheNextProduct";
import { useLayout } from "../../../../layouts/Layout/hook";
import ProductSchema from "../ProductSchema";
import { usePageContext } from "../../../../pages/context";
import { BsBasket } from "react-icons/bs";
import ProductDetailAccordion from "../Accordion";
import SelectProductItem from "../SelectProductItem";
import ProductUSPs from "../USPs";
import { useTrackingContext } from "../../../../../context/tracking";
import { maximumQuantityToAddHelper } from "../../ProductCategoryListItem/maximumQuantityToAddWithoutCurrentShoppingCart";

export interface ProductDetailProps {
    selectedProduct: ProductType;
    productCategory: ProductCategoryType;
    slug: string;
}

export default function ProductDetailView({
    selectedProduct,
    productCategory,
    slug
}: ProductDetailProps) {


    const dispatch = useDispatch<AppDispatch>();

    const salesChannel = useSalesChannel();
    const { reviewPlatform } = useLayout();

    const { productCategories, setProductDetailProduct } = usePageContext();
    const { viewedProductEvent } = useTrackingContext();

    const order = useSelector((state: RootState) => state.order.order);
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );

    const [taxCodes, setTaxCodes] = useState([]);

    const [sortedProducts, setSortedProduct] = React.useState<ProductType[]>(
        sortByKey(productCategory?.products, "sort") || []
    );

    const [selectedProductData, setSelectedProductData] = React.useState<ProductDataType | null>(
        null
    );
    const [adding, setAdding] = React.useState(false);
    const [buyNowing, setBuyNowing] = React.useState(false);

    let productImage = selectedProduct?.imageFile ?? null;

    const [imagePreview, setImagePreview] = React.useState<Partial<
        ProductType["assets"][0]
    > | null>(productImage);

    const handleAddToCartClick = async () => {
        setAdding(true);

        const orderId = order ? order.id : null;
        const params = {
            orderId: orderId,
            skuId: selectedProduct.id,
            quantity: 1
        };
        await dispatch(addToCart(params));
        setAdding(false);
    };

    const handleBuyNowClick = async () => {
        setBuyNowing(true);
        const orderId = order ? order.id : null;
        const params = {
            orderId: orderId,
            skuId: selectedProduct.id,
            quantity: 1
        };
        await dispatch(addToCart(params));
        navigate("/cart");
    };

    const loadProductData = async () => {
        if (selectedProduct) {
            const productId = selectedProduct.product || selectedProduct.id;

            const [product, taxCodes] = await Promise.all([
                EcommerceApi.getProductData(productId),
                EcommerceApi.getProductTaxCodesData(productId)
            ]);

            setTaxCodes(taxCodes);
            setSelectedProductData(product || null);
        }
    };

    const handleProductClick = (product: ProductType) => (e: any) => {
        let childSlug = (product.SKUName || "").toLowerCase();
        childSlug = childSlug.replace(/ /g, "-");
        childSlug = childSlug.replace("---", "-");

        setProductDetailProduct(product);
        // set path 
        window.history.pushState({}, "", `/${slug}/${childSlug}`);
    };

    const windowSize = useWindowSize();
    const isMobileView = useMemo(() => {
        return windowSize?.width ? windowSize.width < 768 : false;
    }, [windowSize]);

    useEffect(() => {
        const load = async () => {
            const data = await Promise.all(
                (sortByKey(productCategory?.products, "sort") ?? []).map(async product => {
                    const productData = (await EcommerceApi.getProductData(
                        product.id
                    )) as ProductDataType;

                    const { currentPrice, listPrice, stock } = productData;

                    return {
                        ...product,
                        currentPrice,
                        listPrice,
                        stock
                    };
                })
            );

            setSortedProduct(data);
        };

        load();
    }, [productCategory?.products ?? [], selectedProduct]);

    useEffect(() => {
        loadProductData();
    }, [selectedProduct, isMobileView]);

    const pricing = useMemo(() => {

        if (!selectedProductData) return null;

        const priceVal = getPriceAsNumber(selectedProductData.currentPrice, salesChannel, currencyCode);
        const listPriceVal = selectedProductData.listPrice ? getPriceAsNumber(selectedProductData.listPrice, salesChannel, currencyCode) : undefined;

        const price = formatPrice(
            priceVal,
            currencyCode
        );

        const listPrice = listPriceVal ? formatPrice(listPriceVal) : undefined;

        return {
            priceVal,
            price,
            listPriceVal,
            listPrice,
        };

    }, [selectedProductData, taxCodes, currencyCode, salesChannel.metadata]);

    let shortDescription = useMemo(
        () =>
            extractScCurrValueFromProductAttr(
                selectedProduct.shortDescription,
                salesChannel?.id,
                "shortDescription"
            ),
        [salesChannel?.id, selectedProduct.shortDescription]
    );

    const reviewSummary = useMemo(() => {
        return selectedProductData?.reviewSummary
    }, [selectedProductData?.reviewSummary]);

    const imageSlider = useMemo(() => {

        const categoryImages = productCategories.find(
            category => category.id === productCategory.id
        )?.images || [];

        const productImages: AssetType[] = (selectedProduct.assets || []).sort(
            (a, b) => (a.sort || 0) - (b.sort || 0)
        );

        return [
            ...categoryImages,
            ...productImages
        ];

    }, [productCategories, productCategory?.id, selectedProduct?.assets]);

    const maximumQuantityToAdd = useMemo(() => {
        return maximumQuantityToAddHelper(order, selectedProduct?.id, selectedProductData);
    }, [selectedProductData, selectedProduct, order]);

    useEffect(() => {
        if (!pricing) return;

        viewedProductEvent({
            ...selectedProduct,
            salesChannel: salesChannel,
            currentPrice: pricing.priceVal,
            currencyCode
        });
    }, [selectedProduct.id, pricing]);

    return (
        <section className="py-5 md:py-10">

            <div className="container max-w-[1150px] mx-auto px-3 overflow-hidden">

                <div className="flex flex-wrap items-start md:-mx-10">

                    {/* Left */}
                    <div className="w-full md:w-6/12 md:px-10">
                        <div className="max-w-[450px] mx-auto">
                            <ProductDetailGallery
                                product={selectedProduct}
                                images={imageSlider}
                            />
                        </div>
                    </div>

                    {/* Right */}

                    <div className="
                            w-full space-y-5
                            md:w-6/12 md:px-10
                            ">

                        {/* Review Summary */}
                        <div className="relative left-[-6px]">
                            <ReviewSummary
                                product={selectedProduct}
                                reviewPlatform={reviewPlatform}
                                reviewSummary={reviewSummary}

                                Wrapper={({ children }) => <section className="pt-5 space-y-2">{children}</section>}
                                width="183px"
                                onClick={() => {
                                    document.getElementById("ProductDetailReview")?.scrollIntoView({ behavior: "smooth" });
                                }}
                            />
                        </div>

                        {/* Heading */}
                        <h1 className="md:text-3xl">
                            {selectedProduct.SKUName}
                        </h1>

                        <p className="md:text-lg font-bold">
                            {shortDescription}
                        </p>

                        {/* Product USPS */}
                        <ProductUSPs product={selectedProduct} />

                        {/* Select Product */}
                        <div>
                            <h2 className="text-xl font-bold">Select your pack:</h2>
                            <div className="
                            flex flex-wrap py-2 -mx-1
                            md:block md:space-y-3
                            ">
                                {sortedProducts.map(product => <SelectProductItem
                                    key={product.id}
                                    selectedProduct={selectedProduct}
                                    product={product}
                                    handleProductClick={handleProductClick}
                                />)}
                            </div>
                        </div>

                        <StockIndicator
                            stock={selectedProductData?.stock}
                            loading={selectedProductData == null}
                        />

                        <div className="
                                flex items-stretch
                                bg-primary
                                rounded-md
                                overflow-hidden
                            ">
                            <div className="flex-1">
                                <Button
                                    padding="py-3 px-4"
                                    className="w-full"
                                    textSize="text-lg"
                                    loader={adding}
                                    onClick={handleAddToCartClick}
                                    disabled={1 > maximumQuantityToAdd}
                                >
                                    <div className="flex items-center space-x-3">
                                        <BsBasket />
                                        <span>
                                            Add to Cart
                                        </span>
                                    </div>
                                </Button>
                            </div>
                            <div className="
                                    flex items-center
                                    p-1 px-5 text-lg font-bold
                                    text-secondary
                                    "
                                style={{
                                    backgroundColor: "rgba(0,0,0,0.1)"
                                }}
                            >
                                {pricing?.price}
                            </div>
                        </div>

                        <OrderInTheNextProduct productId={selectedProduct.id} />

                        <ProductUSPs product={selectedProduct} from={3} to={6} />

                        <ProductDetailAccordion
                            product={selectedProduct}
                        />

                    </div >

                </div >

            </div >

            <ProductSchema product={selectedProduct} pricing={pricing} reviewSummary={reviewSummary} />

        </section >
    );
}

export const ProductDetailHeading = ({ children }: { children: React.ReactNode }) => (
    <h2 className="text-3xl uppercase text-extrabold mb-5">{children}</h2>
);
