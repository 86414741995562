import React from "react";
import { navigate } from "gatsby";
import { LinkItemType } from "../../../types";
import Button from "../../shared/Button";

const LinkItem = ({ linkItem }: { linkItem: LinkItemType }) => {

    if (!linkItem || !(linkItem?.src)) {
        return <></>;
    }

    const handleButtonClick = () => {
        if (linkItem.target === "_blank") {
            window.open(linkItem.src, "_blank");
        } else {
            navigate(linkItem.src);
        }
    };

    return (
        <Button onClick={handleButtonClick}>
            {linkItem.text}
        </Button>
    );
};

export default LinkItem;
