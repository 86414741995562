import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import CheckoutSection from "../CheckoutSection";
import { setStep, addEditOrderNotes } from "../../../../store/order";
import ShipmentsDisplay from "./Shipments";
import Button from "../../../shared/Button";
import OrderNotes from "../Billing/OrderNotes";
import SiteModal from "../../../shared/SiteModal";
import { Link } from "gatsby";
import { getAndValidateOrder as stateGetAndValidateOrder } from "../../../../store/order";

const ORDER_NOTES_MAX_LENGTH = 255;

interface Props {
    temporaryDisable: string | undefined;
    setTemporaryDisable: (value: string | undefined) => void;
}

export default function ReviewDelivery({ temporaryDisable, setTemporaryDisable }: Props) {
    const dispatch = useDispatch<any>();

    const orderStore = useSelector((state: RootState) => state.order);
    const user = useSelector((state: RootState) => state.auth?.user);
    const orderNotes = orderStore.order?.orderNotes as string;
    const [orderNotesData, setOrderNotesData] = React.useState(orderNotes || "");
    const order = orderStore.order;

    const stepOpen = orderStore.stepOpen;

    const shippingAddresses = orderStore.order?.shippingAddress;
    const shippingAddress = shippingAddresses ? shippingAddresses[0] : null;

    const shipmentsInvalid = order?.shipmentsInvalid || (order?.isShipped && !order?.shipments);

    const [continuing, setContinuing] = React.useState(false);
    const [options, setOptions] = React.useState({ open: false });
    const step = useSelector((state: RootState) => state.order.stepOpen);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [modalData, setModalData] = React.useState<string[]>([]);

    const setOpen = (value: boolean) => {
        setOptions({ ...options, open: value });
    };

    useEffect(() => {
        setOpen(stepOpen === "shipments");
    }, [stepOpen]);

    const handleSectionClick = () => {
        dispatch(setStep("shipments"));
    };

    const getAndValidateOrder = async () => {
        // HH: We set it to any truthy string so that it matches all shipments
        setTemporaryDisable("DISABLED");
        if (!order) {
            return;
        }
        const orderRes = await dispatch(stateGetAndValidateOrder(order.id));
        setTemporaryDisable(undefined);
        const hasErrors = !!orderRes?.payload?.errors;
        setShowModal(hasErrors);
        setModalData(orderRes?.payload?.errors || []);
    }

    React.useEffect(() => {
        if (step !== "payment") {
            getAndValidateOrder();
        }
    }, [step]);

    const handleContinue = async () => {
        setContinuing(true);
        // ensure editing is not enabled
        setTimeout(async () => {
            await dispatch(
                addEditOrderNotes({
                    orderId: order?.id,
                    input: { orderNotes: orderNotesData }
                })
            );

            await dispatch(setStep("payment"));
            setContinuing(false);
        }, 250);
    };

    return (
        <>
            {showModal && <SiteModal>
                <div className="flex flex-col gap-4">
                    <h3>Shopping cart updated due to stock updates</h3>
                    {modalData.map((message) => (
                        <p key={message}>{message}</p>
                    ))}
                    <div className="flex flex-row gap-2 w-full">
                        <div className="p-2 bg-secondary text-bold text-center rounded-md w-full text-white cursor-pointer" onClick={() => {
                            setShowModal(false);
                        }}>Close</div>
                        <Link className="p-2 bg-primary text-bold text-center rounded-md w-full" to="/cart">Review Shopping Cart</Link>
                    </div>
                </div>
            </SiteModal>}
            <CheckoutSection
                title="Review Items and Delivery"
                sectionNumber={3}
                options={options}
                disabled={!shippingAddress ? true : false}
                onClick={handleSectionClick}
            >
                <ShipmentsDisplay setTemporaryDisable={setTemporaryDisable} temporaryDisable={temporaryDisable} />

                {/* <div className="mt-5">
                    <OrderNotes orderNotesData={orderNotesData} setOrderNotesData={setOrderNotesData} maxLength={ORDER_NOTES_MAX_LENGTH} />
                </div> */}
                <div className="flex justify-end mt-5">
                    <Button
                        loader={continuing}
                        className="w-full max-w-[200px]"
                        padding="py-3 px-5"
                        disabled={!!temporaryDisable || shipmentsInvalid || continuing || orderNotesData.length > ORDER_NOTES_MAX_LENGTH}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </div>
            </CheckoutSection>
        </>
    );
}
