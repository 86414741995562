import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import LoqateSearch from "react-loqate";
import "react-loqate/dist/react-loqate.cjs.development.css";
import TextInput from "../../shared/FormComponents/TextInput";

const AddressSearchInput = props => {
    const { inputProps } = props;

    return <TextInput {...props} {...inputProps} />;
};

export default function AddressSearch({ handleAddressSelect, inputProps }) {

    const data = useStaticQuery(graphql`
        query {
            loqate: allIntegration(filter: { type: { eq: "loqate" } }) {
                nodes {
                    id
                    config {
                        property
                        propertyId
                        value
                    }
                }
            }
        }
    `);

    if (!data || typeof window === "undefined") {
        return null;
    }

    const integration = data.loqate.nodes[0];

    if (!integration) return null;

    const config: any = {};

    if (integration.config) {
        integration.config.forEach(c => {
            config[c.property] = c.value;
        });
    }

    const apiKey = config?.apiKey;
    const countries = config.countries ? config.countries.split(",") : [""];

    return (
        <>
            <LoqateSearch
                locale="en-GB"
                apiKey={apiKey}
                countries={countries}
                components={{
                    Input: props => <AddressSearchInput {...props} inputProps={inputProps} />
                }}
                onSelect={handleAddressSelect}
                debounce={100}
            />
            <p className="mt-3">
                Type your address and select from the list. If your address is not
                listed, please enter it manually.
            </p>
        </>
    );
}
