import React, { useEffect } from "react";
import { AddressType } from "../../../types";
import { getConfigData } from "../../checkout/CheckoutPage/utils";
import { ADDRESS_FORM } from "../../checkout/CheckoutPage/constant";
import OrderAddress from "../../checkout/CheckoutPage/OrderAddress";
import Button from "../../shared/Button";
import { addressSchema } from "../../../utils/validations";
import SiteModal from "../../shared/SiteModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface Props {
    address: AddressType;
    handleSaveAddressClick: (data: AddressType) => Promise<void>;
    handleCancel: () => void;
    excludeSearch?: boolean;
    allowCancel?: boolean;
    addressErrorsProp?: Record<string, string>;
}
export default function AddressModal({
    address,
    handleSaveAddressClick,
    handleCancel,
    excludeSearch = false,
    allowCancel = false,
    addressErrorsProp = {}
}: Props) {
    const order = useSelector((state: RootState) => state.order.order);
    const [saving, setSaving] = React.useState(false);
    const [addressData, setAddressData] = React.useState<AddressType>({
        ...getConfigData(ADDRESS_FORM, address),
        id: address?.id
    });
    const [addressErrors, setAddressErrors] = React.useState({});

    useEffect(() => {
        setAddressErrors(addressErrorsProp);
    }, [addressErrorsProp]);

    const handleSaveClick = async () => {
        setSaving(true);

        try {
            await addressSchema.validate(addressData, { abortEarly: false });
        } catch (validationError) {
            const validationErrors = {};
            validationError.inner.forEach(error => {
                validationErrors[error.path] = error.message;
            });
            setAddressErrors(validationErrors);
            setSaving(false);
            return;
        }

        await handleSaveAddressClick(addressData);
        setSaving(false);
    };

    return (
        <SiteModal>
            <OrderAddress
                type={2}
                CONFIG={ADDRESS_FORM}
                addressData={addressData}
                setAddressData={setAddressData}
                addressErrors={addressErrors}
                excludeSearch={excludeSearch}
            />
            <div className="flex justify-between items-center mt-3">
                <Button
                    type="transparent"
                    disabled={saving || (allowCancel ? false : !order?.shippingAddress?.[0])}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    className="w-[250px]"
                    loader={saving}
                    disabled={saving}
                    onClick={handleSaveClick}
                >
                    Save Address
                </Button>
            </div>
        </SiteModal>
    );
}
