import React, { useMemo } from "react";
import { navigate } from "gatsby";
import { BsBag } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CartDisplay from "../../../../ecommerce/CartDisplay";
import Button from "../../../../shared/Button";
import { useSalesChannel } from "../../../../../context/SalesChannelContext";
import GetComponent from "../../../../websiteTemplates/_GetComponent";

export default function HeaderCart() {

    const salesChannel = useSalesChannel();

    const template = salesChannel?.websiteTemplate;

    return (
        <GetComponent template={template} component="Cart" props={{}} />
    );
}