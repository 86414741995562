import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import TextInput from "../components/shared/FormComponents/TextInput";
import Button from "../components/shared/Button";
import { IoIosSearch } from "react-icons/io";
import { isEmpty } from "../utils/string";
import { OrderType } from "../types";
import ecommerceApi from "../services/ecommerceApi";

export default function TrackingPage() {
    const params = new URLSearchParams(location.search);
    const parInvNumber = params.get("invNumber");
    const [invNumber, setInvNumber] = useState<string>(parInvNumber ?? "");
    const [postCode, setPostCode] = useState<string>("");
    const [showOrderFullData, setShowOrderFullData] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderType | null>(null);

    useEffect(() => {
        if (!isEmpty(invNumber) && order === null) {
            getOrderByNumber(invNumber, showOrderFullData);
        }
    }, []);

    const getOrderByNumber = (invNumber: string, showOrderFullData: boolean) => {
        ecommerceApi
            .getOrderByNumber(invNumber, showOrderFullData)
            .then((res: OrderType[]) => {
                if (res) {
                    setOrder(res[0]);
                }
            })
            .catch(err => {
                console.log("Error");
            });
    };

    const handleSubmit = async () => {
        if (!isEmpty(invNumber)) {
            getOrderByNumber(invNumber, showOrderFullData);
        }
    };
    return (
        <Layout>
            <div className="min-h-[60vh] pt-10">
                <div className=" w-full  flex flex-col items-center justify-center">
                    <h2>Track your Shipment</h2>
                    <br />
                    <p>Enter your Invoice Number to track your shipment</p>
                    <br />
                    <div className="w-6/12 h-[40px] flex flex-row items-center justify-center ">
                        <TextInput
                            label={""}
                            value={invNumber}
                            onChange={e => {
                                let val = e.target.value;
                                setInvNumber(val);
                            }}
                            className="w-3/4"
                            placeholder="Type your invoice number here "
                        />
                    </div>
                    <br />
                    <br />
                    <div className="w-6/12 h-[40px] flex flex-row items-center justify-center ">
                        <TextInput
                            label={""}
                            value={postCode}
                            onChange={async e => {
                                let val = e.target.value;
                                const customers = await ecommerceApi.getCustomerByPostCodes(val);

                                if (customers && customers.length > 0) {
                                    setShowOrderFullData(true);
                                }
                                setPostCode(val);
                            }}
                            className="w-1/3 mr-6"
                            placeholder="Type your post code here "
                        />

                        <Button
                            disabled={isEmpty(invNumber)}
                            loader={false}
                            onClick={async e => {
                                e.preventDefault();
                                await handleSubmit();
                            }}
                            className="w-1/3 h-full"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
                <br />
                <br />
                {order && (
                    <div className="w-full p-4">
                        <h2>Tracking</h2>
                        <br />
                        {order && (
                            <div className="w-full flex items-center justify-center p-4 flex-col">
                                <div className="w-full flex items-center justify-center flex-row">
                                    <h2 className="mr-4">{`Order #${
                                        order.invNumber ?? order.number
                                    }`}</h2>
                                    <p>|</p>
                                    <p className="ml-4">{`${order.status} ${new Date(
                                        order.orderDate
                                    ).toDateString()}`}</p>
                                </div>
                                <React.Fragment>
                                    <br />
                                    {order.orderNotes && (
                                        <div>
                                            <p className="ml-4">Order Notes : {order.orderNotes}</p>
                                        </div>
                                    )}
                                    <br />
                                    {order.shipments && order.shipments.length > 0 && (
                                        <div className="w-full flex items-center justify-start flex-row border mb-2 mt-2">
                                            <br />
                                            {order.shipments.map(sh => {
                                                return (
                                                    <div
                                                        key={sh.id}
                                                        className="flex items-center justify-center flex-col m-4"
                                                    >
                                                        <p>{`Shipment Tracking Number : ${
                                                            sh.trackingNumber || "N/A"
                                                        }`}</p>
                                                        <br />
                                                        <p>{`Status  : ${
                                                            sh.status || order.status
                                                        }`}</p>
                                                        <br />
                                                        <p>{`Notes  : ${
                                                            sh.shipmentNotes || order.orderNotes
                                                        }`}</p>
                                                        <br />
                                                        <p>{`Services  : ${sh.services
                                                            ?.map(el => el.type)
                                                            .join(",")}`}</p>
                                                        <br />
                                                        <p>{`Shipment Method  : ${sh.shippingMethod?.name}`}</p>
                                                        <br />

                                                        <p>{`Courier  : ${
                                                            sh.shippingMethod?.courier?.name ||
                                                            "N/A"
                                                        }`}</p>
                                                        <br />

                                                        <p>{`Shipping Amount  : ${
                                                            sh.shippingAmount ?? 0
                                                        }`}</p>
                                                        <br />
                                                        <p>{`Est Dispatch Date  : ${new Date(
                                                            sh.estDispatchDate
                                                        ).toDateString()}`}</p>
                                                        <br />
                                                        <p>{`Est Delivery Date  : ${new Date(
                                                            sh.estDeliveryDate
                                                        ).toDateString()}`}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    <br />
                                    {order?.history && order.history.length > 0 && (
                                        <div>
                                            <p>{`Order Tracking Number : ${order.number}`}</p>
                                            <div>
                                                <h5>History</h5>
                                                <div>
                                                    {(order?.history ?? []).map((hist, idx) => {
                                                        return (
                                                            <div
                                                                key={`${new Date(hist.date)
                                                                    .getTime()
                                                                    .toString()}-${idx.toString()}`}
                                                            >
                                                                <p>{`Date : ${new Date(
                                                                    hist.date
                                                                ).toDateString()}`}</p>
                                                                <br />
                                                                <p>{`Note : ${hist.note}`}</p>
                                                                <br />
                                                                <p>{`Status : ${hist.status}`}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
}
