import React, { useEffect } from "react";
import OrderAddress from "../OrderAddress";
import { ADDRESS_FORM } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import Button from "../../../shared/Button";
import { getConfigData } from "../utils";
import { addressSchema } from "../../../../utils/validations";
import { addEditOrderAddress, setStep } from "../../../../store/order";
import { AddressType } from "../../../../types";
import SiteModal from "../../../shared/SiteModal";
import AddressModal from "../../../ecommerce/AddressModal";
import AddressDisplay from "../../../ecommerce/AddressDisplay";
import { BsCheckCircle, BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { getCustomerAddresses, updateCustomer } from "../../../../store/auth";
import ecommerceApi from "../../../../services/ecommerceApi";
import { graphql, useStaticQuery } from "gatsby";
import { validateAddress } from "../../../../utils/loqateValidations";

interface Props {
    temporaryDisable: string | undefined;
    selectedAddress: AddressType | undefined;
    type: "Shipping" | "Billing";
    editing: boolean;
    setEditing: (value: boolean) => void;
}

export default function AddressSelector({ temporaryDisable, selectedAddress, type, editing, setEditing }: Props) {
    const dispatch = useDispatch<AppDispatch>();

    const order = useSelector((state: RootState) => state.order.order);
    const user = useSelector((state: RootState) => state.auth.user);
    const customer = useSelector((state: RootState) => state.auth.customer);

    const [addressToEdit, setAddressToEdit] = React.useState<AddressType | null>(null);

    const handleEditClick = () => {
        setEditing(true);
    };

    useEffect(() => {
        setEditing(!selectedAddress);
    }, [selectedAddress]);

    useEffect(() => {
        initAuth();
    }, [user?.username]);

    useEffect(() => {
        if (customer) {
            dispatch(getCustomerAddresses());
        }
    }, [editing, customer]);

    const initAuth = async () => {
        if (!!user?.username && !customer) {
            const customerLookup = await ecommerceApi.getCustomerByEmail(user.email);
            if (customerLookup != "error" && customerLookup) {
                const customer = customerLookup[0];
                if (customer) {
                    await dispatch(updateCustomer(customer));
                }
            }
        }
    }

    return (
        <>
            {!editing && selectedAddress && (
                <AddressDisplay address={selectedAddress}>
                    <div className="flex -mx-3" style={{ opacity: temporaryDisable ? 0.5 : 1 }}>
                        <div className="px-3">
                            <Button disabled={!!temporaryDisable} type="transparent" onClick={handleEditClick}>
                                Edit
                            </Button>
                        </div>
                    </div>
                </AddressDisplay>
            )}

            {editing ? (
                <ExistingAddresses
                    type={type}
                    selectedAddress={selectedAddress}
                    addressToEdit={addressToEdit}
                    setAddressToEdit={setAddressToEdit}
                    setEditing={setEditing}
                />
            ) : null}
        </>
    );
}

const ExistingAddresses = ({
    selectedAddress,
    addressToEdit,
    setAddressToEdit,
    setEditing,
    type
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const order = useSelector((state: RootState) => state.order.order);
    const customer = useSelector((state: RootState) => state.auth.customer);
    const customerAddresses = useSelector((state: RootState) => state.auth.addresses);
    const userSub = useSelector((state: RootState) => state.auth.user?.sub);

    const [loadingId, setLoadingId] = React.useState<string | undefined>(undefined);
    const [messages, setMessages] = React.useState<string[]>([]);

    const loqateData = useStaticQuery(graphql`
        query {
            loqate: allIntegration(filter: { type: { eq: "loqate" } }) {
                nodes {
                    id
                    config {
                        property
                        propertyId
                        value
                    }
                }
            }
        }
    `);

    const integration = loqateData.loqate.nodes[0];

    const handleSaveAddressClick = async (data: AddressType) => {
        const { valid, messages } = await validateAddress(data, integration);
        if (!valid) {
            setMessages(messages);
            return;
        }

        await dispatch(
            addEditOrderAddress({
                orderId: order?.id,
                address: { ...data, type },
                customer: customer
            })
        );

        if (customer) {
            dispatch(getCustomerAddresses());
        }

        setAddressToEdit(null);
        setEditing(false);
    };

    const handleAddressSelect = (address: AddressType) => async (e: any) => {
        setLoadingId(address.id);
        await dispatch(
            addEditOrderAddress({
                orderId: order?.id,
                address: { ...address, type },
                customer: customer
            })
        );
        setAddressToEdit(null);
        setEditing(false);
        setLoadingId(undefined);
    };

    const handleNewClick = () => {
        setEditing(true);
        setAddressToEdit({
            firstName: "",
            lastName: "",
            line1: "",
            line2: "",
            postCode: "",
            city: ""
        });
    };

    useEffect(() => {
        if (customerAddresses.length < 1 && !userSub) {
            setEditing(true);
            setAddressToEdit(order?.shippingAddress?.[0] || {
                firstName: "",
                lastName: "",
                line1: "",
                line2: "",
                postCode: "",
                city: ""
            });
        }
    }, [customerAddresses]);

    return (
        <>
            {addressToEdit != null && (
                <AddressModal
                    address={addressToEdit}
                    handleSaveAddressClick={handleSaveAddressClick}
                    allowCancel={true}
                    handleCancel={() => setAddressToEdit(null)}
                    addressErrorsProp={{ "postCode": messages.join(".") }}
                />
            )}

            <div>
                {customerAddresses?.map(address => {
                    const isShippingAddress = loadingId
                        ? loadingId == address.id
                        : selectedAddress?.id === address.id;

                    return (
                        <div
                            className={`py-5 px-2 bg-gray-100 my-3 border-2 
                                ${isShippingAddress ? "border-secondary" : "border-gray-100"}
                                ${loadingId != undefined && "opacity-50"
                                }`}
                        >
                            <div className="flex -mx-2">
                                <div className="px-2 flex items-center">
                                    <a
                                        className="block px-2 cursor-pointer"
                                        onClick={handleAddressSelect(address)}
                                    >
                                        {isShippingAddress ? (
                                            <BsCheckCircleFill size={20} className="" />
                                        ) : (
                                            <BsCircle size={20} />
                                        )}
                                    </a>
                                </div>

                                <div className="px-2 grow">
                                    <div
                                        className="cursor-pointer text-base md:text-lg leading-tight"
                                        onClick={handleAddressSelect(address)}
                                    >
                                        <div>
                                            {address.firstName} {address.lastName}
                                        </div>
                                        <div>
                                            {address.line1}, {address.postCode}
                                        </div>
                                    </div>
                                </div>

                                <div className="px-2">
                                    <div className="flex -mx-3">
                                        <div className="px-3">
                                            <Button
                                                type="transparent"
                                                onClick={() => {
                                                    setAddressToEdit(address)
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="flex justify-between">
                <Button type="secondary" onClick={handleNewClick}>
                    New Address
                </Button>
            </div>
        </>
    );
};
