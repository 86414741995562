import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse, { DOMNode } from "html-react-parser";
import { FAQJsonLd } from "gatsby-plugin-next-seo";
import { ContentType, IFaqProp, ModelType } from "../../../types";

interface FaqProps {
    data: IFaqProp;
}

export default function Faq({ data }: FaqProps) {
    if (data.faqs && data.faqs.length > 0 && data.selectedTransformation) {
        let entriesHtmlStr: string[] = [];

        data.faqs.forEach(faq => {
            let faqData = JSON.parse(faq.data ?? "{}");
            let processedHtml = data?.selectedTransformation.html;
            let dataEntries = Object.fromEntries(
                Object.keys(faqData).map(field => [field, faqData[field]])
            );
            for (let key in dataEntries) {
                processedHtml = processedHtml.replaceAll(`{{${key}}}`, dataEntries[key]);
            }
            entriesHtmlStr.push(processedHtml);
        });

        return (
            <div>
                <div>{parse(entriesHtmlStr.join("<br />"))}</div>

                <FAQJsonLd
                    questions={data.faqs.map(faq => {
                        let data = JSON.parse(faq.data);
                        return { question: data.Question, answer: data.Answer };
                    })}
                />
            </div>
        );
    }

    return null;
}
