import React, { useEffect, useMemo, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { getPriceToShow } from "../../../../../utils/currency";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { addToCart } from "../../../../../store/order";
import Button from "../../../../shared/Button";
import EcommerceApi from "../../../../../services/ecommerceApi";
import { useSalesChannel } from "../../../../../context/SalesChannelContext";
import { extractScCurrValueFromProductAttr } from "../../../../../utils/string";
import { constructProductMetricHtml, constructProductMetricsHtml } from "../../../../../utils/html";
import { ProductDataType, ProductType } from "../../../../../types";
import ItemsInStock from "../../../../ecommerce/ItemsInStock";

const ProductCard = ({ product }) => {
    const dispatch = useDispatch<AppDispatch>();

    const salesChannel = useSalesChannel();
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );
    const [adding, setAdding] = React.useState(false);
    const [taxCodes, setTaxCodes] = useState([]);
    const [productData, setProductData] = useState<ProductDataType | null>(null);
    const store = useSelector((state: RootState) => state);
    const { order: orderStore, currencyCode: currencyCodeStore } = store;

    const hasPage = product?.url ? true : false;

    useEffect(() => {
        loadProductData();
    }, []);

    const handleLinkClick = e => {
        e.preventDefault();

        if (hasPage) {
            navigate(product?.url);
        }
        console.error("Product page not found.");
    };

    const loadProductData = async () => {
        const taxCodes = await EcommerceApi.getProductTaxCodesData(product.id);
        const productData = (await EcommerceApi.getProductData(product.id)) as ProductDataType;
        setProductData(productData);
        setTaxCodes(taxCodes);
    };
    const handleButtonClick = async () => {
        setAdding(true);
        const orderId =
            orderStore?.order && orderStore?.order ? (orderStore?.order as any).id : null;
        const params = {
            orderId: orderId,
            skuId: product.id,
            quantity: 1
        };
        await dispatch(addToCart(params));
        setAdding(false);
    };

    let priceToShow = getPriceToShow(
        product.currentPrice,
        salesChannel,
        currencyCode,
        undefined,
        undefined,
        true,
        0
    );

    let shortDescription = useMemo(
        () =>
            extractScCurrValueFromProductAttr(
                product.shortDescription,
                salesChannel?.id,
                "shortDescription"
            ),
        [salesChannel?.id, product.shortDescription]
    );
    let productMetricsHtml = constructProductMetricsHtml(
        product?.productPricingMetrics,
        product as ProductType,
        productData as ProductDataType
    );
    let productMetricHtml = constructProductMetricHtml(product as ProductType);
    return (
        <div className="p-5 bg-white h-full flex flex-col text-center">
            <div className="text-center grow">
                <h3>{product.shortName}</h3>
            </div>

            <div>
                <div className="py-6" dangerouslySetInnerHTML={{ __html: priceToShow }}></div>
                <div className="h-5">{productMetricsHtml || productMetricHtml}</div>
                <div className="py-6">
                    <span className="text-2xl">{shortDescription}</span>
                </div>
                {hasPage && (
                    <div className="flex justify-center mb-2">
                        <Button
                            className="w-[180px]"
                            type="underline"
                            padding="py-2 px-3"
                            onClick={handleLinkClick}
                        >
                            Find out More
                        </Button>
                    </div>
                )}
                <div className="flex justify-center">
                    <Button
                        className="w-[150px]"
                        padding="py-2 px-3"
                        onClick={handleButtonClick}
                        disabled={adding}
                        loader={adding}
                    >
                        Add to Cart
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
