import React from "react";
import { FieldType, PageListType } from "../../../../types";
import { Link } from "gatsby";
import Button from "../../../shared/Button";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { FieldProps } from "../../../pages/Page/Field";

export interface PageListProps {
    pageListConfig: PageListType;
    field: FieldProps["field"];
}

export default function PageList({ pageListConfig, field }: PageListProps) {
    const { pages } = pageListConfig;
    const pageList = field?.pageList?.pages ?? [];

    return (
        <div className="flex flex-wrap justify-center -mx-3">
            {pageList.map((page, index) => {
                return <Item page={page} index={index} />;
            })}
        </div>
    );
}

const Item = ({ page, index }: { page: any; index: any }) => {
    const image = getImage(page?.imageUrl?.imageFile);
    const bgImage = convertToBgImage(image);

    return (
        <div className="p-3 w-full md:w-1/2 mb-6 md:mb-0" key={page.id}>
            <Link className="block h-full bg-gray-100" to={page.slug} key={page.id}>

                <BackgroundImage
                    Tag="div"
                    {...bgImage}
                    className="bg-center bg-cover bg-gray-300">
                    <div className="pb-[56%]"></div>
                </BackgroundImage>

                <div className="p-6">
                    <div>
                        <h3 className="font-primary text-2xl font-extrabold uppercase mb-2">
                            {page.title || page.name}
                        </h3>
                        <p className="text-sm">{page.desc}</p>
                    </div>
                    <div>
                        <Button className="px-0 mt-3 text-primary" type="transparent">
                            Read More
                        </Button>
                    </div>
                </div>
            </Link>
        </div>
    );
};