import React from "react";
import Collapse from "../../shared/Collapse";
import CaretDown from "../../shared/SVG/CaretDown";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";

type Props = {
    sectionNumber: number;
    title?: string;
    options: { open: boolean };
    children: React.ReactNode;
    disabled: boolean;
    onClick: () => void;
};

export default function CheckoutSection({
    sectionNumber,
    title,
    options,
    children,
    disabled,
    onClick
}: Props) {
    const sectionOnClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };

    return (
        <>
            <section
                className={`border-t-2 border-secondary ${!options.open ? "opacity-50" : "mb-5"}`}
            >
                <div className="flex -mx-3">
                    <div className="hidden xl:block px-3">
                        <div
                            className="cursor-pointer w-10 h-10 md:w-16 md:h-16 bg-secondary text-white 
                            font-extrabold 
                            flex items-center justify-center
                            text-xl md:text-4xl
                            "
                            onClick={sectionOnClick}
                        >
                            {sectionNumber}
                        </div>
                    </div>

                    <div className="px-3 flex-1 max-w-full">
                        <div
                            className="cursor-pointer min-h-16 flex items-center md:mb-5 py-4"
                            onClick={sectionOnClick}
                        >
                            {!!title && (
                                <h2 className="text-xl md:text-3xl">
                                    <span className="xl:hidden mr-5">{sectionNumber}.</span>
                                    {title}
                                </h2>
                            )}
                        </div>

                        {!!options.open && children}
                    </div>
                </div>
            </section>
        </>
    );
}
