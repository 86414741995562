import React from "react";
import { ProductCategoryType } from "../../../types";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { useSalesChannel } from "../../../context/SalesChannelContext";

const RelatedProducts = () => {
    const { websiteTemplate } = useSalesChannel();
    return (
        <GetComponent
            template={websiteTemplate}
            component="RelatedProducts"
        />
    );
};

export default RelatedProducts;
