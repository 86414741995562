import React from "react";
import { Link } from "gatsby";
import { BsList } from "react-icons/bs";
import HeaderAccount from "../../../../components/layouts/Layout/Header/Account";
import HeaderCart from "../../../../components/layouts/Layout/Header/Cart";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";
import Button from "../../../shared/Button";
import { HeaderProps } from "../../Default/Header";

export default function Header({
    salesChannel,
    logoFile,
    isSticky,
    isDrawerVisible,
    setIsDrawerVisible,
    isMobile,
    windowSize,
    menus
}: HeaderProps) {

    const firstMenu = menus?.length > 0 ? menus[0] : null;

    return (
        <>
            <header
                className={`h-[80px] ${isSticky ? "sticky" : ""
                    } top-0 left-0 w-full bg-white z-40`}
            >
                <div className="mx-auto px-5">
                    <div className="flex justify-between md:justify-start items-center -mx-2">
                        <div className="px-2 grow flex">
                            <button
                                title={isDrawerVisible ? "Close Menu" : "Open Menu"}
                                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                                className="flex items-center px-3 md:hidden"
                            >
                                <BsList className="w-[25px] h-[25px]" />
                            </button>
                            <Link title="Home" to="/">
                                <div className="max-w-[200px] max-h-[60px]">
                                    <HeaderLogo
                                        logoFile={logoFile}
                                        alt={salesChannel.name + " Logo"}
                                    />
                                </div>
                            </Link>
                        </div>
                        <div
                            style={{
                                top: isDrawerVisible || !isMobile ? 0 : "-100%",
                                opacity: isDrawerVisible || !isMobile ? 1 : 0
                            }}
                            className="
                                flex justify-end fixed left-0 w-full h-full bg-black bg-opacity-50 z-30
                                md:static md:h-auto md:bg-transparent
                                md:px-3 md:grow md:justify-start transition-[opacity] md:transition-none
                            "
                            onClick={() => setIsDrawerVisible(false)}
                        >
                            <ul
                                style={{
                                    width: !isMobile ? "auto" : "calc(100% - 60px)",
                                    right: isDrawerVisible || !isMobile ? 0 : "-100%"
                                }}
                                className="
                                relative flex flex-col h-full bg-white py-[50px]
                                md:h-auto md:bg-transparent md:p-0 md:flex-row md:w-auto transition-[right] md:transition-none
                            "
                            >
                                <li>
                                    <span
                                        className="
                                        block px-8 pb-5
                                        md:hidden
                                    "
                                    >
                                        <span className="bg-primary font-bold uppercase text-sm px-2 py-1 rounded">
                                            Menu
                                        </span>
                                    </span>
                                </li>
                                {firstMenu?.items?.map(menuItem => (
                                    <li key={menuItem.id} className="">
                                        <Link
                                            to={`${menuItem.link?.src}`}
                                            className="
                                                block px-8 py-[13px] text-xl font-semibold
                                                md:px-5 md:py-2 md:text-base md:font-normal md:tracking-wider
                                            "
                                        >
                                            {menuItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="hidden px-2">
                            <HeaderAccount isMobile={isMobile} />
                        </div>

                        <div className="px-2">
                            <Button textSize="text-base">
                                Classroom
                            </Button>
                        </div>

                        <div className="px-2">
                            <HeaderCart />
                        </div>

                    </div>
                </div>
            </header>
        </>
    );
}

const HeaderLogo = ({ logoFile, alt }: { logoFile?: { node: ImageDataLike }, alt: string }) => {

    if (logoFile) {
        const gatsbyImage = getImage(logoFile);
        if (gatsbyImage) {
            return <GatsbyImage
                image={gatsbyImage}
                alt={alt}
            />;
        }
    }

    return <></>

}