import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import CheckoutSection from "../CheckoutSection";
import { CONTACT_FORM } from "../constant";
import TextInput from "../../../shared/FormComponents/TextInput";
import Button from "../../../shared/Button";
import { addCustomerOrder, setStep } from "../../../../store/order";
import EnterEmail from "./EnterEmail";
import { AuthModes, useLoginSignUp } from "../../../auth/LoginPage/hook";
import { SIGN_UP_FORM } from "../../../auth/LoginPage/constants";
import { graphql, navigate, useStaticQuery } from "gatsby";
import LoggedInView from "./LoggedInView";
import { isEmpty } from "../../../../utils/string";
import ecommerceApi from "../../../../services/ecommerceApi";
import { BsExclamationCircleFill, BsFileRuled } from "react-icons/bs";
import { isEmail, validatePhone } from "../../../../utils/validations";
import { validatePhoneNumber } from "../../../../utils/loqateValidations";

export default function CustomerDetails() {
    const dispatch = useDispatch<AppDispatch>();

    const stepOpen = useSelector((state: RootState) => state.order.stepOpen);
    const order = useSelector((state: RootState) => state.order.order);

    const user = useSelector((state: RootState) => state.auth.user);

    const loqateData = useStaticQuery(graphql`
        query {
            loqate: allIntegration(filter: { type: { eq: "loqate" } }) {
                nodes {
                    id
                    config {
                        property
                        propertyId
                        value
                    }
                }
            }
        }
    `);

    const integration = loqateData.loqate.nodes[0];

    const { loading, mode, data, setValue, setMode, getSecretCode, error, setError, verify } =
        useLoginSignUp(undefined, false);

    const orderIsShipped = order?.isShipped;

    const [saving, setSaving] = React.useState(false);
    const [options, setOptions] = React.useState({ open: false });
    const [errorMessages, setErrorMessages] = React.useState<string | null>(null);
    const [warningMessages, setWarningMessages] = React.useState<string | null>(null);

    const setOpen = (value: boolean) => {
        setOptions({ ...options, open: value });
    };

    useEffect(() => {
        setOpen(stepOpen === "customerDetails");
    }, [stepOpen]);

    const handleClick = async () => {
        setSaving(true);

        const addCustomer: any = {
            given_name: data.firstName,
            family_name: data.lastName,
            email: data.email,
            phone_number: data.phoneNumber
        };

        // VALIDATE
        if (
            isEmpty(data.firstName) ||
            isEmpty(data.lastName) ||
            isEmpty(data.email) ||
            isEmpty(data.phoneNumber)
        ) {
            setErrorMessages("Please ensure all fields are completed for Guest checkout.");
            setSaving(false);
            return;
        }

        // VALIDATE EMAIL
        if (!isEmail(data.email)) {
            setErrorMessages("Please ensure your email is valid");
            setSaving(false);
            return;
        }

        // VALIDATE PHONE
        const { valid, message } = await validatePhoneNumber(data.phoneNumber, integration)
        if (!valid) {
            setErrorMessages(message || "Please ensure your phone number is valid");
            setSaving(false);
            return;
        }
        if (valid && message) {
            setWarningMessages(message);
            setSaving(false);
            if (!warningMessages) {
                return;
            }
        }

        addCustomer.phone_number = data.phoneNumber.replace(/^(0)/, "+44");
        setValue("phoneNumber", addCustomer.phone_number);

        await dispatch(
            addCustomerOrder({
                user: addCustomer,
                orderId: order?.id as string
            })
        );

        dispatch(setStep(orderIsShipped ? "shipping" : "payment"));

        setSaving(false);
    };

    const handleSectionClick = () => {
        dispatch(setStep("customerDetails"));
    };

    const handleVerifyCode = async () => {
        await verify();
    };

    const handleGuestFormChange = (fieldName: string) => (e: any) => {
        setErrorMessages(null);
        setWarningMessages(null);
        setValue(fieldName, e.target.value);
    };

    const signUp = async () => {
        const errorMessages: string[] = [];
        const tempGuestCheckoutWarning = warningMessages;
        let firstTimeWarning = false;
        for (const field of SIGN_UP_FORM) {
            const input = data[field.name];
            if (field.validate) {
                const { valid, message } = await field.validate(input, loqateData);
                if (!valid && message) {
                    errorMessages.push(message);
                }
                if (valid && message) {
                    setWarningMessages(message);
                    if (!tempGuestCheckoutWarning) {
                        firstTimeWarning = true;
                    }
                }
            }
        }

        if (errorMessages.length > 0 || firstTimeWarning) {
            setErrorMessages(errorMessages.join("\n"));
            return;
        }

        setErrorMessages(null);
        setWarningMessages(null);
        getSecretCode();
    }

    return (
        <CheckoutSection
            title={"Customer Details"}
            sectionNumber={1}
            options={options}
            disabled={false}
            onClick={handleSectionClick}
        >
            <div className="pb-5">
                {!loading && user ? (
                    <LoggedInView />
                ) : (
                    <>
                        {mode == "SIGN_IN" && (
                            <EnterEmail
                                loading={loading}
                                email={data?.["email"] || ""}
                                setMode={setMode}
                                getSecretCode={getSecretCode}
                                setValue={setValue}
                            />
                        )}

                        {mode == "SIGN_UP" && (
                            <>
                                <div className="flex items-center justify-center mb-5">
                                    <BsExclamationCircleFill className="mr-4" />
                                    Looks like you don't have an account with us. Please sign-up
                                    below.
                                </div>
                                {SIGN_UP_FORM.map(f => {
                                    return (
                                        <div className="mb-3" key={f.name}>
                                            <TextInput
                                                label={f.label}
                                                value={data[f.name]}
                                                onChange={e => {
                                                    setValue(f.name, e.target.value);
                                                    setErrorMessages(null);
                                                    setWarningMessages(null);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                                {errorMessages && (
                                    <div className="text-red-500 text-sm mt-3 text-center whitespace-pre">
                                        {errorMessages}
                                    </div>
                                )}
                                {warningMessages && (
                                    <div className="text-yellow-500 text-sm mt-3 text-center whitespace-pre">
                                        {warningMessages}
                                    </div>
                                )}
                                <Button
                                    disabled={loading}
                                    loader={loading}
                                    className="mt-5 w-full"
                                    onClick={() => { signUp() }}
                                >
                                    {warningMessages && !errorMessages ? "Sign Up Anyway" : "Sign Up"}
                                </Button>
                                <div className="pt-5 text-center">
                                    <a
                                        className="cursor-pointer underline"
                                        onClick={() => setMode("SIGN_IN")}
                                    >
                                        Already have an Account? Click here to sign-in
                                    </a>
                                </div>
                            </>
                        )}

                        {mode == "VERIFY_CODE" && (
                            <>
                                <div className="flex items-center justify-center mb-5">
                                    <BsExclamationCircleFill className="mr-4" />
                                    We've sent a verification code to your email address. Please
                                    enter it below.
                                </div>
                                <TextInput
                                    label={"Code"}
                                    value={data.code}
                                    onChange={e => setValue("code", e.target.value)}
                                />

                                <div className="flex -mx-3 my-3">
                                    <div className="px-3 w-1/2">
                                        <Button
                                            disabled={loading}
                                            loader={loading}
                                            className="w-full"
                                            onClick={handleVerifyCode}
                                        >
                                            Verify Code
                                        </Button>
                                    </div>
                                    <div className="px-3 w-1/2">
                                        <Button
                                            disabled={loading}
                                            loader={loading}
                                            type="secondary"
                                            className="w-full"
                                            onClick={getSecretCode}
                                        >
                                            Resend Code
                                        </Button>
                                    </div>
                                </div>
                                <div className="pt-2 flex justify-end">
                                    <Button
                                        type="transparent"
                                        className="mr-3"
                                        padding={"py-3 px-5"}
                                        onClick={() => setMode("SIGN_IN")}
                                    >
                                        Back to Login
                                    </Button>
                                </div>
                            </>
                        )}

                        {mode == "GUEST" && (
                            <>
                                <div className="flex flex-wrap -mx-3">
                                    {CONTACT_FORM.map((field: any, index: number) => (
                                        <div key={index} className={`${field.col} px-3 mb-3`}>
                                            <TextInput
                                                label={field.label}
                                                value={data[field.name]}
                                                onChange={handleGuestFormChange(field.name)}
                                                error={
                                                    errorMessages != null &&
                                                    isEmpty(data[field.name])
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                                {errorMessages != null && (
                                    <div className="text-red-500 text-sm mt-3 text-center">{errorMessages}</div>
                                )}
                                {warningMessages != null && !errorMessages && (
                                    <div className="text-yellow-500 text-sm mt-3 text-center">{warningMessages}</div>
                                )}
                                <div className="flex justify-end mt-5">
                                    <Button
                                        type="transparent"
                                        className="mr-3"
                                        padding={"py-3 px-5"}
                                        onClick={() => setMode("SIGN_IN")}
                                    >
                                        Back to Login
                                    </Button>
                                    <Button
                                        loader={saving}
                                        disabled={saving}
                                        className="w-full max-w-[300px]"
                                        padding={"py-3 px-5"}
                                        onClick={handleClick}
                                    >
                                        {warningMessages && !errorMessages ? "Continue Anyway" : "Save & Continue"}
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </CheckoutSection>
    );
}
