import React, { useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import CheckoutSection from "../CheckoutSection";
import PaymentOptions from "./Options";
import order, { addEditOrderAddress, setStep } from "../../../../store/order";
import BillingAddress from "./BillingAddress";
import Button from "../../../shared/Button";
import Checkbox from "../../../shared/Checkbox";
import auth from "../../../../store/auth";
import { validateAddress } from "../../../../utils/validations";
import { BsExclamationCircleFill } from "react-icons/bs";
import { formatPrice } from "../../../../utils/currency";

export default function CheckoutPayment() {
    const dispatch = useDispatch<AppDispatch>();

    const orderStore = useSelector((state: RootState) => state.order);
    const user = useSelector((state: RootState) => state.auth?.user);
    const authCustomer = useSelector((state: RootState) => state.auth?.customer);

    const order = useSelector((state: RootState) => state.order.order);

    const shipmentsInvalid = order?.shipmentsInvalid;
    const noShipments = (order?.shipments?.length || 0) === 0 || order?.shipmentsCount === 0;
    let sectionDisabled = shipmentsInvalid || noShipments;

    const orderId = orderStore.order?.id;

    const stepOpen = orderStore.stepOpen;

    const billingAddress = orderStore.order?.billingAddress;
    const shippingAddresses = orderStore.order?.shippingAddress;
    const shippingAddress = shippingAddresses ? shippingAddresses[0] : null;

    const isOrderShipped = orderStore.order?.isShipped;

    const [options, setOptions] = React.useState({ open: false });

    const [message, setMessage] = React.useState<string | null>(null);
    const [sameAddress, setSameAddress] = React.useState(
        billingAddress?.id === shippingAddress?.id
    );

    const setOpen = (value: boolean) => {
        setOptions({ ...options, open: value });
    };

    const handleSameAddressChange = async (checked: boolean) => {
        if (checked && shippingAddress) {
            await dispatch(
                addEditOrderAddress({
                    orderId,
                    address: { ...shippingAddress, type: "Billing" },
                    customer: authCustomer
                })
            );
        }
        setSameAddress(checked);
    };

    const checkBillingAddress = async () => {
        if (billingAddress) {
            const validationErrors = await validateAddress(billingAddress);
            if (validationErrors != true) {
                const firstKey = Object.keys(validationErrors)[0];
                setMessage(validationErrors[firstKey]);
            } else {
                setMessage(null);
            }
        }
    };

    useEffect(() => {
        setOpen(stepOpen === "payment");
    }, [stepOpen]);

    useEffect(() => {
        checkBillingAddress();
    }, [billingAddress]);

    useEffect(() => {
        setSameAddress(billingAddress?.id === shippingAddress?.id);
    }, [shippingAddress, billingAddress]);

    const sectionNumber = useMemo(() => {
        let number = 4;
        if (!isOrderShipped) {
            number -= 2;
        }
        return number;
    }, [isOrderShipped, user, isOrderShipped]);

    const handleSectionClick = () => {
        dispatch(setStep("payment"));
    };

    return (
        <CheckoutSection
            title="Payment"
            sectionNumber={sectionNumber}
            options={options}
            disabled={sectionDisabled}
            onClick={handleSectionClick}
        >
            {message && (
                <div className="py-3 px-4 flex items-center text-red-500 font-bold">
                    <BsExclamationCircleFill />
                    <span className="ml-3">{message}</span>
                </div>
            )}

            <div className="my-3">
                {isOrderShipped ? (
                    <div className="flex items-center px-3 pb-2">
                        <Checkbox
                            label={"Billing Address is the same as Shipping"}
                            checked={sameAddress}
                            onChange={handleSameAddressChange}
                        />
                    </div>
                ) : null}

                {!isOrderShipped && (
                    <>
                        <h2 className="text-xl">Billing</h2>
                    </>
                )}

                {(!sameAddress || !isOrderShipped) && (
                    <div className="my-3">
                        <BillingAddress />
                    </div>
                )}
            </div>

            {!message && (
                <div className="">
                    <PaymentOptions className="mt-5" setOpen={value => setOpen(value)} />
                </div>
            )}

            <div className="flex justify-end mt-4">
                <Button
                    type="secondary"
                    onClick={() => {
                        window.open("https://www.surveymonkey.co.uk/r/WHE_Feedback", "_blank")
                    }}>
                    Send us Feedback
                </Button>
            </div>

        </CheckoutSection>
    );
}
