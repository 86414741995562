import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadScript } from "@paypal/paypal-js";
import EcommerceService from "../../../../../../services/ecommerceApi";
import { getPaymentGatewayConfig } from "../../../../../../utils/paymentGateway";
import { RootState } from "../../../../../../store";
import { IPaymentGateway } from "..";

interface Props {
    paymentGateway: IPaymentGateway;
    rendered: () => void;
    handlePlaceOrder: (order: any) => Promise<void>;
}

export default function PayPal({ rendered, paymentGateway, handlePlaceOrder }: Props) {

    const orderStore = useSelector((state: RootState) => state.order);
    const order = orderStore.order;

    useEffect(() => {
        drawButtons();
    }, []);

    const drawButtons = async () => {
        if (paymentGateway) {
            const config = getPaymentGatewayConfig(paymentGateway);
            const paypal = await loadScript({
                "client-id": config["clientId"],
                currency: "GBP"
            });
            if (paypal) {
                await configButtons(paypal);
            }
        }
    };

    const createOrder = async (data, actions) => {
        const roundedValue = Math.round((order?.totalAmount || 0) * 100) / 100;

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: roundedValue
                    },
                    reference_id: order?.id,
                    description: `${order?.id}-${order?.customer?.email}`
                }
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        });
    };

    const onApprove = async (data, actions) => {
        try {
            const response = await EcommerceService.placeOrder(order?.id, paymentGateway.id, {
                paypal: {
                    paymentGatewayType: "paypal",
                    ...data
                }
            });
            
            handlePlaceOrder(response);

        } catch (error) {
            console.error("error", error);
        }
    };

    const configButtons = async paypal => {
        await paypal
            .Buttons({
                createOrder,
                onApprove
            })
            .render("#paypal-button-container")
            .catch(error => {
                console.error("failed to render the PayPal Buttons", error);
            });

        rendered();
    };

    return (
        <div>
            <div id={"paypal-button-container"}></div>
        </div>
    );
}
