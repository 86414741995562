import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import CartPage from "../../components/checkout/CartPage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createOrderFromCartLink } from "../../store/order";
import Spinner from "../../components/shared/Spinner";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useTrackingContext } from "../../context/tracking";

type Props = {
    id: string;
};

export default function CartLinkPage({ id }: Props) {
    const dispatch = useDispatch<AppDispatch>();
    const order = useSelector((state: RootState) => state.order.order);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);

    const { addToCartLinkEvent } = useTrackingContext();

    useEffect(() => {
        loadCartFromLink();
    }, [id]);

    const updateState = (loading, error) => {
        setLoading(loading);
        setError(error);
    };

    const loadCartFromLink = async () => {
        const params = {
            id
        };

        const res = await dispatch(createOrderFromCartLink(params));

        if (!res.payload) {
            updateState(false, "This link could not be found.");
            return;
        }

        addToCartLinkEvent({ cartLinkId: id });
        setLoading(false);
    };

    return (
        <Layout>
            {loading && (
                <div className="min-h-[40vh] w-full flex items-center justify-center">
                    <Spinner width="50px" height="30px" />
                </div>
            )}
            {!loading && error && (
                <div className="min-h-[40vh] w-full flex items-center justify-center py-10">
                    <div className="text-center">
                        <BsExclamationCircleFill className="inline-block text-6xl text-yellow-500 mb-3" />
                        <h1 className="text-4xl mb-3">Cart Link Invalid</h1>
                        <p className="text-2xl">{error}</p>
                    </div>
                </div>
            )}
            {!error && !loading && order && <CartPage />}
        </Layout>
    );
}
