import React from 'react'
import TrustpilotWidget from '.'
import { useLayout } from '../../layouts/Layout/hook';

type Props = {
    sku: string;
    locale?: string;
    templateId?: string;
    theme?: string;
    width?: string;
    height?: string;
}

export default function TrustpilotProductReviewsMultisourceSEO({
    sku,
    locale = "en-GB",
    theme = "light",
    width = "100%",
    height = "500px"
}: Props) {

    if (!sku) {
        console.warn("TrustpilotProductReviewsMultisourceSEO: SKU is required")
        return null
    }

    return (
        <TrustpilotWidget
            className="trustpilot-widget"
            data-locale={locale}
            data-template-id="5763bccae0a06d08e809ecbb"
            data-theme={theme}
            data-sku={sku}

            data-style-height={height}
            data-style-width={width}
            data-fullwidth="true"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-style-alignment="center"
        />
    )
}
