import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useMemo } from "react";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { ButtonProps } from "../../websiteTemplates/Default/Button";

export default function Button(props: ButtonProps) {
    const salesChannel = useSalesChannel();
    return <GetComponent template={salesChannel?.websiteTemplate} component="Button" props={props} />
}
