import React from "react";
import Layout from "../../components/layouts/Layout";
import CartPage from "../../components/checkout/CartPage";

export default function ShoppingCartPage() {
    return (
        <Layout>
            <CartPage />
        </Layout>
    );
}
