import * as React from "react";
import { useState, useEffect } from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import GetComponent from "../../../websiteTemplates/_GetComponent";
import { HeaderProps } from "../../../websiteTemplates/Default/Header";
import { ImageDataLike } from "gatsby-plugin-image";
import { Menu } from "../Footer";
import _ from "lodash";

interface Props {
    pageTemplate: any;
    salesChannel: ISalesChannel;
    menuItems: {
        nodes: any[];
    }
}

const Header = ({ pageTemplate, salesChannel, menuItems }: Props) => {

    const menuItemDict = _.groupBy(menuItems.nodes, 'menu');

    const logoFile = pageTemplate?.headerLogoFile as {
        node: ImageDataLike;
    }

    const headerMenus = pageTemplate?.header?.menus || [];
    const menus: Menu[] = headerMenus.map(menu => {
        return {
            ...menu,
            items: (menuItemDict[menu.id] || [])
                .sort((a, b) => (a.sort || 0) - (b.sort || 0))
        }
    });

    const windowSize = useWindowSize();

    const [isSticky, setIsSticky] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsSticky(scrollTop > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isMobile = React.useMemo(() => {
        return (windowSize?.width || 0) < 768;
    }, [windowSize]);

    const headerProps: HeaderProps = {
        salesChannel,
        logoFile,
        isSticky,
        isDrawerVisible,
        setIsDrawerVisible,
        isMobile,
        windowSize,
        menus
    }

    const template = salesChannel?.websiteTemplate;

    return (
        <GetComponent template={template} component="Header" props={headerProps} />
    );
};

export default Header;
