import React from "react";
import { Link, navigate } from "gatsby";

import Button from "../../../../shared/Button";
import { mdiAccount, mdiAccountCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { getUser } from "../../../../../store/auth";
import { useSalesChannel } from "../../../../../context/SalesChannelContext";
import { useTrackingContext } from "../../../../../context/tracking";

export default function HeaderAccount() {
    const dispatch = useDispatch<AppDispatch>();
    const { identifyEvent } = useTrackingContext();
    const salesChannel = useSalesChannel();

    const user = useSelector((state: RootState) => state?.auth?.user);

    React.useEffect(() => {
        load();
    }, []);

    React.useEffect(() => {
        if (user?.email) {
            identifyEvent({
                email: user.email,
                firstName: user?.given_name,
                lastName: user?.family_name,
            })
        }
    }, [user?.email]);

    const load = async () => {
        dispatch(getUser());
    };

    const handleClick = async (e: any) => {
        e.preventDefault();
        navigate(user != undefined ? "/account" : "/login");
    };

    return (
        <div className="flex items-center justify-center flex-row"
        >
            <Button
                title="Go to My Account Page"
                padding="px-0"
                type="transparent"
                onClick={handleClick}
            >
                <Icon color={"white"} path={mdiAccountCircleOutline} size={1} />
            </Button>
        </div>
    );
}
