import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ReviewType } from "../../../../types";
import { ReviewListProps } from "../../Default/ReviewsList";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { BsStar, BsStarFill } from "react-icons/bs";
import ReviewItem from "./ReviewItem";

const ReviewList = ({ reviews }: ReviewListProps) => {
    return (
        <div className="-mx-3">
            <Splide
                options={{
                    autoplay: false,
                    type: "loop",
                    perPage: 3,
                    pagination: false,
                    padding: 100,
                    breakpoints: {
                        640: {
                            perPage: 1,
                            type: "loop",
                            padding: 50,
                            arrows: false,
                        }
                    }
                }}
            >
                {reviews?.map((review) => (
                    <SplideSlide key={review?.id}>
                        <div className="px-4" >
                            <ReviewItem review={review} />
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
            
        </div>
    );
};

export default ReviewList;
