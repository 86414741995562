import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useMemo } from "react";

export type ButtonProps = {
    children: React.ReactNode;
    type?: "primary" | "secondary" | "tertiary" | "transparent" | string;
    className?: string;
    textSize?: string;
    disabled?: boolean;
    padding?: string;
    loader?: boolean;
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function Button({
    children,
    type = "primary",
    className,
    padding = "py-2 px-5",
    textSize = "text-xl",
    disabled,
    title,
    loader = false,
    onClick
}: ButtonProps) {
    disabled = disabled === null ? false : disabled;

    const textClass = useMemo(() => {
        switch (type) {
            case "secondary":
            case "tertiary":
                return "text-white";
            default:
                return "text-dark";
        }
    }, [type]);

    const disabledClass = useMemo(() => {
        return disabled ? "opacity-50" : "";
    }, [disabled]);

    const child = () =>{
        if(type === "underline"){
            return <span className="sco-underline">{children}</span>
        }
        return children;
    }

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            title={title}
            className={`${textClass} bg-${type} ${disabledClass} ${padding} ${textSize} 
                font-extrabold relative flex justify-center items-center rounded 
                ${className}`}
        >
            {child()}
            {loader && (
                <div className="absolute top-0 left-2 h-full flex items-center">
                    <Icon spin={0.8} path={mdiLoading} size={0.75} />
                </div>
            )}
        </button>
    );
}
