import React from "react";

export enum AccountTabTypes {
    ORDERS = "ORDERS",
    ADDRESSES = "ADDRESSES",
    GENERAL = "GENERAL",
    CREDIT = "CREDIT"
}

const TABS = [
    {
        name: AccountTabTypes.GENERAL,
        label: "General"
    },
    {
        name: AccountTabTypes.ORDERS,
        label: "Orders"
    },
    {
        name: AccountTabTypes.ADDRESSES,
        label: "Address Book"
    },
    {
        name: AccountTabTypes.CREDIT,
        label: "Referrals & Credit"
    },
];

interface Props {
    tab: AccountTabTypes;
    setTab: (tab: AccountTabTypes) => void;
}

export default function AccountTabs({ tab, setTab }: Props) {
    return (
        <div className="overflow-auto md:overflow-hidden">
            <nav className="flex flex-nowrap flex-row md:flex-col whitespace-nowrap">
                {TABS.map(t => (
                    <button
                        className={`
                            cursor-pointer block px-4 py-4 border-b-4 font-bold border-white text-left
                            md:border-b-0 md:border-l-4
                            ${tab == t.name ? "bg-primary-50 border-primary" : ""}`
                        }
                        onClick={() => setTab(t.name)}
                    >
                        {t.label}
                    </button>
                ))}
            </nav>
        </div>
    );
}
