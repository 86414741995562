import React from "react";

interface IFrameProps {
    data: {
        url: string;
        maxHeight: string | number;
        maxWidth: string | number;
        className?: string;
    };
}

export default function IFrame({ data }: IFrameProps) {
    if (!data) {
        return null;
    }
    const { url, maxWidth, maxHeight, className } = data;
    return (
        <iframe
            src={url}
            height={`${maxHeight}px`}
            width={`${maxWidth}px`}
            className={className ?? "mx-auto"}
        ></iframe>
    );
}
