import React, { useEffect } from 'react'
import Loading from '../../components/shared/Loading';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function redirect() {

    const orderId = useSelector((state: RootState) => state.order?.order?.id);

    // get query params
    const urlParams = new URLSearchParams(window.location.search);
    const redirect_status = urlParams.get('redirect_status');

    useEffect(() => {
        setTimeout(() => {
            if (orderId && redirect_status) {
                if (redirect_status === 'succeeded') {
                    navigate("/order/" + orderId);
                    return;
                }
                navigate("/checkout");
            }
        }, 3000);
    }, [redirect_status, orderId])

    return (
        <Loading />
    )
}
