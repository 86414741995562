import axios from "axios";
import { AddressType } from "../types";

export const validatePhoneNumber = async (phoneNumber: string, loqate: any) => {
    if (!phoneNumber) {
        return { valid: false, message: 'Phone number is required.' };
    }
    try {
        // HH: Stefan has requested that the loqate validation be turned off as loqate is charging a lot of money for it.
        throw new Error("loqate validation is disabled");
        if (!phoneNumber.startsWith("+")) {
            return { valid: false, message: 'Phone number must start with a +.' }
        }
        const loqateResponse = await axios.get('https://api.addressy.com/PhoneNumberValidation/Interactive/Validate/v2.20/json3.ws', {
            params: {
                Phone: phoneNumber,
                Key: loqate?.config?.find(c => c.property === 'apiKey')?.value
            }
        })
        if (loqateResponse?.data?.Items?.[0]?.IsValid === "No") {
            return { valid: false, message: 'Phone number is invalid.' }
        }
        if (loqateResponse?.data?.Items?.[0]?.NumberType !== "Mobile") {
            return { valid: true, message: 'We recommend providing a mobile number.' }
        }
        return { valid: true }
    } catch (e) {
        console.error("loqate call failed, falling back on custom validation");

        // Logic for any valid phone number
        if (!phoneNumber.match(/^(0)/) && !phoneNumber.match(/^(\+44)/)) {
            return { valid: false, message: 'Phone number must start with a 0 or +44.' }
        }
        if (phoneNumber.length < 11) {
            return { valid: false, message: 'Phone number must be at least 11 characters.' }
        }

        // Logic for a valid landline
        if (phoneNumber.match(/^(0)(1|2)/) || phoneNumber.match(/^(\+44)(1|2)/)) {
            return { valid: true, message: 'We recommend providing a mobile number.' }
        }

        // Logic for a valid mobile
        if (!phoneNumber.match(/^(0)(7)/) && !phoneNumber.match(/^(\+44)(7)/)) {
            return { valid: false, message: 'Phone number must start with 07.' }
        }

        return { valid: true };
    }
}

const validatePostcode = async (postCode: string | undefined, loqate: any) => {
    if (!postCode) {
        return { valid: false, message: "Postcode is required." };
    }
    if (!postCode.match(/[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}/i)) {
        return { valid: false, message: "Invalid postcode." };
    }
    try {
        const loqateResponse = await axios.post('https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws', {
            Addresses: [{ Country: "GB", PostalCode: postCode }],
            Key: loqate?.config?.find(c => c.property === 'apiKey')?.value
        })
        if (!loqateResponse?.data?.[0] || !loqateResponse?.data?.[0]?.Matches) {
            throw new Error("Loqate response failed");
        }
        if (!loqateResponse?.data?.[0]?.Matches?.[0]) {
            throw new Error("Loqate response failed");
        }
        return { valid: true, update: loqateResponse?.data?.[0]?.Matches?.[0].PostalCode }
    } catch (e) {
        console.error("loqate call failed.");
        return { valid: true };
    }
}

export const validateAddress = async (address: AddressType, loqate: any) => {
    let messages: string[] = [];

    const postcodeValidationResponse = await validatePostcode(address.postCode, loqate);

    if (!postcodeValidationResponse.valid && postcodeValidationResponse.message) {
        messages = [...messages, postcodeValidationResponse.message];
    }

    if (postcodeValidationResponse.valid && postcodeValidationResponse.update) {
        address.postCode = postcodeValidationResponse.update;
    }

    return { valid: postcodeValidationResponse.valid, messages: messages };
}