import React from "react";
import { Link } from "gatsby";
import { BsList } from "react-icons/bs";
import HeaderAccount from "../../../../components/layouts/Layout/Header/Account";
import HeaderCart from "../../../../components/layouts/Layout/Header/Cart";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";
import { Menu } from "../../../layouts/Layout/Footer";

export interface HeaderProps {
    salesChannel: ISalesChannel;
    logoFile?: {
        node: ImageDataLike;
    };
    isSticky: boolean;
    isDrawerVisible: boolean;
    setIsDrawerVisible: (value: boolean) => void;
    isMobile: boolean;
    windowSize: { width?: number; height?: number };
    menus: Menu[];
}

export default function Header({
    salesChannel,
    logoFile,
    isSticky,
    isDrawerVisible,
    setIsDrawerVisible,
    isMobile,
    windowSize,
    menus
}: HeaderProps) {

    const firstMenu = menus?.[0]
    const secondMenu = menus?.[1];

    const height = !secondMenu ? 80 : 120;

    return (
        <>
            <header
                style={{
                    height: isSticky ? 80 : height,
                    transition: "height 0.3s"
                }}
                className={`${isSticky ? "sticky" : ""} top-0 left-0 w-full bg-secondary z-40`}
            >
                {secondMenu && (
                    <div className="flex items-center justify-center h-[40px] bg-red-600 space-x-3"
                        style={{ height: isSticky ? 0 : 40, transition: "height 0.3s", overflow: "hidden" }}
                    >
                        {secondMenu?.items?.sort((a, b) => a.sort - b.sort)?.map(menuItem => (
                            <Link
                                key={menuItem.id}
                                to={`${menuItem.link?.src}`}
                                className="text-white font-semibold uppercase text-sm px-2 py-1"
                            >
                                {menuItem.name}
                            </Link>

                        ))}
                    </div>
                )}
                <div className="container max-w-screen-2xl mx-auto">
                    <div className="flex justify-between md:justify-start items-center -mx-2">

                        <div className="px-2 flex w-[130px] md:w-[500px]">
                            <button
                                title={isDrawerVisible ? "Close Menu" : "Open Menu"}
                                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                                className="flex items-center px-3 text-white md:hidden"
                            >
                                <BsList className="w-[25px] h-[25px]" />
                            </button>
                            {!isMobile && (
                                <Link title="Home" to="/">
                                    <HeaderLogo
                                        logoFile={logoFile}
                                        alt={salesChannel.name + " Logo"}
                                        maxHeight={`${height - 20}px`}
                                    />
                                </Link>
                            )}
                        </div>

                        {isMobile && (
                            <div className="flex-1 px-2">
                                <Link className="block max-h-full" title="Home" to="/">
                                    <HeaderLogo
                                        logoFile={logoFile}
                                        alt={salesChannel.name + " Logo"}
                                        maxHeight={`${height - 20}px`}
                                    />
                                </Link>
                            </div>
                        )}

                        <div
                            style={{
                                top: isDrawerVisible || !isMobile ? 0 : "-100%",
                                opacity: isDrawerVisible || !isMobile ? 1 : 0
                            }}
                            className="
                                flex justify-end fixed left-0 w-full h-full bg-black bg-opacity-50 z-30
                                md:static md:h-auto md:bg-transparent
                                md:px-3 md:grow transition-[opacity] md:transition-none md:justify-center
                            "
                            onClick={() => setIsDrawerVisible(false)}
                        >
                            <ul
                                style={{
                                    width: !isMobile ? "auto" : "calc(100% - 60px)",
                                    right: isDrawerVisible || !isMobile ? 0 : "-100%"
                                }}
                                className="
                                relative flex flex-col h-full bg-secondary py-[50px]
                                md:h-auto md:bg-transparent md:p-0 md:flex-row md:w-auto transition-[right] md:transition-none
                            "
                            >
                                <li>
                                    <span
                                        className="
                                        block px-8 pb-5
                                        md:hidden
                                    "
                                    >
                                        <span className="bg-primary font-bold uppercase text-sm px-2 py-1 rounded">
                                            Menu
                                        </span>
                                    </span>
                                </li>
                                {firstMenu?.items?.sort((a, b) => a.sort - b.sort)?.map(menuItem => (
                                    <li key={menuItem.id} className="">
                                        <Link
                                            to={`${menuItem.link?.src}`}
                                            className="
                                                block px-8 py-[13px] text-xl text-white font-semibold uppercase
                                                md:px-5 md:py-2 md:text-base md:font-bold md:tracking-wider
                                            "
                                        >
                                            {menuItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="flex justify-end w-[130px] md:w-[500px] space-x-4">
                            <HeaderAccount />
                            <HeaderCart />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

const HeaderLogo = ({ logoFile, alt, maxHeight }: { logoFile?: { node: ImageDataLike }, alt: string, maxHeight: string }) => {

    if (logoFile) {
        const gatsbyImage = getImage(logoFile);
        if (gatsbyImage) {
            return <GatsbyImage
                image={gatsbyImage}
                alt={alt}
                style={{
                    position: "relative",
                    maxHeight,
                }}
                imgStyle={{
                    objectFit: "contain",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
            />;
        }
    }

    return <></>

}