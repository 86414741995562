import React, { useEffect, useMemo, useState } from 'react'
import { ProductDataType, ProductType } from '../../../../../types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import ecommerceApi from '../../../../../services/ecommerceApi';
import { useSalesChannel } from '../../../../../context/SalesChannelContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { formatPrice, getPriceAsNumber, getPriceIncTax, getPriceToShow, round2dp } from '../../../../../utils/currency';
import { constructProductMetricsHtml, templateProductMetricHtml } from '../../../../../utils/html';
import Color from 'color';

interface Props {
    selectedProduct: ProductType;
    product: ProductType;
    handleProductClick: (product: ProductType) => (e: any) => void;
}

export default function SelectProductItem({ selectedProduct, product, handleProductClick }: Props) {

    const salesChannel = useSalesChannel();

    const [productData, setProductData] = useState<ProductDataType | undefined>();
    const [taxCodes, setTaxCodes] = useState([]);
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );

    const loadProductData = async () => {
        if (!product) return;

        const data = await ecommerceApi.getProductData(product.id);
        const taxCodes = await ecommerceApi.getProductTaxCodesData(product.id);

        setProductData(data || undefined);
        setTaxCodes(taxCodes || []);
    };

    const pricing = useMemo(() => {

        if (!productData) return null;

        const priceVal = getPriceAsNumber(productData.currentPrice, salesChannel, currencyCode);
        const listPriceVal = productData.listPrice ? getPriceAsNumber(productData.listPrice, salesChannel, currencyCode) : undefined;

        const price = formatPrice(
            priceVal,
            currencyCode
        );

        const listPrice = listPriceVal ? formatPrice(listPriceVal) : undefined;

        let diff = listPriceVal ? round2dp(listPriceVal - priceVal) : undefined;

        return {
            priceVal,
            price,
            listPriceVal,
            listPrice,
            diff: diff ? formatPrice(diff) : undefined,
            metrics: constructProductMetricsHtml(product, {
                ...productData,
                currentPrice: priceVal,
                listPrice: listPriceVal
            }, salesChannel, currencyCode)
        };

    }, [productData, taxCodes, currencyCode, salesChannel.metadata]);

    useEffect(() => {
        loadProductData();
    }, [product?.id]);

    const isSelected = selectedProduct.id === product.id;
    const parsedFlag = product.flagBySalesChannel ? JSON.parse(product.flagBySalesChannel) : {};
    const flagText = parsedFlag[salesChannel.id]?.label || "";
    const flagColour = parsedFlag[salesChannel.id]?.color === "primary" ? salesChannel.primaryColour : parsedFlag[salesChannel.id]?.color === "secondary" ? salesChannel.secondaryColour : parsedFlag[salesChannel.id]?.color

    return <div
        key={`PRODUCT_ITEM_${product.id}`}
        className={`
            px-1 w-1/2 mb-2 
            md:w-full md:mb-0
        `}
    >
        <div className={`
            cursor-pointer px-3 py-3 rounded-md
            relative
            ${flagText ? "md:mt-5" : "md:mt-0"}
            mt-3
        `}
            style={{
                borderColor: isSelected ? salesChannel.secondaryColour : "",
                borderWidth: isSelected ? "4px" : "2px"
            }}
            onClick={handleProductClick(product)}>
            {flagText && <div className='absolute top-[-12px] flex justify-center w-full left-0 md:justify-start md:left-5'>
                <div style={{ backgroundColor: flagColour }} className={`py-0.5 px-4 text-sm w-fit ${isSelected ? "border-x-solid border-x-2" : ""} uppercase font-bold border-white rounded-md ${Color(flagColour).isDark() ? "text-white" : "text-black"}`}>
                    <p>{flagText}</p>
                </div></div>}
            <div className="
            text-center
            mt-3
            md:mt-0
            md:flex md:items-center md:space-x-5 md:text-left">
                <div>
                    {!!product?.imageFile ? (
                        <GatsbyImage
                            image={getImage(product.imageFile) as any}
                            alt={product.SKUName}
                            className="w-14 h-14"
                        />
                    ) : (
                        <div className="w-14 h-14 bg-gray-200 animate-cyclic-pulse mx-auto"></div>
                    )}
                </div>
                <div className="flex-1 mt-2 md:mt-0">
                    <div className="md:space-y-1">
                        <h3 className="text-xl normal-case">Buy {product.shortName || product.SKUName} {product.listPrice && (product.listPrice > product.currentPrice) ? `& Get ${((1 - (product.currentPrice / product.listPrice)) * 100).toFixed()}% OFF` : ""}</h3>
                        <h4 className=" text-lg gap-2 flex flex-row items-baseline flex-wrap justify-center space-y-0 md:justify-start md:flex-row">
                            {pricing?.listPrice && (<span className="md:mr-2 line-through font-normal">{pricing?.listPrice}</span>)}
                            {pricing?.price ? pricing?.price : <span className='inline-block w-20 h-3 bg-gray-200 animate-cyclic-pulse' />}

                            <div className='mt-1 md:mt-0'>
                                {productData ? (
                                    <div
                                        key={`PRODUCT_METRICS`}
                                        className='text-sm min-h-[19px]'
                                        dangerouslySetInnerHTML={{ __html: pricing?.metrics || "" }}
                                    />
                                ) : (
                                    <div className='min-h-[19px] w-full max-w-[400px] bg-gray-200 animate-pulse'></div>
                                )}
                            </div>

                            {pricing?.diff && (
                                <span className='
                            inline-block
                            px-2
                            py-1
                            bg-red-600
                            text-white
                            rounded-full
                            font-normal
                            capitalize
                            text-xs
                            md:text-[13px]
                            md:ml-2
                        '>
                                    Save <strong> {pricing?.diff} </strong>
                                </span>
                            )}
                        </h4>
                    </div >
                </div >
                <div
                    className='
                    absolute top-4 left-2
                    md:static
                    '
                >
                    {isSelected ? (
                        <MdOutlineRadioButtonChecked size={24} className='text-primary' />
                    ) : (
                        <MdOutlineRadioButtonUnchecked size={24} />
                    )}
                </div>
            </div >
        </div >
    </div >
}
