import React from "react"
import Spinner from "../Spinner"

export default function Loading() {
  return (
    <div className={"absolute top-0 left-0 w-full h-full bg-white/70 flex justify-center items-center z-40"}>
      <Spinner width="60px" height="60px" />
    </div>
  )
}
