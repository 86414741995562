import { createContext, useContext } from "react";
import { ISalesChannel } from "../services/ecommerceApi/SalesChannel/types";
import { useEcommerceHelper } from "./ecommerce";

interface SalesChannelContextProps {
    salesChannel: ISalesChannel;
}

const SalesChannelContext = createContext<SalesChannelContextProps | null>(null);

export const useSalesChannel = () => {
    const { setSalesChannel } = useEcommerceHelper();

    const context = useContext(SalesChannelContext);
    if (!context) {
        throw new Error("useSalesChannel must be used within a SalesChannelContextProvider");
    }

    if (context.salesChannel) {
        setSalesChannel(context.salesChannel);
    }

    return context.salesChannel;
};

export default SalesChannelContext;
