import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { setStep } from "../../../../../store/order";
import { navigate } from "gatsby";

interface IStep {
    title: string;
    step: string;
}

const STEPS: IStep[] = [
    {
        title: "Cart",
        step: "cart"
    },
    {
        title: "Customer Details",
        step: "customerDetails"
    },
    {
        title: "Shipping",
        step: "shipping"
    },
    {
        title: "Review",
        step: "shipments"
    },
    {
        title: "Payment",
        step: "payment"
    }
];

export default function CheckoutProgression() {
    const dispatch = useDispatch<AppDispatch>();

    const order = useSelector((state: RootState) => state.order.order);
    const user = useSelector((state: RootState) => state.auth.user);
    const currentStep = useSelector((state: RootState) => state.order.stepOpen);

    const orderIsShipped = order?.isShipped;

    const isStepSelected = (step: IStep) => {
        return step.step === currentStep;
    };

    const isStepDisabled = (step: IStep) => {

        const noOrderItems = (order?.orderItems?.length || 0) === 0;
        const shipmentsInvalid = order?.shipmentsInvalid;
        const noShipments = (order?.shipments?.length || 0) === 0 || order?.shipmentsCount === 0;
        const noCustomer = !order?.customer;

        switch (step.step) {
            case "payment":
                return noOrderItems || shipmentsInvalid || noShipments;
            case "shipping":
                return noOrderItems || noCustomer;
            case "shipments":
                return noOrderItems || noCustomer || (orderIsShipped && noShipments);
        }

        return false;
    };

    const isStepVisible = (step: IStep) => {
        switch (step.step) {
            case "shipping":
            case "shipments":
                return order?.isShipped;
            case "customerDetails":
                return !user;
        }
        return true;
    };

    const handleClick = (step: IStep) => async (e: any) => {
        if (step.step === "cart") {
            navigate("/cart");
            return;
        }
        dispatch(setStep(step.step));
    };

    const steps = useMemo(() => {
        return STEPS.filter(isStepVisible);
    }, [order, user]);

    return (
        <nav
            className="
                flex -mx-1 uppercase font-bold text-xs
                md:text-sm md:-mx-2
            "
        >
            {steps.map((step, i) => {
                return (
                    <React.Fragment key={`${step.step.toString()}-${i}`}>
                        {i > 0 && (
                            <div
                                key={step.step + "_arrow"}
                                className="flex items-center text-gray-400 px-1 md:px-2"
                            >
                                <svg
                                    className="w-4 h-4"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    ></path>
                                </svg>
                            </div>
                        )}
                        <button
                            key={step.step}
                            disabled={isStepDisabled(step)}
                            className={`uppercase block cursor-pointer px-1 md:px-2 ${isStepSelected(step) ? "text-secondary" : "text-gray-400"}`}
                            onClick={handleClick(step)}
                        >
                            {step.title}
                        </button>
                    </React.Fragment>
                );
            })}
        </nav>
    );
}
