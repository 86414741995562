import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";
import { Menu, MenuItem } from "../../../layouts/Layout/Footer";
import * as BootstrapIconsMap from "react-icons/bs";

export interface FooterProps {
    salesChannel: any;
    socials: any[];
    logoFile?: {
        node: ImageDataLike;
    };
    menus: Menu[];
    footerLeftTitle: string;
    footerLeftContent: string;
}

export default function Footer({ salesChannel, logoFile, menus, footerLeftTitle, footerLeftContent }: FooterProps) {

    const firstMenu = menus[0];
    const secondMenu = menus[1];
    const thirdMenu = {
        displayName: "Contact Us",
        ...(menus?.[2] as any),
        items: [
            {
                id: "contact-phone",
                link: { src: `tel:${salesChannel.phone}` },
                name: salesChannel.phone,
                sort: -5,
                icon: "BsFillTelephoneFill",
            },
            {
                id: "contact-email",
                link: { src: `mailto:${salesChannel.email}` },
                name: salesChannel.email,
                sort: -4,
                icon: "BsEnvelopeFill",
            },
            {
                id: "contact-address",
                link: {},
                name: salesChannel.address,
                sort: -3,
                icon: "BsGeoAltFill",
            },
            ...(menus[2]?.items || [])
        ]
    }
    const fourthMenu: Menu | undefined = menus[3];

    const data = useStaticQuery(graphql`
        query {
            integration(type: {eq: "klaviyo"}){
                config{
                    property
                    propertyId
                    type
                    value
                }
            }
        }
    `);

    const klaviyoConfig = data?.integration?.config || [];
    const subscribeFormId = klaviyoConfig.find(p => p.property == "subscribeFormId")?.value;

    const createMenuItemRender = (menuItem: MenuItem, className?: string) => {
        const to = menuItem.link.src ? menuItem.link.src.startsWith("/") ? menuItem.link.src : `/${menuItem.link.src}` : undefined;
        const isHref = menuItem.link.src ? (menuItem.link.src.startsWith("mailto:") || menuItem.link.src.startsWith("tel:")) : false;
        const menuItemHasIcon = menuItem.icon;
        const SelectedIcon = menuItemHasIcon ? BootstrapIconsMap[menuItem.icon] : undefined;
        const render = <div className="flex flex-row gap-4">
            {SelectedIcon && <SelectedIcon className={"min-w-5"} size={20} style={{ color: menuItem.iconColor }} />}{menuItem.name}
        </div>

        if (isHref) {
            return (
                <li key={menuItem.id}>
                    <a className={`block ${className}`} style={
                        menuItemHasIcon ? { paddingTop: "8px", paddingBottom: "8px" } : { paddingTop: "4px", paddingBottom: "4px" }
                    } href={menuItem.link.src}>
                        {render}
                    </a>
                </li>
            )
        }

        if (to) {
            return (
                <li key={menuItem.id}>
                    <Link className={`block ${className}`} style={
                        menuItemHasIcon ? { paddingTop: "8px", paddingBottom: "8px" } : { paddingTop: "4px", paddingBottom: "4px" }
                    } to={to}>
                        {render}
                    </Link>
                </li>
            )
        }

        return <div style={
            menuItemHasIcon ? { paddingTop: "8px", paddingBottom: "8px" } : { paddingTop: "4px", paddingBottom: "4px" }
        }>
            {render}
        </div>;

    }

    return (
        <footer className="">
            <section className="bg-white text-secondary">
                <div className="container max-w-screen-xl mx-auto">
                    <div className="flex flex-wrap -mx-3 py-16">
                        {/* OF: removed this temporarily */}
                        {/* <div className="w-full md:w-1/4 px-3 md:order-4">
                            {subscribeFormId && (
                                <>
                                    <h2 className="text-lg uppercase mt-6 mb-3">Join the Club</h2>
                                    <p className="mb-4">
                                        Join thousands of other members and get the latest deals and offers.
                                    </p>
                                    <div className={`klaviyo-form-${subscribeFormId}`}></div>
                                </>
                            )}
                        </div> */}
                        <div className="w-full md:w-4/12 px-3">
                            <h2 className="text-2xl uppercase mb-3  mt-6 ">{footerLeftTitle}</h2>
                            <span>{footerLeftContent}</span>
                        </div>
                        <div className="w-full md:w-1/12 px-3" />
                        <div className="w-1/2 md:w-2/12 px-3">
                            {firstMenu && (
                                <>
                                    <h2 className="text-2xl uppercase mb-3  mt-6 ">{firstMenu.displayName}</h2>
                                    <ul>
                                        {firstMenu.items.sort((a, b) => a.sort - b.sort).map(menuItem => createMenuItemRender(menuItem))}
                                    </ul>
                                </>
                            )}
                        </div>
                        <div className="w-1/2 md:w-2/12 px-3">
                            {secondMenu && (
                                <>
                                    <h2 className="text-2xl uppercase mb-3  mt-6 ">{secondMenu.displayName}</h2>
                                    <ul>
                                        {secondMenu.items.sort((a, b) => a.sort - b.sort).map(menuItem => createMenuItemRender(menuItem))}
                                    </ul>
                                </>
                            )}
                        </div>
                        <div className="w-full md:w-3/12 px-3">
                            {thirdMenu && (
                                <>
                                    <h2 className="text-2xl uppercase mb-3  mt-6 ">{thirdMenu.displayName}</h2>
                                    <ul>
                                        {thirdMenu.items.sort((a, b) => a.sort - b.sort).map(menuItem => createMenuItemRender(menuItem))}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                    <hr className="border-t border-[#d4cfdb]" />
                </div>
            </section>
            <section className="py-6">
                <div className="container max-w-screen-xl mx-auto">
                    <div className="
                        flex flex-col items-center
                        md:justify-between md:flex-row
                    ">
                        <div className="px-3 flex justify-center">
                            <Link className="" title="Home" to="/">
                                <FooterLogo logoFile={logoFile} alt={salesChannel.name + " Logo"} />
                            </Link>
                        </div>
                        <div className="px-3 flex-1 md:w-auto">
                            <div className="
                                flex flex-wrap items-center justify-center
                                md:justify-end md:space-x-5
                            ">
                                {fourthMenu && (
                                    <div className="flex justify-end">
                                        <ul className="flex gap-4">
                                            {fourthMenu.items.sort((a, b) => a.sort - b.sort).map(menuItem => createMenuItemRender(menuItem))}
                                        </ul>
                                    </div>
                                )}
                                <div className="hidden md:block">
                                    |
                                </div>
                                <p className="
                                    w-full text-center mt-4 mb-3
                                    md:w-auto md:text-right md:mt-0 md:mb-0">
                                    Copyright {new Date().getFullYear()}. All Rights Reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}

const FooterLogo = ({ logoFile, alt }: { logoFile?: { node: ImageDataLike }, alt: string }) => {

    if (logoFile) {
        const gatsbyImage = getImage(logoFile);
        if (gatsbyImage) {
            return <GatsbyImage
                image={gatsbyImage}
                alt={alt}
                style={{
                    maxWidth: "150px"
                }}
            />;
        }
    }

    return <></>

}