import React from "react";
import { ProductCategoryType } from "../../../types";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { useSalesChannel } from "../../../context/SalesChannelContext";

const ProductList = ({ productCategory }: { productCategory: ProductCategoryType }) => {
    const { websiteTemplate } = useSalesChannel();
    return (
        <GetComponent
            template={websiteTemplate}
            component="ProductList"
            props={{
                productCategory
            }}
        />
    );
};

export default ProductList;
