import React, { useMemo } from "react";

import { CardElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { useDispatch, useSelector } from "react-redux";
import EcommerceService from "../../../../../../services/ecommerceApi";
import { navigate } from "gatsby";
import { CARD_OPTIONS } from "./constants";
import Spinner from "../../../../../shared/Spinner";
import Button from "../../../../../shared/Button";
import { AppDispatch, RootState } from "../../../../../../store";
import { jsonParseString } from "../../../../../../utils/string";

export default function StripeForm({ paymentGatewayId, handlePlaceOrder, config }) {

    const dispatch = useDispatch<AppDispatch>();
    const orderStore = useSelector((state: RootState) => state.order);

    const stripe = useStripe();
    const elements = useElements();

    const order = orderStore.order;

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!order || !order.id || !elements || !stripe) {
            setError("Something went wrong");
            return;
        }

        // check can be placed
        const checkedOrder = await EcommerceService.checkOrderCanBePlaced(order.id, paymentGatewayId, {});

        if (!checkedOrder?.id) {
            setError("Something went wrong");
            return;
        }

        if (checkedOrder.errors && checkedOrder.errors.length > 0) {
            // OF: handle place order displays errors if order.errors exists
            handlePlaceOrder(checkedOrder);
            return;
        }

        switch (type) {
            case "Card":
                return handleCardPayment();
            case "Payment":
                return handlePayment();
        }

    };

    const handlePayment = async () => {
        setLoading(true);

        if (!order || !order.id || !elements || !stripe) {
            setError("Something went wrong");
            return;
        }

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.origin + "/stripe/return/",
            }
        });

        if (response.error) {
            setError(response.error.message || "Something went wrong");
            setLoading(false);
            return;
        }
    }

    const handleCardPayment = async () => {
        setLoading(true);

        if (elements == null || stripe == null) {
            return;
        }
        if (!order || !order.id) {
            setError("Something went wrong");
            return;
        }

        const metadata = jsonParseString(order.metadata);

        if (!metadata?.stripe || !metadata.stripe.paymentIntentClientSecret) {
            setError("Something went wrong");
            return;
        }

        const paymentIntentResponse = await stripe.confirmCardPayment(metadata.stripe.paymentIntentClientSecret, {
            payment_method: {
                card: elements.getElement(CardElement) ?? { token: "" },
                billing_details: {
                    name:
                        order.billingAddress?.firstName +
                        " " +
                        order.billingAddress?.lastName,
                    email: order.customer.email,
                    address: {
                        city: order.billingAddress?.city,
                        country: order.billingAddress?.countryCode,
                        line1: order.billingAddress?.line1,
                        line2: order.billingAddress?.line2,
                        postal_code: order.billingAddress?.postCode,
                        state: order.billingAddress?.state,
                    },
                },
            },
        });

        if (!paymentIntentResponse.error) {

            const response = await EcommerceService.placeOrder(order.id, paymentGatewayId, {
                paymentGatewayType: "stripe"
            });

            await handlePlaceOrder(response);

            setLoading(false);

        } else {
            setError(paymentIntentResponse.error.message || "Something went wrong");
        }

        setLoading(false);
    }

    const type = useMemo(() => {
        if (config?.["elementType"]) {
            return config["elementType"];
        }
        return config["elementType"] || "Card";
    }, [config]);

    return (
        <form className="pb-10">
            <fieldset>
                <div className="p-5 bg-gray-100">
                    {type === "Card" && <CardElement options={CARD_OPTIONS} onChange={e => setError(e?.error?.message ?? null)} />}
                    {type === "Payment" && <PaymentElement options={{
                        defaultValues: {
                            billingDetails: {
                                name: order?.billingAddress?.firstName + " " + order?.billingAddress?.lastName,
                                email: order?.customer.email,
                                address: {
                                    city: order?.billingAddress?.city,
                                    country: order?.billingAddress?.countryCode,
                                    line1: order?.billingAddress?.line1,
                                    line2: order?.billingAddress?.line2,
                                    postal_code: order?.billingAddress?.postCode,
                                    state: order?.billingAddress?.state,
                                },
                            }
                        },
                    }} />}
                </div>
            </fieldset>
            {error && <div className="text-red-500 text-sm mt-3">{error}</div>}
            <Button className="w-full mt-4 bg-orange-500 text-white" onClick={handleSubmit}>
                Pay
                {loading && <Spinner className="ms-3" />}
            </Button>
        </form>
    );
}