import React from 'react'
import AirwallexWrapper from './AirwallexContext'
import FullyFeaturedCard from './DropInElement'
import { OrderType } from '../../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import AirwallexProvider from './AirwallexContext';
import DropInElement from './DropInElement';
import { navigate } from 'gatsby';
import ecommerceApi from '../../../../../../services/ecommerceApi';
import { IPaymentGateway } from '..';

type Props = {
    paymentGateway: IPaymentGateway;
    handlePlaceOrder: (order: OrderType) => void;
}

export default function Airwallex({ paymentGateway, handlePlaceOrder }: Props) {

    const order = useSelector((state: RootState) => state.order.order);

    const checkOrder = async () => {

        if (!order) {
            return;
        }

        const checkedOrder = await ecommerceApi.checkOrderCanBePlaced(order.id, paymentGateway.id, {})

        if (!checkedOrder?.id) {
            throw new Error("Something went wrong");
        }

        if (checkedOrder.errors && checkedOrder.errors.length > 0) {
            // OF: handle place order displays errors if order.errors exists
            handlePlaceOrder(checkedOrder);
            return;
        }
    }

    const onPendingVerifyAccount = (event: any) => {
        const detail = event.detail;
        console.log("Airwallex", "onPendingVerifyAccount", detail);
    }

    const onError = (event: any) => {
        const detail = event.detail;
        const error = detail.error;
        console.log("Airwallex", "onError", `[${error.code}] ${error.message}`, detail);
    };

    const onSuccess = (event: any) => {
        const detail = event.detail;
        navigate("/airwallex/return?redirect_status=succeeded");
    };

    const onSwitchMethod = (event: any) => {
        const detail = event.detail;
        checkOrder();
    }

    return (
        <AirwallexProvider
            paymentGateway={paymentGateway}
        >
            <DropInElement
                onSuccess={onSuccess}
                onError={onError}
                onPendingVerifyAccount={onPendingVerifyAccount}
                onSwitchMethod={onSwitchMethod}
            />
        </AirwallexProvider>
    )
}
