import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import type { PageProps } from "gatsby";

import { GatsbySeo } from "gatsby-plugin-next-seo";

import { BlockType } from "../../../types";

import Layout from "../../layouts/Layout";
import Block from "./Block";
import { ISalesChannel } from "../../../services/ecommerceApi/SalesChannel/types";
import { PageProvider } from "../context";

const IndexPage = ({ pageContext, data, location }: PageProps) => {

    const { pageTemplate, salesChannel } = data as {
        pageTemplate: any;
        salesChannel: ISalesChannel;
    };

    const { blocks, seo, title } = pageContext as any;

    const pageTitle = title ? `${title} | ${salesChannel?.name}` : salesChannel?.name;

    return (
        <>
            <GatsbySeo
                title={pageTitle}
                description={seo?.desc}
                noindex={salesChannel?.noIndex ? true : seo?.noIndex ? true : false}
                openGraph={{
                    type: "website",
                    url: window.location.href,
                    title: pageTitle,
                    description: seo?.desc
                }}
                metaTags={[
                    {
                        property: "keywords",
                        content: seo?.keywords
                    }
                ]}
            />
            <Layout pageTemplate={pageTemplate}>
                {blocks.map((block: BlockType) => (
                    <Block
                        block={block}
                        key={block.id}
                        location={location}
                        pageTitle={title}
                        salesChannel={salesChannel}
                    />
                ))}
            </Layout>
        </>
    );
};
export default IndexPage;

export const query = graphql`
    query ($template: String!) {
        pageTemplate(id: { eq: $template }) {
            id
            name
            header {
                menu
                menus {
                    id
                    name
                    displayName
                }
                logo
            }
            footer {
                menu
                menus {
                    id
                    name
                    displayName
                }
                logo
            }
        }
        salesChannel {
            id
            name
            email
            websiteTemplate
            logo
            phone
            address
            metadata
            type
            noIndex
            defaultPostcode
            defaultCountry
            primaryColour
            secondaryColour
        }
    }
`;
