import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../store/auth";
import store, { AppDispatch, RootState } from "../../../../store";
import { salesChannel } from "../../../../config";
import SalesChannelContext from "../../../../context/SalesChannelContext";
import CartDisplay from "../../../ecommerce/CartDisplay";
import OrderInTheNext from "../../../ecommerce/OrderInTheNext";
import Button from "../../../shared/Button";
import { setStep } from "../../../../store/order";
import CheckoutProgression from "./CheckoutProgression";
import { OrderType } from "../../../../types";
import { BsBag } from "react-icons/bs";
import SiteModal from "../../../shared/SiteModal";
import TextInput from "../../../shared/FormComponents/TextInput";
import ecommerceApi from "../../../../services/ecommerceApi";
import _ from "lodash";

type Props = {
    seo: {
        title: string;
    };
    cart?: OrderType;
    children?: React.ReactNode;
    hideNav?: boolean;
    showCouponCode?: boolean;
    refreshOrder?: boolean;
};

const CheckoutLayout = ({ seo, cart, hideNav = false, showCouponCode = true, children, refreshOrder }: Props) => {
    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                email
                logo
                logoFile {
                    publicURL
                }

                defaultPostcode
                defaultCountry

                primaryColour
                secondaryColour

                phone
                address
                type
                donations {
                    id
                    name
                    desc
                    type
                    value
                    image
                    enabled
                    salesChannel
                }
            }
        }
    `);



    const stateOrder = useSelector((state: RootState) => state.order.order);
    const logoUrl = data?.salesChannel?.logoFile?.publicURL;

    const order = cart ?? stateOrder;

    const dispatch = useDispatch<any>();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <SalesChannelContext.Provider value={{ salesChannel: data.salesChannel }}>
            <div className="flex flex-wrap w-full" style={{ minHeight: "100vh" }}>
                <div className="flex-1 max-w-full px-3 pb-10 md:pb-0">
                    <header className="px-1 md:px-6 pt-5 pb-5 md:pb-10">
                        <div className="flex flex-wrap items-center -mx-3">
                            <div className="md:w-auto px-3">
                                <Link className="inline-block" to="/">
                                    <img
                                        className="h-8 md:h-12 w-auto"
                                        width={"32px"}
                                        height={"32px"}
                                        src={logoUrl}
                                    />
                                </Link>
                            </div>
                            {!hideNav && (
                                <div className="px-3 ml-3 grow hidden xl:block">
                                    <CheckoutProgression />
                                </div>
                            )}
                        </div>
                    </header>

                    <main className="max-w-screen-md mx-auto">{children}</main>
                </div>

                <div className="w-full xl:w-[500px] px-3 bg-gray-100">
                    <div
                        className={`
                        p-5 }
                        overflow-auto
                        bg-gray-100 flex justify-center
                        xl:static
                    `}
                    >
                        {!!order && (
                            <div className="max-w-[500px]">
                                <div className="mb-6 hidden xl:block">
                                    <div className="p-4 bg-white">
                                        <OrderInTheNext hasBreak />
                                    </div>
                                </div>

                                <h2 className="text-2xl d mb-3">Shopping Cart</h2>

                                <div>
                                    <CartDisplay
                                        order={order}
                                        textSize="text-base"
                                        options={{
                                            showRemove: false,
                                            allowQuantityChange: false,
                                            showCouponCode
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </SalesChannelContext.Provider>
    );
};

const Badge = ({ children, count }: { children: React.ReactNode; count: number }) => {
    return (
        <div className="relative">
            {children}
            {count > 0 && (
                <span
                    className="
                        absolute top-[-9px] right-[-9px]
                        w-[18px] h-[18px] flex items-center justify-center rounded-full 
                        bg-primary text-white text-xs"
                >
                    {count}
                </span>
            )}
        </div>
    );
};

export default CheckoutLayout;
