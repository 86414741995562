import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../shared/Button";
import Spinner from "../../../../shared/Spinner";
import CheckoutSection from "../../CheckoutSection";
import { AppDispatch, RootState } from "../../../../../store";
import { getOrder, selectShippingMethodForShipment, setStep } from "../../../../../store/order";
import Collapse from "../../../../shared/Collapse";
import SelectInput from "../../../../shared/FormComponents/SelectInput";
import RadioGroupInput from "../../../../shared/FormComponents/RadioGroupInput";
import ShipmentEdit from "./ShipmentEdit";
import { IOrderShipment, IShipmentService } from "../../../../../types";
import { FaExclamationCircle } from "react-icons/fa";

export default function ShipmentsDisplay({ setTemporaryDisable, temporaryDisable }: { setTemporaryDisable: (temporaryDisable: string | undefined) => void, temporaryDisable: string | undefined }) {
    const dispatch = useDispatch<AppDispatch>();
    const orderStore = useSelector((state: RootState) => state.order);

    const stepOpen = orderStore.stepOpen;

    const orderId = orderStore.order?.id;
    const shipments = orderStore.order?.shipments;

    const [loading, setLoading] = React.useState(false);

    const handleShippingMethodSelect =
        (orderShipmentId: string) =>
            async (shippingMethodId: string, services: IShipmentService[], selectedEstDeliveryDate?: string) => {
                if (loading) return;
                setLoading(true);
                if (orderId) {
                    await dispatch(
                        selectShippingMethodForShipment({
                            orderId,
                            orderShipmentId,
                            shippingMethodId,
                            services,
                            selectedEstDeliveryDate
                        })
                    );
                }
                setLoading(false);
            };

    return (
        <section>
            {shipments?.map(shipment => (
                <ShipmentEdit
                    shipment={shipment}
                    loading={loading}
                    handleShippingMethodSelect={handleShippingMethodSelect}
                    setTemporaryDisable={setTemporaryDisable}
                    temporaryDisable={temporaryDisable}
                />
            ))}

            {(shipments?.length || 0) < 1 && (
                <div className="flex items-center justify-center p-5 bg-red-100">
                    <div className="w-[100px] flex items-end justify-center">
                        <FaExclamationCircle size={25} />
                    </div>
                    <div className="text-lg font-bold">
                        Something went wrong with your order. Please ensure you've entered your customer details and a valid shipping address.
                    </div>
                </div>
            )}
        </section>
    );
}
