import React, { useCallback, useEffect, useState } from "react";

import Layout from "../../layouts/Layout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";

import { navigate } from "gatsby";
import { updateOrder } from "../../../store/order";
import MyOrders from "./MyOrders";
import MyAddresses from "./MyAddresses";
import MyCredit from "./MyCredit";
import cognito from "../../../services/cognito";
import AccountTabs, { AccountTabTypes } from "./AccountTabs";
import Button from "../../shared/Button";
import { SIGN_UP_FORM } from "../../auth/LoginPage/constants";
import TextInput from "../../shared/FormComponents/TextInput";
import General from "./General";
import ecommerceApi from "../../../services/ecommerceApi";
import { getLoggedInCustomer, updateCustomer } from "../../../store/auth";
import { formatPrice } from "../../../utils/currency";
import Spinner from "../../shared/Spinner";
import ReferralCode from "../ReferralCode";

type Props = {
    defaultTab?: AccountTabTypes;
};

export default function MyAccount({ defaultTab }: Props) {
    const dispatch = useDispatch<AppDispatch>();

    const customer = useSelector((state: RootState) => state.auth.customer);
    const user = useSelector((state: RootState) => state.auth.user);

    // STATE
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState<AccountTabTypes>(defaultTab || AccountTabTypes.GENERAL);
    const currentPath = location.pathname;

    const handleSignOut = async () => {
        await cognito.signOut();
        dispatch(updateOrder(null));
        navigate("/");
    };

    const loadCustomer = useCallback(async () => {

        await dispatch(getLoggedInCustomer());


        if (!customer) {
            await navigate("/login", { state: { redirectUrl: currentPath } });
            return;
        }

        if (loading) {
            setLoading(false);
            return;
        }

    }, [user?.username, loading]);

    useEffect(() => {
        loadCustomer();
    }, [loadCustomer]);

    if (loading) {
        return <Layout>
            <div className="flex items-center justify-center min-h-[50vh]">
                <Spinner width={"50px"} height={"50px"} />
            </div>
        </Layout>
    }

    return (
        <Layout>
            <section className="bg-gray-100 py-5 md:py-10">
                <div className="mx-auto px-3 max-w-screen-xl">
                    <div className="">
                        <div className="md:hidden text-end mb-3">
                            <Button
                                type="secondary"
                                className="w-full"
                                padding={"py-3 px-3"}
                                onClick={handleSignOut}
                            >
                                Sign Out
                            </Button>
                        </div>
                        <div className="flex flex-wrap -mx-3">

                            <div className="w-full px-3 md:hidden">
                                <Welcome customer={customer} />
                            </div>

                            {/* LEFT */}
                            <div className="w-full md:w-3/12 px-3 mb-4 md:mb-0">
                                <div className="bg-white">
                                    <div className="text-center md:text-left px-6 py-4 hidden md:block">
                                        <h1>My Account</h1>
                                    </div>
                                    <AccountTabs tab={tab} setTab={setTab} />
                                </div>
                                <div className="hidden md:block mt-4">
                                    <Button
                                        type="secondary"
                                        className="w-full"
                                        padding={"py-3 px-3"}
                                        onClick={handleSignOut}
                                    >
                                        Sign Out
                                    </Button>
                                </div>
                            </div>
                            {/* RIGHT */}
                            <div className="w-full md:w-8/12 px-3 mb-6 md:mb-0 ">
                                <div className="hidden md:block">
                                    <Welcome customer={customer} />
                                </div>
                                {tab == AccountTabTypes.GENERAL && <General />}
                                {tab == AccountTabTypes.ORDERS && <MyOrders />}
                                {tab == AccountTabTypes.ADDRESSES && <MyAddresses />}
                                {tab == AccountTabTypes.CREDIT && <MyCredit />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    );
}

const Welcome = ({ customer }) => {
    return <div className="p-5 bg-white mb-4">
        <div className=" flex flex-wrap md:flex-nowrap justify-between items-center">
            <div className="w-full md:w-auto text-center md:text-left">
                <h2 className="">Welcome, {customer?.firstName}!</h2>
                <p>{customer?.email}</p>
            </div>
            <div className="flex w-full gap-4 justify-center md:justify-end">
                {(customer?.creditAmountTotal || 0) > 0 && (
                    <div className="flex mt-3 md:w-auto md:mt-0">
                        <div>
                            <div className="py-2 px-3 bg-primary font-bold rounded-md">
                                <div className="text-sm">
                                    My Credit
                                </div>
                                <div className="text-lg text-center">
                                    {formatPrice(customer?.creditAmountTotal || 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {customer?.referralCode && (
                    <div className="flex w-fit mt-3 md:w-auto md:mt-0">
                        <div className="py-2 px-3 bg-primary font-bold rounded-md">
                            <div className="text-sm text-center">
                                My Referral Code
                            </div>
                            <ReferralCode referralCode={customer.referralCode} copiedTextColor="text-secondary" edit customer={customer} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
}