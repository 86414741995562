import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckoutShipping from "./Shipping";
import CheckoutPayment from "./Payment";
import { AppDispatch, RootState } from "../../../store";
import { getOrder, addCustomerOrder, setStep } from "../../../store/order";
import { updateCustomer } from "../../../store/auth";
import CheckoutLayout from "./CheckoutLayout";
import CustomerDetails from "./CustomerDetails";
import ReviewDelivery from "./ReviewDelivery";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import { useTrackingContext } from "../../../context/tracking";

export default function CheckoutPage() {

    const dispatch = useDispatch<AppDispatch>();

    const order = useSelector((state: RootState) => state.order.order);
    const loggedInUser = useSelector((state: RootState) => state.auth.user);

    const [loaded, setLoaded] = React.useState(false);
    const [redirecting, setRedirecting] = React.useState(false);
    const [temporaryDisable, setTemporaryDisable] = React.useState<string | undefined>(undefined);

    const orderIsShipped = order?.isShipped;
    const orderCustomer = order?.customer as any | null;

    const loadUserAndOrder = async () => {
        if (order?.id && !orderCustomer && loggedInUser) {
            await dispatch(
                addCustomerOrder({
                    user: loggedInUser,
                    orderId: order.id
                })
            );
        }
        setLoaded(true);
    };

    const loadStep = () => {
        if (!orderCustomer) {
            dispatch(setStep("customerDetails"));
            return;
        }
        if (!order?.isShipped) {
            dispatch(setStep("payment"));
            return;
        }
        dispatch(setStep("shipping"));
    };


    useEffect(() => {
        loadUserAndOrder();
        loadStep();
    }, []);

    useEffect(() => {
        loadUserAndOrder();
        loadStep();
    }, [loggedInUser?.sub, orderCustomer?.id]);

    return (
        <CheckoutLayout seo={{ title: "Checkout" }} refreshOrder>
            {!redirecting ? (
                <div className="container mx-auto max-w-screen-lg">
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full">

                            <StartedCheckoutComponent />

                            <CustomerDetails />

                            {orderIsShipped ? (
                                <>
                                    <CheckoutShipping temporaryDisable={temporaryDisable} />
                                    <ReviewDelivery
                                        temporaryDisable={temporaryDisable}
                                        setTemporaryDisable={setTemporaryDisable}
                                    />
                                </>
                            ) : null}

                            <CheckoutPayment />
                        </div>
                    </div>
                </div>
            ) : null}
        </CheckoutLayout>
    );
}

const StartedCheckoutComponent = () => {

    const order = useSelector((state: RootState) => state.order.order);
    const orderCustomer = order?.customer;
    const loggedInUser = useSelector((state: RootState) => state.auth.user);
    const { identifyEvent, beginCheckoutEvent } = useTrackingContext();

    const salesChannel = useSalesChannel();

    const handleStartedCheckout = async () => {
        if (order?.id && order?.customer) {
            identifyEvent(order.customer);
        }
    };

    const handleBeginCheckout = async () => {
        if (order?.id) {
            beginCheckoutEvent({ ...order, salesChannel });
        }
    }

    useEffect(() => {
        handleBeginCheckout();
    }, [order?.id]);

    useEffect(() => {
        handleStartedCheckout();
    }, [loggedInUser?.sub, orderCustomer?.id]);

    return (<></>);
}