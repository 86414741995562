import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ReviewType } from "../../../../types";
import { ProductJsonLd } from "gatsby-plugin-next-seo";
import { BsStarFill } from "react-icons/bs";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useLayout } from "../../../layouts/Layout/hook";
import TrustpilotCarousel from "../../../shared/Trustpilot/Carousel";
import TrustpilotProductReviewsMultisourceSEO from "../../../shared/Trustpilot/ProductReviewsMultisourceSEO";

export interface ReviewListProps {
    reviews: ReviewType[];
    reviewType: string;
    skuCode: string;
}
const ReviewList = ({ reviews, reviewType, skuCode }: ReviewListProps) => {

    const { reviewPlatform } = useLayout();

    if (reviewPlatform.type === 'trustpilot') {
        if (reviewType === "Product") {
            return <TrustpilotProductReviewsMultisourceSEO sku={skuCode} />
        }
        return <TrustpilotCarousel />
    }

    return (
        <Splide
            options={{
                autoplay: false,
                type: "loop",
                perPage: 3,
                pagination: false,
                padding: 50,
                breakpoints: {
                    640: {
                        perPage: 1,
                        type: "loop",
                        autoplay: true,
                        padding: 25,
                        arrows: false,
                    }
                }
            }}>
            {reviews?.map(review =>
                <SplideSlide key={review?.id}>
                    <div>
                        <ReviewItem review={review} key={review.id} />
                    </div>
                </SplideSlide>)
            }
        </Splide>
    );
};


function ReviewItem({ review }: {
    review: ReviewType
}) {

    const rating = Number(review?.ratingValue);
    const percentage = (rating / 5) * 100;

    return (
        <div className="px-4 text-left" key={review?.id}>
            <div className="flex items-center mb-3">
                <div>
                    <div
                        className="w-[40px] h-[40px] mr-3
                flex items-center justify-center
                rounded-full bg-secondary text-white text-lg
                "
                    >
                        {review?.customerName?.charAt(0)?.toUpperCase()}
                    </div>
                </div>
                <div>
                    <h3 className="text-base font-primary">{review?.customerName}</h3>
                    <div className="relative text-2xl">
                        <div className="flex items-center text-gray-300">
                            <BsStarFill />
                            <BsStarFill />
                            <BsStarFill />
                            <BsStarFill />
                            <BsStarFill />
                        </div>
                        <div className="absolute overflow-hidden top-0 left-0 text-amber-400" style={{
                            width: `${percentage}%`,
                        }}>
                            <div className="flex">
                                <BsStarFill className='flex-none' />
                                <BsStarFill className='flex-none' />
                                <BsStarFill className='flex-none' />
                                <BsStarFill className='flex-none' />
                                <BsStarFill className='flex-none' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-sm md:text-base line-clamp-4 overflow-hidden" title={review?.body}>{review?.body}</div>
        </div>
    )
}

export default ReviewList;
