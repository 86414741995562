import { ProductJsonLd } from 'gatsby-plugin-next-seo';
import React, { useMemo } from 'react'
import { useLayout } from '../../../layouts/Layout/hook';
import { IProductReviewSummary, ProductType } from '../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

type Props = {
    product: ProductType
    pricing: any;
    reviewSummary: IProductReviewSummary | undefined;
}

export default function ProductSchema({ product, pricing, reviewSummary }: Props) {

    const { reviewPlatform } = useLayout();

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );

    const selectedSchema = useMemo(() => {
        if (product?.schema) {
            try {
                const parsed = JSON.parse(product.schema);
                // if not array
                if (!Array.isArray(parsed)) {
                    return parsed;
                }
            } catch (e) {
                console.warn(e);
            }
        }
        return null;
    }, [product?.schema]);


    const reviewInfoSpread = useMemo(() => {

        const showReviewInfo = reviewPlatform?.type !== "trustpilot";

        if (!showReviewInfo) {
            return {};
        }

        return {
            aggregateRating: reviewSummary && reviewSummary.count > 0 ? {
                ratingValue: reviewSummary.rating,
                reviewCount: reviewSummary.count,
                bestRating: "5",
                worstRating: "1"
            } : undefined,
            reviews: (product?.reviews ?? []).map(rev => {
                return {
                    author: rev.author ?? rev.customerName,
                    datePublished: rev.datePublished,
                    reviewBody: rev.body,
                    name: rev.title,
                    reviewRating: {
                        bestRating: "5",
                        ratingValue: rev.ratingValue,
                        worstRating: "1"
                    }
                };
            })
        }

    }, [reviewPlatform, reviewSummary]);

    return (
        <ProductJsonLd
            name={product?.SKUName}
            brand={selectedSchema?.brand}
            images={[product?.imageFile?.publicURL || selectedSchema?.image]} //add additional image links here + prefix the site url
            // description={product?.shortDescription}
            gtin={selectedSchema?.gtin}
            offers={{
                price: pricing?.currentPrice?.toString() || "N/A",
                priceCurrency: currencyCode || "GBP",
                // priceValidUntil: '', //Today + 30 days
                // availability: 'InStock', //OfferAvailability converter from gatsby-plugin-next-seo
                itemCondition: "NewCondition",
                url: selectedSchema?.url
            }}
            mpn={selectedSchema?.mpn}
            {...reviewInfoSpread}
        />
    )
}
