import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { addToCart } from "../../../../store/order";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import OrderInTheNext from "../../../ecommerce/OrderInTheNext";
import { getPriceToShow } from "../../../../utils/currency";
import ecommerceApi from "../../../../services/ecommerceApi";
import EcommerceApi from "../../../../services/ecommerceApi";
import { getProductImage } from "../../../../utils/image";
import Button from "../../../shared/Button";
export interface ProductSelectorProps {
    defaultProduct: ProductType;
    slug: string;
}
import {
    ProductCategoryType,
    ProductDataType,
    ProductTaxCodeType,
    ProductType
} from "../../../../types";
import { useSalesChannel } from "../../../../context/SalesChannelContext";
import QuantityModifier from "../../../ecommerce/QuantityModifier";
import StockIndicator from "../../../ecommerce/StockIndicator";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { maximumQuantityToAddHelper } from "../ProductCategoryListItem/maximumQuantityToAddWithoutCurrentShoppingCart";

export default function ProductSelector({ defaultProduct: selectedProduct, slug }: ProductSelectorProps) {

    const dispatch = useDispatch<AppDispatch>();

    const salesChannel = useSalesChannel();

    const order = useSelector((state: RootState) => state.order.order);
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );

    const [selectedProductData, setSelectedProductData] = React.useState<ProductDataType | null>(
        null
    );

    const [quantity, setQuantity] = React.useState(1);
    const [adding, setAdding] = React.useState(false);


    const loadProductData = async () => {
        if (selectedProduct) {
            const productId = selectedProduct.product || selectedProduct.id;

            const [product] = await Promise.all([
                ecommerceApi.getProductData(productId),
            ]);

            setSelectedProductData(product || null);
        }
    };

    const handleAddToCartClick = async () => {
        setAdding(true);
        const orderId = order ? order.id : undefined;
        const params = {
            orderId: orderId,
            skuId: selectedProduct.id,
            quantity
        };
        await dispatch(addToCart(params));
        setAdding(false);
    };

    const handleHeadingClick = () => {
        navigate(selectedProduct?.url || "/");
    };

    const pricing = useMemo(() => {
        let metadata = JSON.parse(salesChannel?.metadata ?? "{}");
        let showVat = metadata?.settings?.showVAT || false;

        if (selectedProductData) {
            const listPrice = getPriceToShow(
                selectedProductData.listPrice,
                salesChannel,
                currencyCode,
                undefined,
                selectedProduct || undefined
            );
            return {
                price: getPriceToShow(
                    selectedProductData.currentPrice,
                    salesChannel,
                    currencyCode,
                    undefined,
                    selectedProduct || undefined
                ),
                listPrice: selectedProductData?.listPrice || 0 ? listPrice : null
            };
        }
        return null;
    }, [selectedProductData, currencyCode, salesChannel.metadata]);

    useEffect(() => {
        loadProductData();
    }, [selectedProduct]);

    const image = getImage(selectedProduct?.imageFile);


    const maximumQuantityToAdd = useMemo(() => {
        return maximumQuantityToAddHelper(order, selectedProduct?.id, selectedProductData);
    }, [selectedProductData, selectedProduct, order]);

    return (
        <>
            <div className="bg-white p-8 h-full flex flex-col justify-between">
                {/* Top */}
                <div className="mb-3">
                    {image && (

                        <Link to={selectedProduct?.url || "/"}
                            className="flex justify-center cursor-pointer"
                        >
                            <GatsbyImage
                                image={image as any}
                                alt={selectedProduct?.SKUName || "Product"}
                                className="w-full max-w-[200px] h-full object-contain"
                            />
                        </Link>
                    )}
                </div>
                {/* Bottom */}
                <div className="grow mb-5">
                    <h2
                        className="text-xl mb-1 cursor-pointer font-extrabold"
                        onClick={handleHeadingClick}
                    >
                        {selectedProduct.SKUName}
                    </h2>
                    <div className="flex flex-wrap items-center -mx-2 mb-3">
                        <div className="text-2xl px-2 h-[32px] flex items-center">
                            {pricing == null ? (
                                <span className="inline-block px-5">
                                    <Icon path={mdiLoading} spin={0.5} size={1} />
                                </span>
                            ) : (
                                <>
                                    {pricing?.listPrice && (
                                        <span className="text-gray-500 line-through mr-2 opacity-99">
                                            {pricing?.listPrice}
                                        </span>
                                    )}
                                    <span>{pricing?.price}</span>
                                </>
                            )}
                        </div>
                        <div className="px-2 w-full mb-3 md:mb-0 md:w-auto">
                            {selectedProductData &&
                                selectedProductData?.stock &&
                                selectedProductData?.stock?.type &&
                                selectedProductData?.stock?.type !== "STOCK_TYPE_NOT_SET" && (
                                    <div className="mt-2 md:mt-0">
                                        <StockIndicator
                                            stock={selectedProductData?.stock}
                                            loading={selectedProductData == null}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                    {selectedProduct?.productMetricHtml && (
                        <div className="mb-3 w-full" dangerouslySetInnerHTML={{ __html: selectedProduct.productMetricHtml }}></div>
                    )}
                    <div>
                        <label className="block mb-2 font-bold text-secondary text-xs uppercase">
                            Quantity
                        </label>
                        <QuantityModifier quantity={quantity} handleQuantityChange={setQuantity} />
                    </div>
                </div>
                <div>
                    <div className="flex -mx-2">
                        <div className="px-2 w-full">
                            <Button
                                padding="py-3 px-10"
                                loader={adding}
                                onClick={handleAddToCartClick}
                                disabled={
                                    adding || quantity > maximumQuantityToAdd
                                }
                            >
                                Add to Cart
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
