import React from "react";
import { useSelector } from "react-redux";
import AddressSelector from "../../AddressSelector";
import { RootState } from "../../../../../store";

interface Props {
    temporaryDisable: string | undefined;
    editing: boolean;
    setEditing: (value: boolean) => void;
}

export default function ShippingAddress({ temporaryDisable, editing, setEditing }: Props) {

    const shippingAddresses = useSelector((state: RootState) => state.order.order?.shippingAddress);
    const selectedAddress = shippingAddresses ? shippingAddresses[0] : undefined;

    return (
        <>
            <AddressSelector temporaryDisable={temporaryDisable} type="Shipping" selectedAddress={selectedAddress} editing={editing} setEditing={setEditing} />
        </>
    )
}