/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { useEffect, ReactNode, useState, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { fetchAllCurrencyCodes } from "../../../store/currencyCode";
import CookieBanner from "../../seo/CookieBanner";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import CustomBreadcrumb from "../../shared/CustomBreadcrumb";
import SalesChannelContext from "../../../context/SalesChannelContext";
import { Helmet } from "react-helmet";
import { getSalesChannelImage } from "../../../utils/image";
import DevTools from "../../shared/devtools";
import { useEcommerceHelper } from "../../../context/ecommerce";
import EditPostcode from "../../ecommerce/EditPostcode";
import { useLayout } from "./hook";
import { ConsentForm } from "../../seo/CookieBanner/Consent";

interface Props {
    children: ReactNode;
    pageTemplate?: any;
}

const Layout = ({ children, pageTemplate }: Props) => {

    const showDevTools = process.env.GATSBY_SHOW_DEV_TOOLS === "true";

    const dispatch = useDispatch<AppDispatch>();

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode
    );

    const { salesChannel, allTemplates, menuItems, socials, reviewPlatform } = useLayout();

    if (!pageTemplate) {
        pageTemplate = allTemplates.nodes[0];
    } else {
        pageTemplate = allTemplates.nodes.find((template: any) => template.id === pageTemplate.id);
    }

    useEffect(() => {
        if (!currencyCode) {
            dispatch(fetchAllCurrencyCodes());
        }
    }, [currencyCode]);

    const scripts = useMemo(() => {
        switch (reviewPlatform?.type) {
            case "trustpilot":
                return <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"></script>
        }
        return null;
    }, [reviewPlatform?.type]);

    return (
        <>
            <SalesChannelContext.Provider value={{ salesChannel }}>

                <Helmet>
                    <meta charSet="utf-8" />
                    {salesChannel?.iconFile?.publicURL && (
                        <link rel="icon" href={salesChannel?.iconFile.publicURL as string} />
                    )}
                    {scripts}
                </Helmet>

                <Header
                    pageTemplate={pageTemplate}
                    salesChannel={salesChannel}
                    menuItems={menuItems}
                />
                <main className="text-secondary">{children}</main>
                <Footer
                    pageTemplate={pageTemplate}
                    salesChannel={salesChannel}
                    menuItems={menuItems}
                    socials={socials?.nodes}
                />

                <ConsentForm />
                <EditPostcode />
            </SalesChannelContext.Provider>
            {showDevTools && <DevTools />}
        </>
    );
};

export default Layout;
