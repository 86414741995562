import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { editOrderItemQuantity } from "../../../store/order";
import QuantityModifier from "../QuantityModifier";
type TOrderItemQuantityModifier = {
    orderItemId: string;
    quantity: number;
    disabled?: boolean;
    max?: number;
};

export default function OrderItemQuantityModifier({
    orderItemId,
    quantity,
    disabled = false,
    max
}: TOrderItemQuantityModifier) {
    const dispatch = useDispatch<AppDispatch>();

    const orderId = useSelector((state: RootState) => state.order.order?.id);
    const [oQuantity, setOQuantity] = useState(quantity || 1);
    const [loading, setLoading] = useState(false);

    const handleQuantityChange = async (val: number) => {
        setLoading(true);
        if (orderItemId && orderId) {
            const quantityAmount = val;
            const params = {
                orderId: orderId,
                orderItemId: orderItemId,
                quantity: quantityAmount
            };

            await dispatch(editOrderItemQuantity(params));
            setOQuantity(val);
        }
        setLoading(false);
    };

    useEffect(() => {
        setOQuantity(quantity);
    }, [quantity]);

    return (
        <QuantityModifier
            quantity={oQuantity}
            disabled={disabled}
            loading={loading}
            handleQuantityChange={handleQuantityChange}
            max={max}
        />
    );
}
