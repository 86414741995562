import React, { useMemo, useState } from "react";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

// @ts-ignore
import * as uuid from "uuid";
import EcommerceApi from "../../../services/ecommerceApi";
import { getSiteKeys } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { updateCustomer } from "../../../store/auth";
import { answerCustomChallenge, signIn, signUp } from "../../../services/cognito";
import { isEmpty, jsonParseString } from "../../../utils/string";
import { salesChannel } from "../../../config";
import { useLoginSignUp } from "./hook";
import TextInput from "../../shared/FormComponents/TextInput";
import Button from "../../shared/Button";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import { SIGN_UP_FORM } from "./constants";
import { BsExclamation, BsExclamationCircle, BsExclamationCircleFill } from "react-icons/bs";

interface Props {
    location: any;
}

export default function LoginPage({ location }: Props) {
    const salesChannel = useSalesChannel();

    const loqateData = useStaticQuery(graphql`
        query {
            loqate: allIntegration(filter: { type: { eq: "loqate" } }) {
                nodes {
                    id
                    config {
                        property
                        propertyId
                        value
                    }
                }
            }
        }
    `);

    const integration = loqateData.loqate.nodes[0];

    const { loading, mode, data, setValue, setMode, getSecretCode, error, setError, verify } =
        useLoginSignUp(location);

    const [errorMessages, setErrorMessages] = React.useState<string | null>(null);
    const [warningMessages, setWarningMessages] = React.useState<string | null>(null);

    const logoUrl = useMemo(() => {
        const file = jsonParseString(salesChannel.logo || "{}");
        if (file?.url) {
            return file.url;
        }
        return salesChannel.logo;
    }, [salesChannel]);

    const signUp = async () => {
        const errorMessages: string[] = [];
        const tempGuestCheckoutWarning = warningMessages;
        let firstTimeWarning = false;
        for (const field of SIGN_UP_FORM) {
            const input = data[field.name];
            if (field.validate) {
                const { valid, message } = await field.validate(input, loqateData);
                if (!valid && message) {
                    errorMessages.push(message);
                }
                if (valid && message) {
                    setWarningMessages(message);
                    if (!tempGuestCheckoutWarning) {
                        firstTimeWarning = true;
                    }
                }
            }
        }

        if (errorMessages.length > 0 || firstTimeWarning) {
            setErrorMessages(errorMessages.join("\n"));
            return;
        }

        setErrorMessages(null);
        setWarningMessages(null);
        getSecretCode();
    }

    return (
        <div className="py-10">
            <div className="px-3 max-w-screen-md mx-auto">
                <div className="p-10 bg-white">
                    <div className="mb-5 text-center">
                        <img
                            className="max-w-[300px] w-full h-auto mx-auto mb-5"
                            src={logoUrl}
                            width="300px"
                            height="50px"
                        />
                        <h1 className="text-4xl mb-2">{mode == "SIGN_UP" ? "Sign-up" : "Sign-in"}</h1>
                        <p className="text-lg">
                            Enter your email below and we will send you a login code.
                        </p>
                    </div>

                    {error && (
                        <div className="p-4 flex items-center bg-red-400 font-bold mb-3">
                            <BsExclamationCircleFill />
                            <span className="ml-3">{error}</span>
                        </div>
                    )}

                    {mode == "SIGN_IN" && (
                        <div>
                            <TextInput
                                label={"Email"}
                                value={data.email}
                                onChange={e => setValue("email", e.target.value)}
                            />
                            <Button
                                disabled={loading}
                                loader={loading}
                                className="mt-5 w-full"
                                onClick={getSecretCode}
                            >
                                Send Login Code
                            </Button>
                            <div className="pt-5 text-center">
                                <a
                                    className="cursor-pointer underline"
                                    onClick={() => setMode("SIGN_UP")}
                                >
                                    Don't have an Account? Click here to sign-up
                                </a>
                            </div>
                        </div>
                    )}

                    {mode == "SIGN_UP" && (
                        <>
                            {SIGN_UP_FORM.map(f => {
                                return (
                                    <div className="mb-3" key={f.name}>
                                        <TextInput
                                            label={f.label}
                                            value={data[f.name]}
                                            onChange={e => {
                                                setValue(f.name, e.target.value);
                                                setErrorMessages(null);
                                                setWarningMessages(null);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            {errorMessages && (
                                <div className="text-red-500 text-sm mt-3 text-center whitespace-pre">
                                    {errorMessages}
                                </div>
                            )}
                            {warningMessages && (
                                <div className="text-yellow-500 text-sm mt-3 text-center whitespace-pre">
                                    {warningMessages}
                                </div>
                            )}
                            <Button
                                disabled={loading}
                                loader={loading}
                                className="mt-5 w-full"
                                onClick={() => { signUp() }}
                            >
                                {warningMessages && !errorMessages ? "Sign Up Anyway" : "Sign Up"}
                            </Button>
                            <div className="pt-5 text-center">
                                <a
                                    className="cursor-pointer underline"
                                    onClick={() => setMode("SIGN_IN")}
                                >
                                    Already have an Account? Click here to sign-in
                                </a>
                            </div>
                        </>
                    )}

                    {mode == "VERIFY_CODE" && (
                        <>
                            <TextInput
                                label={"Code"}
                                value={data.code}
                                onChange={e => setValue("code", e.target.value)}
                            />

                            <div className="flex -mx-3 my-3">
                                <div className="px-3 w-1/2">
                                    <Button
                                        disabled={loading}
                                        loader={loading}
                                        className="w-full"
                                        onClick={e => verify()}
                                    >
                                        Verify Code
                                    </Button>
                                </div>
                                <div className="px-3 w-1/2">
                                    <Button
                                        disabled={loading}
                                        loader={loading}
                                        type="secondary"
                                        className="w-full"
                                        onClick={getSecretCode}
                                    >
                                        Resend Code
                                    </Button>
                                </div>
                            </div>
                            <div className="pt-2 text-center">
                                <a
                                    className="cursor-pointer underline py-2"
                                    onClick={() => setMode("SIGN_IN")}
                                >
                                    Back to sign-in
                                </a>
                            </div>
                        </>
                    )}

                    <div className="mt-10 text-center underline">
                        <Link to="/">Back to wesbite</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
