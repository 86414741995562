import Header from "./Header";
import ProductCategoryList from "./ProductCategoryList";
import Button from "./Button";
import PageList from "./PageList";
import ProductDetail from "./ProductDetail";
import Footer from "./Footer";
import Cart from "./Cart";
import ProductList from "./ProductList";
import ReviewList from "./ReviewsList";
import ProductSelector from "./ProductSelector";
import RelatedProducts from "./RelatedProducts";

export const DefaultComponents: { [key: string]: any } = {
    Header: Header,
    ProductCategoryList: ProductCategoryList,
    Button: Button,
    PageList: PageList,
    ProductDetail: ProductDetail,
    Footer: Footer,
    Cart: Cart,
    ProductList: ProductList,
    ReviewList: ReviewList,
    ProductSelector: ProductSelector,
    RelatedProducts: RelatedProducts
};
