import React from "react";
import Field from "../Field";
import { BlockType } from "../../../../types";
import { parsedHtmlTransformation } from "../../../../utils/html";
import { ISalesChannel } from "../../../../services/ecommerceApi/SalesChannel/types";
import BackgroundImage from "../../../content/BackgroundImage";

interface Props {
    block: BlockType;
    location: any;
    pageTitle: string;
    salesChannel: ISalesChannel;
}

export default function Block({ block, location, pageTitle, salesChannel }: Props) {
    let bgField = block.attributes.find(attr => attr.fieldId === "background_image");
    if (bgField) {
        return (
            <BackgroundImage
                field={bgField}
                block={block}
                pageTitle={pageTitle}
                salesChannel={salesChannel}
            />
        );
    }

    const transformation = block.transformation;
    if (transformation?.html) {
        let data = Object.fromEntries(
            block.attributes?.map(field => [
                field.title.toLowerCase().replace(" ", ""),
                <Field
                    key={field.id}
                    field={field}
                    location={location}
                    pageTitle={pageTitle}
                    salesChannel={salesChannel}
                    block={block}
                />
            ])
        );

        let html = transformation.html;
        return <div>{parsedHtmlTransformation(html, data)}</div>;
    }

    return (
        <>
            {block.attributes?.map(field => {
                return (
                    <Field
                        key={field.id}
                        field={field}
                        location={location}
                        pageTitle={pageTitle}
                        salesChannel={salesChannel}
                    />
                );
            })}
        </>
    );
}
