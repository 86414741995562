import React, { ChangeEvent } from "react";

interface Props {
    value: string;
    label?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    options?: {
        bg?: string;
        padding?: string;
    }
    onChange: (value: ChangeEvent<HTMLSelectElement>) => void;
    children: React.ReactNode;
    autocomplete?: string;
}
const defiaultOptions = {
    bg: "gray-100",
    padding: "px-3 py-2"
};
export default function SelectInput({
    value,
    label,
    className,
    placeholder,
    disabled = false,
    children,
    options,
    onChange,
    autocomplete
}: Props) {

    const opt = {
        ...defiaultOptions,
        ...options
    }

    return (
        <>
            <label className="inline-block text-base font-bold mb-1 uppercase">{label}</label>
            <select
                value={value}
                disabled={disabled}
                onChange={onChange}
                className={`
                    ${className}
                    w-full border text-lg mt-1
                    ${opt.padding} 
                    bg-${opt.bg} border-${opt.bg}
                `}
                autoComplete={autocomplete}
                >
                {children}
            </select>
        </>
    );
}
