import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse, { DOMNode } from "html-react-parser";
import { FAQJsonLd } from "gatsby-plugin-next-seo";
import { BlockType, ContentType, FieldType, ModelType } from "../../../types";
import Field, { FieldProps } from "../../pages/Page/Field";
import { ISalesChannel } from "../../../services/ecommerceApi/SalesChannel/types";
import { parsedHtmlTransformation } from "../../../utils/html";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

interface Props {
    field: FieldProps["field"];
    block: BlockType;
    pageTitle?: string;
    salesChannel: ISalesChannel;
}

export default function RenderBlockWithBgBackgroundImage({
    field,
    block,
    pageTitle,
    salesChannel
}: Props) {
    const val = JSON.parse(field?.value || "{}")?.value;

    let containerClass = val?.containerClass ?? "";
    const image = getImage(field?.imageFile);

    const bgImage = convertToBgImage(image);

    const transformation = block.transformation;
    if (transformation) {
        let data = Object.fromEntries(
            block.attributes?.map(field => [
                field.title.toLowerCase().replace(" ", ""),
                <Field
                    key={field.id}
                    field={field}
                    location={location}
                    pageTitle={pageTitle}
                    salesChannel={salesChannel}
                />
            ])
        );

        return (
            <BackgroundImage
                Tag="section"
                // Spread bgImage into BackgroundImage:
                {...bgImage}
                className={containerClass}
            >
                {parsedHtmlTransformation(transformation?.html, data)}
            </BackgroundImage>
        );
    }

    return null;
}
