import React from "react";
import RatingsStars from "../RatingStars";
import { IProductReviewSummary, ProductType } from "../../../types";
import { IReviewPlatform } from "../../../../build/BlockHelper/types";
import Trustpilot from "./Trustpilot";
import TrustpilotProductReviews from "../../shared/Trustpilot/ProductReviewSummary";

interface Props {
    product?: Partial<ProductType>;
    reviewPlatform?: IReviewPlatform;
    reviewSummary?: IProductReviewSummary;
    Wrapper?: any;
    size?: number;
    hideText?: boolean;
    hideRating?: boolean;
    className?: string;
    width?: string;
    onClick?: () => void;
}

export default function ReviewSummary({ product, reviewPlatform, reviewSummary, width, Wrapper = DefaultWrapper, size = 22, hideText = false, hideRating = false, className = "", onClick }: Props) {

    if (reviewPlatform?.type === "trustpilot") {
        if (!product?.reviewSKU) return;
        return <Wrapper>
            <TrustpilotProductReviews sku={product.reviewSKU} width={width} />
        </Wrapper>
    }

    if (!reviewSummary) return <></>

    const { rating, count, max } = reviewSummary;

    if (count < 1) return <></>

    return (
        <Wrapper>
            <div className={`flex items-center space-x-2 ${className} ${onClick ? "cursor-pointer" : ""}`}
                onClick={onClick}
            >
                <RatingsStars rating={rating} size={size} maxRating={max} />
                {!hideRating && (
                    <div className="flex items-center text-gray-500 text-xl leading-none">
                        <div>
                            {`${rating}/${max}`}
                        </div>
                    </div>
                )}
            </div>
            {!hideText &&
                <div className="text-sm">
                    Rating based on {count} verified reviews
                </div>
            }
        </Wrapper>
    );
}

const DefaultWrapper = (props: any) => <>{props.children}</>;