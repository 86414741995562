import React, { useMemo } from "react";
import { navigate } from "gatsby";
import { BsBag } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import CartDisplay from "../../../ecommerce/CartDisplay";
import Button from "../../../shared/Button";

export default function HeaderCart() {
    
    // -- HOOKS
    const order = useSelector((state: RootState) => state.order.order);

    const count = useMemo(() => {
        return (order?.orderItems || []).filter(orderItem => !orderItem.parentId)?.reduce((acc, item) => {
            return acc + item.quantity;
        }, 0);
    }, [order?.orderItems]);

    const handleCartClick = () => {
        navigate("/cart");
    };

    return (
        <div className="relative h-[80px] flex items-center">
            <button title="Go to Cart Page" className="flex items-center pr-4 md:px-4" onClick={handleCartClick}>
                <Badge count={count ?? 0}>
                    <BsBag className="text-secondary w-[20px] h-[20px]" />
                </Badge>
            </button>
        </div>
    );
}

const Badge = ({ children, count }: {
    children: React.ReactNode;
    count: number;
}) => {

    return (
        <div className="relative">
            {children}
            {count > 0 && (
                <span
                    className="
                        absolute top-[-9px] right-[-9px]
                        w-[18px] h-[18px] flex items-center justify-center rounded-full 
                        bg-primary text-white text-xs"
                >
                    {count}
                </span>
            )}
        </div>
    );

};