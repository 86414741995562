import { Link } from 'gatsby'
import React from 'react'

interface Props {
    productUrl: string | undefined
    children: React.ReactNode
    className?: string
}

export default function ProductLink({ children, productUrl, className }: Props) {

    if (productUrl == undefined) {
        return <div className={className}>
            {children}
        </div>
    }

    return (
        <Link to={productUrl} className={className}>
            {children}
        </Link>
    )
}
