import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link, graphql, useStaticQuery } from "gatsby";

type CookieBannerProps = {
    header: string;
    message: string;
    acceptText: string;
    denyText: string;
    onAccept: () => void;
    onDeny: () => void;
}

export default function CookieBanner({ header, message, acceptText, denyText, onAccept, onDeny }: CookieBannerProps) {
    return (
        <div className={`fixed bottom-0 inset-x-0 pb-2 sm:pb-5`} style={{ zIndex: "2000" }}>
            <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className={`p-2 rounded-lg bg-secondary shadow-lg sm:p-3 border border-white`}>
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="sm:flex-1 flex items-center">
                            <div className="flex-col flex">
                                <div className={`ml-3 font-bold text-xl text-primary`} > {header}</div>
                                <div className="ml-3 font-medium text-white">
                                    <span className="md:hidden">{message}</span>
                                    <span className="hidden md:inline">{message}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 sm:mt-0 mt-4 sm:w-max w-full sm:mx-0 mx-auto sm:ml-0 ml-2">
                            {denyText !== undefined && (
                                <div className="flex-shrink-0 sm:order-2 sm:ml-2">
                                    <button
                                        className={`flex items-center justify-center px-4 py-2 text-sm leading-5 font-medium rounded-md text-white border-secondary border-2 hover:text-secondary focus:outline-none focus:shadow-outline transition ease-in-out duration-150`}
                                        onClick={onDeny}
                                    >
                                        {denyText}
                                    </button>
                                </div>
                            )}
                            {acceptText !== undefined && (
                                <div className="flex-shrink-0 sm:order-3 sm:mt-0 sm:w-auto">
                                    <div className="rounded-md shadow-sm">
                                        {onAccept !== undefined && (
                                            <button
                                                className={`flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary hover:text-secondary focus:outline-none focus:shadow-outline transition ease-in-out duration-150`}
                                                onClick={onAccept}
                                            >
                                                {acceptText}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
