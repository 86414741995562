import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse, { DOMNode } from "html-react-parser";
import { parsedHtmlTransformation } from "../../../utils/html";
import Field from "../../pages/Page/Field";
import { ContentType, ModelType } from "../../../types";
import { useSalesChannel } from "../../../context/SalesChannelContext";

interface CMSContentItemProps {
    model: ModelType;
    item: ModelContentParsed;
    selectedTransformation: string;
}

export type ModelContentParsed = Pick<ContentType, "id" | "modelId"> & { [key: string]: any };

export default function CMSContentItem({ model, item, selectedTransformation }: CMSContentItemProps) {

    const salesChannel = useSalesChannel();

    const transformation = model?.transformations?.find(
        trans => trans.id === selectedTransformation
    );

    if (transformation && transformation?.html) {

        let data = Object.fromEntries(Object.keys(item).map(field => {

            const modelField = model.fieldsArr?.find(f => f.fieldId === field);

            if (!modelField) {
                return [field.toLowerCase(), <>ERROR!</>];
            }

            const value = JSON.stringify({
                value: item[field],
            });

            return [field.toLowerCase(), <Field key={field} field={{ fieldId: modelField.type, value }}
                pageTitle={"TEST"}
                salesChannel={salesChannel}
            />]
        }));

        return parsedHtmlTransformation(transformation.html, data);
    }

    return null;
}
