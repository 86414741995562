import React from 'react'
import { useSalesChannel } from '../../../context/SalesChannelContext';

export default function PaymentFailedMessage() {
    const salesChannel = useSalesChannel();
    return (
        <>
            <h2 className="text-4xl uppercase">
                Payment Failed
            </h2>
            <h3 className="text-2xl mt-4 mb-2">
                Having trouble placing an order?
            </h3>
            <div className='mb-3'>
                You can call us on
                {" "}
                <a className='font-bold underline' href={`tel:${salesChannel?.phone}`}>{salesChannel?.phone}</a>
                {" "}
                9AM - 5PM, Monday - Friday or drop us an email on
                {" "}
                <a className='font-bold underline' href={`mailto:${salesChannel?.email}`}>{salesChannel?.email}</a>
                {" "}
                and our Customer service team will be happy to help.
            </div>
        </>
    )
}
