import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import AddressSelector from "../../AddressSelector";

export default function BillingAddress() {

    const selectedAddress = useSelector((state: RootState) => state.order.order?.billingAddress);

    const [editing, setEditing] = React.useState(selectedAddress == null);

    return (
        <AddressSelector type="Billing" selectedAddress={selectedAddress} editing={editing} setEditing={setEditing} />
    )
}