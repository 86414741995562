import React from "react";
import { PageListType } from "../../../types";
import { Link } from "gatsby";
import { PageListProps } from "../../websiteTemplates/Default/PageList";
import GetComponent from "../../websiteTemplates/_GetComponent";
import { useSalesChannel } from "../../../context/SalesChannelContext";

export default function PageList(props: PageListProps) {
    const salesChannel = useSalesChannel();
    return <GetComponent template={salesChannel?.websiteTemplate} component="PageList" props={props} />;
}
