import React from "react";
// @ts-ignore
import Output from "editorjs-react-renderer";

type Block = {
    type: string;
    data: any;
};

type OutputData = {
    time: number;
    blocks: Block[];
    version: string;
};

interface Props {
    content: string;
}

const getData = (dataStr: string) => {
    let isValidJSON = false;
    try {
        JSON.parse(dataStr);
        isValidJSON = true;
    } catch (error) {
        console.log("err", error);
        isValidJSON = false;
    }

    if (isValidJSON) {
        return JSON.parse(dataStr) as OutputData;
    }

    return {
        time: 0,
        blocks: [],
        version: "0"
    } as OutputData;
};

const EditorJsRenderer = ({ content }: Props) => {
    let data: OutputData = getData(content);

    return <Output data={data} />;
};

export default EditorJsRenderer;
