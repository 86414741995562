import React from "react";
import { OrderType } from "../../../types";
import { formatPrice, getPriceToShow } from "../../../utils/currency";
import { removeOrderItem } from "../../../store/order";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import Button from "../../shared/Button";
import OrderItemQuantityModifier from "../OrderItemQuantityModifier";
import CartItem from "./CartItem";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import CouponCode from "../CouponCode";
import { jsonParseString } from "../../../utils/string";

interface Props {
    order: OrderType | null;
    className?: string;
    textSize?: string;
    options?: {
        showRemove?: boolean;
        allowQuantityChange?: boolean;
        showTotals?: boolean;
        showTitle?: boolean;
        cardClass?: string;
        showCouponCode?: boolean;
    };
}

const DEFAULT_OPTIONS: Props["options"] = {
    showRemove: true,
    allowQuantityChange: true,
    showTotals: true,
    showTitle: false,
    cardClass: "p-5 bg-white mb-3",
    showCouponCode: false
};

export default function CartDisplay({
    order,
    className = "",
    textSize = "text-xl",
    options = DEFAULT_OPTIONS
}: Props) {
    const dispatch = useDispatch<AppDispatch>();

    const salesChannel = useSalesChannel();

    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode as string
    );

    const firstName = (order?.customer as any)?.firstName ?? "";
    const orderItems = (order?.orderItems || []).filter(orderItem => !orderItem.parentId);

    options = { ...DEFAULT_OPTIONS, ...options };

    const handleOnRemove = async (orderItemId: string) => {
        const orderId = order?.id;
        dispatch(removeOrderItem({ orderId, orderItemId }));
    };

    const subTotalAmount = React.useMemo(() => {
        return order?.subTotalAmount || 0;
    }, [order?.subTotalAmount]);

    const totalAmount = React.useMemo(() => {
        return order?.totalAmount || 0;
    }, [order?.totalAmount]);

    const shippingAmmount = React.useMemo(() => {
        return (order?.shippingAmount || 0) + (order?.shippingTaxAmount || 0);
    }, [order?.shippingAmount, order?.shippingTaxAmount]);

    return (
        <div data-cart-number={order?.number} className={`${className} ${textSize}`}>
            {options.showTitle && (
                <h2 className="text-2xl font-bold mb-5">
                    {firstName ? firstName + "'s" : "Your"} Shopping Cart
                </h2>
            )}

            {orderItems?.map(item => (
                <CartItem
                    order={order}
                    key={item.id}
                    item={item}
                    options={options}
                    textSize={textSize}
                    currencyCode={currencyCode}
                    handleOnRemove={handleOnRemove}
                />
            ))}

            {options.showCouponCode && <CouponCode />}

            {options?.showTotals && (
                <div className="mt-5">
                    <h2 className="text-2xl">Order Summary</h2>

                    <table className="w-full ">
                        <tbody>
                            <tr>
                                <td>Items:</td>
                                <td className="w-[50px] text-right">
                                    {formatPrice(subTotalAmount, currencyCode)}
                                </td>
                            </tr>
                            {!!order?.discountAmount && (order?.discountAmount || 0) > 0 && (
                                <tr>
                                    <td>Total Discount:</td>
                                    <td className="w-[50px] text-right">
                                        -{formatPrice(order?.discountAmount, currencyCode)}
                                    </td>
                                </tr>
                            )}
                            {!!order?.creditUsed && (order?.creditUsed || 0) > 0 && (
                                <tr>
                                    <td>Credit Used:</td>
                                    <td className="w-[50px] text-right">
                                        -{formatPrice(order?.creditUsed, currencyCode)}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>Shipping:</td>
                                <td className="w-[50px] text-right">
                                    {formatPrice(shippingAmmount, currencyCode)}
                                </td>
                            </tr>
                            <tr className="text-xl font-extrabold">
                                <td className="mt-2">Total:</td>
                                <td className="mt-2 w-[50px] text-right">
                                    {formatPrice(totalAmount, currencyCode)}
                                </td>
                            </tr>
                            <br />
                            <React.Fragment>
                                {(salesChannel?.donations ?? []).map(donation => {
                                    let file = jsonParseString(donation?.image);

                                    let donationValue = Number(donation.value || 0);

                                    if (donation.type === "percentage") {
                                        donationValue = totalAmount * (donationValue / 100);
                                    }

                                    return (
                                        <tr className="my-9" key={donation?.id}>
                                            <td className="mt-2 ">
                                                {`With your order you are donating ${formatPrice(
                                                    donationValue,
                                                    currencyCode
                                                )} to ${donation?.name}`}
                                            </td>
                                            <td className="mt-2 w-[50px] text-right">
                                                <img
                                                    alt={donation.name + " donation item."}
                                                    className="mb-3 w-full h-28 object-contain md:mb-0 md:w-full md:h-full "
                                                    src={file?.url}
                                                    width="50px"
                                                    height={"112px"}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
