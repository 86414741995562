import React from "react";
import SiteModal from "../SiteModal";
import Button from "../Button";
interface Props {
    children: React.ReactNode;
    show: boolean;
    maxWidth?: string;
    onConfirm: () => Promise<void> | void;
    onCancel: () => Promise<void> | void;
}
export default function ConfirmationDialog({ children, show = false, maxWidth = "auto", onConfirm, onCancel }: Props) {
    if (!show) {
        return null;
    }
    return (
        <SiteModal width={maxWidth}>
            {children}
            <div className="flex justify-between items-center mt-3">
                <Button
                    type="transparent"
                    onClick={e => {
                        e.preventDefault();
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className="w-[250px]"
                    onClick={e => {
                        e.preventDefault();
                        onConfirm();
                    }}
                >
                    Confirm
                </Button>
            </div>
        </SiteModal>
    );
}
