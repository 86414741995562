import { ISalesChannel } from "../services/ecommerceApi/SalesChannel/types";
import { AddressType, ITax, ITaxRate, ProductTaxCodeType, ProductType } from "../types";
import { firstOrDefault } from "./array";

export const round2dp = (num: number) => Math.round(num * 100) / 100;
export const formatPrice = (
    price: number,
    currency = "GBP",
    returnHTML: boolean = false,
    digits: number = 2
) => {
    let result = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency,
        maximumFractionDigits: digits,
        trailingZeroDisplay: "stripIfInteger"
    } as any).format(price);
    if (returnHTML) return replaceFirstCharacter(result);
    else return result;
};
export const getPriceToShow = (
    price: number,
    salesChannel: ISalesChannel,
    currencyCode?: string,
    shippingAddress?: AddressType,
    product?: ProductType,
    returnHTML: boolean = false,
    digits: number = 2
) => {
    let internalPrice = getPriceAsNumber(
        price,
        salesChannel,
        currencyCode,
        shippingAddress,
        product
    );
    return formatPrice(internalPrice, currencyCode, returnHTML, digits);
};
export const getPriceAsNumber = (
    price: number,
    salesChannel: ISalesChannel,
    currencyCode?: string,
    shippingAddress?: AddressType,
    product?: ProductType,
    returnHTML: boolean = false,
    digits: number = 2
): number => {
    let metadata = JSON.parse(salesChannel?.metadata || "{}");
    let showVat = metadata?.settings?.showVAT || false;

    if (showVat) {
        let taxRates: ITaxRate[] = [];
        // check for product taxes
        // default to sales channel taxes
        if (salesChannel.taxes) {
            taxRates =
                salesChannel.taxes.flatMap((t: ITax) => {
                    const postcode = shippingAddress?.postCode;
                    if (postcode) {
                        return t.taxRates.filter((tr: ITaxRate) => {
                            let postcodeMatch = tr.zipCodeRegex
                                ? postcode.match(new RegExp(tr.zipCodeRegex, "g"))
                                : false;

                            const countryCodeMatch = tr.countryCodeRegex
                                ? postcode.match(new RegExp(tr.countryCodeRegex, "g"))
                                : false;

                            return postcodeMatch || countryCodeMatch;
                        });
                    }
                    return firstOrDefault(t.taxRates);
                }) || [];
        }

        const totalVat = taxRates
            .map(t => {
                return Number(t.taxRate / 100);
            })
            .reduce((partialSum, a) => partialSum + a, 0);

        price = getPriceWithVATIncl(price, totalVat);
    }

    return round2dp(price);
};
export const getPriceIncTax = (
    price: number,
    salesChannel: ISalesChannel,
    shippingAddress?: AddressType
) => {
    let taxRates: ITaxRate[] = [];
    // check for product taxes
    // default to sales channel taxes
    if (salesChannel.taxes) {
        taxRates =
            salesChannel.taxes.flatMap((t: ITax) => {
                const postcode = shippingAddress?.postCode;
                if (postcode) {
                    return t.taxRates.filter((tr: ITaxRate) => {
                        let postcodeMatch = tr.zipCodeRegex
                            ? postcode.match(new RegExp(tr.zipCodeRegex, "g"))
                            : false;

                        const countryCodeMatch = tr.countryCodeRegex
                            ? postcode.match(new RegExp(tr.countryCodeRegex, "g"))
                            : false;

                        return postcodeMatch || countryCodeMatch;
                    });
                }
                return firstOrDefault(t.taxRates);
            }) || [];
    }

    const totalVat = taxRates
        .map(t => {
            return Number(t.taxRate / 100);
        })
        .reduce((partialSum, a) => partialSum + a, 0);

    return getPriceWithVATIncl(price, totalVat);
};
const getPriceWithVATIncl = (price: number, vatRate: number) => {
    return price * (1 + vatRate);
};
const replaceFirstCharacter = (inputString: string): string => {
    if (inputString.length === 0) {
        return inputString; // Return the input string unchanged if it's empty
    }

    const firstCharacter = inputString[0];
    const restOfString = inputString.slice(1);

    // Use template literals to create the desired output string
    const replacedString = `<span class="text-2xl font-bold">${firstCharacter}</span><span class="text-5xl font-bold">${restOfString}</span>`;

    return replacedString;
};
