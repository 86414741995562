import * as Yup from "yup";
import { AddressType, IAddress } from "../types";

export const isImage = (url: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const validateAddress = async (
    address: AddressType
): Promise<true | Record<string, any>> => {
    try {
        await addressSchema.validate(address, { abortEarly: false });
    } catch (validationError) {
        const validationErrors = {};
        validationError.inner.forEach(error => {
            validationErrors[error.path] = error.message;
        });
        return validationErrors;
    }
    return true;
};

export const addressSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    line1: Yup.string()
        .required("Address Line 1 is required")
        .max(50, "Address Line 1 must be no more than 50 characters"),
    line2: Yup.string().nullable().max(50, "Address Line 2 must be no more than 50 characters"),
    city: Yup.string()
        .required("City is required")
        .max(40, "City must be no more than 40 characters"),
    postCode: Yup.string().required("Postal Code is required"),
    countryCode: Yup.string()
        .required("Country Code is required")
        .max(2, "Country Code must be no more than 2 characters")
});

export const orderNotesSchema = (maxLength: number) =>
    Yup.string()
        .max(maxLength, `Order notes must be no more than ${maxLength} characters`)
        .nullable()
        .trim();

export function isEmail(emailAdress: string) {
    let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;

    if (emailAdress.match(regex)) return true;
    else return false;
}
export function validatePhone(num: string) {
    const re =
        /^((((\+44\s?([0–6]|[8–9])\d{3} | \(?0([0–6]|[8–9])\d{3}\)?)\s?\d{3}\s?(\d{2}|\d{3}))|((\+44\s?([0–6]|[8–9])\d{3}|\(?0([0–6]|[8–9])\d{3}\)?)\s?\d{3}\s?(\d{4}|\d{3}))|((\+44\s?([0–6]|[8–9])\d{1}|\(?0([0–6]|[8–9])\d{1}\)?)\s?\d{4}\s?(\d{4}|\d{3}))|((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4})))$/;
    return num.match(re);
}
