import React from "react";
import GetComponent from "../../../websiteTemplates/_GetComponent";
import { FooterProps } from "../../../websiteTemplates/Default/Footer";

interface Props {
    pageTemplate: any;
    salesChannel: any;
    menuItems: any;
    socials: any[];
}

export interface Menu {
    id: string;
    name: string;
    displayName: string;
    items: MenuItem[];
}
export interface MenuItem {
    id: string;
    link: any;
    name: string;
    sort: number;
    icon: string;
    iconColor?: string;
}

export default function Footer({ pageTemplate, salesChannel, menuItems, socials }: Props) {
    const logoFile = pageTemplate?.footerLogoFile || pageTemplate?.headerLogoFile;
    const footerLeftTitle = pageTemplate?.footer?.leftTitle;
    const footerLeftContent = pageTemplate?.footer?.leftContent;
    const footerMenus = pageTemplate?.footer?.menus || [];
    const menus: Menu[] = [];

    for (const menu of footerMenus) {
        const menuId = menu.id;
        const items = menuItems.nodes.filter(menuItem => menuItem.menu === menuId);
        const length = items.length;
        items.sort((a, b) => a.sort ?? length - b.sort ?? length);
        menus.push({
            ...menu,
            items
        });
    }


    const footerProps: FooterProps = {
        salesChannel,
        menus,
        logoFile,
        socials,
        footerLeftTitle,
        footerLeftContent
    }

    const template = salesChannel?.websiteTemplate;

    return (
        <GetComponent template={template} component="Footer" props={footerProps} />
    );
}
