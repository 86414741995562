import React, { useState, useEffect } from "react";
import { SIGN_UP_FORM } from "../../../auth/LoginPage/constants";
import TextInput from "../../../shared/FormComponents/TextInput";
import { CustomerType } from "../../../../types";
import { Auth } from "aws-amplify";
import ECommerceApi from "../../../../services/ecommerceApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Button from "../../../shared/Button";
import { CUSTOMER_SCHEMA } from "./constants";
import Spinner from "../../../shared/Spinner";

export default function General() {

    const [customer, setCustomer] = useState<CustomerType | null>(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saveMessage, setSaveMessage] = useState<string | null>(null);

    useEffect(() => {
        loadCustomer();
    }, []);

    useEffect(() => {
        if (saveMessage) {
            setTimeout(() => {
                setSaveMessage(null);
            }, 2000);
        }
    }, [saveMessage]);

    const loadCustomer = async () => {
        let cust = await ECommerceApi.getLoggedInCustomerData();
        if (cust) {
            setCustomer(cust);
        }
        setLoading(false);
    };

    const updateUser = async () => {
        setSaving(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            const cognito = await Auth.updateUserAttributes(user, {
                given_name: customer?.firstName,
                family_name: customer?.lastName
            });
            const db = await ECommerceApi.updateCustomer({
                id: customer?.id as string,
                firstName: customer?.firstName,
                lastName: customer?.lastName
            });

            setSaveMessage(db?.id ? "Successfully updated" : "Something went wrong updating your account. Please try again.");

        } catch (err) {
            console.log(err);
        }
        setSaving(false);
    };

    return (
        <>
            <div className="p-5 bg-white">

                {CUSTOMER_SCHEMA.map(f => {

                    if (f.onlyDisplayIfValueExists && !customer?.[f.name]) {
                        return <></>
                    }

                    return (
                        <div className="mb-3" key={f.name}>
                            <TextInput
                                readonly={f.readonly}
                                disabled={f.readonly}
                                name={f.name}
                                label={f.label}
                                value={customer?.[f.name]}
                                onChange={e => {
                                    let name = e.target.name as keyof CustomerType;
                                    let value = e.target.value as string;
                                    setCustomer({
                                        ...(customer as CustomerType),
                                        [name]: value as string
                                    });
                                }}
                            />
                        </div>
                    );
                })}
                <div className="flex justify-end items-center">
                    {saveMessage && (
                        <div className="text-sm mr-2">{saveMessage}</div>
                    )}
                    <Button
                        disabled={loading || saving}
                        onClick={updateUser}
                    >
                        Update
                        {saving && (
                            <Spinner className="ml-2" />
                        )}
                    </Button>
                </div>
            </div>
        </>
    );
}
