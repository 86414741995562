import React, { useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { getPriceToShow } from "../../../utils/currency";
import { useDispatch, useSelector } from "react-redux";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AppDispatch, RootState } from "../../../store";
import Button from "../../shared/Button";
import { useSalesChannel } from "../../../context/SalesChannelContext";
import { ProductDataType, RelatedProductType } from "../../../types";
import ProductLink from "../ProductLinkComponent";
import { addToCart } from "../../../store/order";
import EcommerceApi from "../../../services/ecommerceApi";
import ReviewSummary from "../ReviewSummary";
import { computeReviewSummary } from "../../../utils/product";
import { useLayout } from "../../layouts/Layout/hook";

interface Props {
    product: RelatedProductType;
}

const RecommendedProduct = ({ product }: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const { reviewPlatform } = useLayout();

    const salesChannel = useSalesChannel();
    const orderId = useSelector((state: RootState) => state.order.order?.id || null);
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode.selectedCurrencyCode?.currencyCode
    );

    const productUrl = useMemo(() => {
        return product.url;
    }, [product.url]);

    const image = getImage(product?.imageFile);

    const [adding, setAdding] = React.useState(false);
    const [selectedProductData, setSelectedProductData] = React.useState<ProductDataType | null>(
        null
    );

    const handleAddToCartClick = async () => {
        setAdding(true);
        const params = {
            orderId,
            skuId: product.addedProduct,
            quantity: 1
        };
        await dispatch(addToCart(params));
        setAdding(false);
    };

    const loadProductData = async () => {
        if (product) {
            const data = await EcommerceApi.getProductData(product.addedProduct);
            setSelectedProductData(data);
        }
    };

    const pricing = useMemo(() => {
        if (selectedProductData) {
            const listPrice = getPriceToShow(
                selectedProductData.listPrice,
                salesChannel,
                currencyCode,
                undefined
            );
            return {
                price: getPriceToShow(
                    selectedProductData.currentPrice,
                    salesChannel,
                    currencyCode,
                    undefined
                ),
                listPrice: selectedProductData?.listPrice || 0 ? listPrice : null
            };
        }
        return null;
    }, [selectedProductData, currencyCode, salesChannel.metadata]);

    useEffect(() => {
        loadProductData();
    }, [product]);

    const reviewSummary = useMemo(() => {
        return selectedProductData?.reviewSummary
    }, [selectedProductData?.reviewSummary]);

    return (
        <div className="w-full md:w-1/3 px-3 mb-6">
            <div className="p-6 bg-white">
                <div className="flex justify-center">
                    <ProductLink className="block max-w-[200px]" productUrl={productUrl}>
                        <GatsbyImage
                            image={image as any}
                            alt={product?.SKUName}
                            imgClassName="h-full w-full object-contain"
                        />
                    </ProductLink>
                </div>

                <div className="mt-3">

                    <ReviewSummary
                        product={product}
                        reviewPlatform={reviewPlatform}
                        size={18}
                        reviewSummary={reviewSummary}

                        Wrapper={(props: any) => <div className="w-full mb-3">{props.children}</div>}
                    />

                    <ProductLink
                        className="block text-xl leading-tight mb-1 font-extrabold uppercase"
                        productUrl={productUrl}
                    >
                        {product.SKUName}
                    </ProductLink>

                    <div className="pb-2">
                        <span className="text-xl text-gray-500">{pricing?.price}</span>
                    </div>

                    <div className="flex -mx-2 items-center justify-between mt-3">
                        <div className="w-1/2 px-2">
                            <Button
                                className="w-full"
                                padding="py-2"
                                loader={adding}
                                disabled={adding}
                                onClick={handleAddToCartClick}
                            >
                                Add To Cart
                            </Button>
                        </div>
                        {productUrl != undefined && (
                            <div className="w-1/2 px-2">
                                <Button
                                    className="w-full"
                                    padding="py-2 px-3"
                                    onClick={() => navigate(productUrl || "")}
                                >
                                    Find out More
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecommendedProduct;
