import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import React, { useCallback, useEffect } from "react";

import Layout from "../../components/layouts/Layout";
import CartDisplay from "../../components/ecommerce/CartDisplay";

import EcommerceService from "../../services/ecommerceApi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import { updateOrder } from "../../store/order";
import CheckoutLayout from "../../components/checkout/CheckoutPage/CheckoutLayout";
import { OrderType } from "../../types";
import SiteModal from "../../components/shared/SiteModal";
import Button from "../../components/shared/Button";
import EmptyLayout from "../../components/layouts/EmptyLayout";
import { useSalesChannel } from "../../context/SalesChannelContext";
import PaymentFailedMessage from "./PaymentFailedMessage";
import { getLoggedInCustomer } from "../../store/auth";
import { FaGift } from "react-icons/fa";
import ReferralCode from "../../components/ecommerce/ReferralCode";
import { useTrackingContext } from "../../context/tracking";

type Props = {
    id: string;
};

export default function OrderSuccess({ id }: Props) {
    const dispatch = useDispatch<AppDispatch>();

    const stateOrder = useSelector((state: RootState) => state.order.order);

    const data = useStaticQuery(graphql`
        query {
            salesChannel {
                id
                name
                email
                logo
                logoFile {
                    publicURL
                }

                phone
                address
                type
                donations {
                    id
                    name
                    desc
                    type
                    value
                    image
                    enabled
                    salesChannel
                }
            }
            allIntegration {
                edges {
                    node {
                        id
                        type
                        config {
                            property
                            propertyId
                            type
                            value
                        }
                    }
                }
            }
        }
    `);

    const customer = useSelector((state: RootState) => state.auth.customer);
    const salesChannel = data?.salesChannel;
    const integrations = (data?.allIntegration?.edges ?? []).map(el => el.node);
    const gtm = integrations.find((el: { type: string }) => el.type === "google-tag-manager");
    const currencyCode = useSelector(
        (state: RootState) => state.currencyCode?.selectedCurrencyCode?.currencyCode
    );

    const [showConfirmationEmailMessage, setShowConfirmationEmailMessage] = React.useState(false);
    const [order, setOrder] = React.useState<OrderType | null>(null);
    const [loading, setLoading] = React.useState(true);
    const { purchaseEvent } = useTrackingContext();

    const loadLiveOrder = useCallback(async () => {

        const liveOrder = await EcommerceService.getOrder(id);
        const isCart = liveOrder?.status == "shoppingCart";
        const isFailed = liveOrder?.status == "paymentFailed";

        // clear cart
        await dispatch(updateOrder(null));

        // send analytics
        if (!isCart && !isFailed) {
            purchaseEvent({ ...liveOrder, salesChannel });
        }

        // if cart try to load live order in 5 seconds
        if (isCart) {
            console.log("is cart");
            setShowConfirmationEmailMessage(true);
            return;
        };


        setOrder(liveOrder);
    }, [id, dispatch, salesChannel, gtm]);

    const load = useCallback(async () => {

        setLoading(true);

        const loggedIn = customer?.id;

        if (loggedIn) {
            await loadLiveOrder();
            setLoading(false);
            return;
        }

        // load order as guest

        // if url id is the same as state order
        if (stateOrder?.id === id) {
            await loadLiveOrder();
            setLoading(false);
            return;
        }

        // show login to view history
        setLoading(false);

    }, [customer?.id, loadLiveOrder, stateOrder]);

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        dispatch(getLoggedInCustomer());
    }, []);

    if (loading) {
        return (
            <EmptyLayout>
                <SiteModal>
                    <Loading />
                </SiteModal>
            </EmptyLayout>
        );
    }

    if (showConfirmationEmailMessage) {
        return <EmptyLayout>
            <SiteModal>
                <div className="p-5 text-center">
                    <div className="text-2xl font-bold mb-3">Please await your Confirmation Email</div>
                    <p>
                        Your order is still processing, you should recieve an order confirmation email
                        shortly. If you do not recieve an email please check your spam folder.
                    </p>
                    <Link to="/" className="inline-block mt-5">
                        <Button type="primary">Back to Home</Button>
                    </Link>
                </div>
            </SiteModal>
        </EmptyLayout>
    }

    if (!order && !loading) {
        return <LoginToViewHistory />;
    }

    return (
        <CheckoutLayout
            seo={{ title: `Order ${order?.number}` }}
            cart={order || undefined}
            hideNav={true}
            showCouponCode={false}
        >
            <section className="py-0 md:py-10">
                {!loading && order ? (
                    <>
                        <div className="flex flex-wrap -mx-3 text-center md:text-left">

                            <div className="px-3 w-full md:w-2/3">
                                {order.status !== "paymentFailed" ? (
                                    <>
                                        <h1 className="text-2xl">Order Success</h1>
                                        <h2 className="text-4xl">
                                            Thank you, {order.customer.firstName}
                                        </h2>
                                        <div>{order.customer.email}</div>

                                        <p className="mt-4">
                                            We have received your order and will begin preparing it. You will receive an email confirmation once it has been dispatched.
                                        </p>

                                        <div className="flex justify-center md:justify-start space-x-3 mt-6">
                                            <Button className="md:w-[200px]" onClick={
                                                () => navigate("/")
                                            }>
                                                Go to Home
                                            </Button>
                                            <Button className="md:w-[200px]" type={"secondary"} onClick={
                                                () => navigate("/account")
                                            }>
                                                View My Account
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <PaymentFailedMessage />
                                )}

                            </div>

                            <div className="px-3 w-full md:w-1/3 mt-5 md:mt-0">
                                <div className="space-y-4">

                                    <div>
                                        <h2 className="text-lg uppercase font-extrabold mb-1">
                                            Shipping Address
                                        </h2>
                                        <div className="p-5 bg-gray-100">
                                            <div>
                                                <div>
                                                    {order.shippingAddress[0].firstName}{" "}
                                                    {order.shippingAddress[0].lastName}
                                                </div>
                                                <div>
                                                    {order.shippingAddress[0].line1},{" "}
                                                    {order.shippingAddress[0].postCode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h2 className="text-lg uppercase font-extrabold mb-1">
                                            Billing Address
                                        </h2>
                                        <div className="p-5 bg-gray-100">
                                            <div>
                                                <div>
                                                    {order.billingAddress.firstName}{" "}
                                                    {order.billingAddress.lastName}
                                                </div>
                                                <div>
                                                    {order.billingAddress.line1},{" "}
                                                    {order.billingAddress.postCode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {customer?.referralCode && (
                            <div className="p-6 bg-primary mt-8">
                                <div className="flex text-center md:text-left flex-wrap md:flex-nowrap space-x-3 items-center">

                                    <div className="flex justify-center md:block w-full md:w-auto mb-2 md:mb-0">
                                        <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
                                            <FaGift />
                                        </div>
                                    </div>

                                    <div className="text-white">
                                        <h2 className="text-xl">
                                            Your Referral Code!
                                        </h2>
                                        <p>
                                            You can share this code with your friends and family to get them a discount on their first order.
                                            <br /><br />
                                            You'll earn £10 of store credit for each person who successfully uses your code.
                                        </p>
                                    </div>

                                    <div className="flex-1 flex justify-end">
                                        <ReferralCode referralCode={customer.referralCode} className={"bg-white mt-2 md:mt-0 md:w-auto p-3"} />
                                    </div>

                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </section>
        </CheckoutLayout>
    );
}

const LoginToViewHistory = () => {
    const user = useSelector((state: RootState) => state.auth.user);

    const onLogin = async () => {
        if (!user?.username) {
            await navigate("/login", {
                state: {
                    redirect: "/account"
                }
            });
        }
        await navigate("/account");
    };

    return (
        <CheckoutLayout
            seo={{ title: `Please login to view your History` }}
            hideNav={true}
        >
            <section className="py-0 md:py-10">
                <h1 className="text-4xl mb-3">Login to view your History</h1>
                <p>
                    Thank you for your order. This page has now expired, if you'd like to see more
                    information about your order check your email for your invoice, or login to your
                    account to view your order history.
                </p>
                <div className="flex -mx-3 mt-5">
                    <Button type="primary" className="mx-3" onClick={onLogin}>
                        Login to your Account
                    </Button>
                    <Button type="secondary" className="mx-3" onClick={() => navigate("/")}>
                        Back to Home
                    </Button>
                </div>
            </section>
        </CheckoutLayout>
    );
};

const Loading = () => {
    return (
        <div className="flex items-center justify-center min-h-[50vh]">
            <div className="text-2xl font-bold mb-3">Loading...</div>
        </div>
    );
};
