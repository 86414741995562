import { FormInput } from "./types"

export function getConfigData(config: FormInput[], data: any) {
  const res: any = {}
  if (data) {
    for (const input of config) {
      res[input.name] = data[input.name]
    }
  }
  return res
}
